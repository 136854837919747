import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import toast from "react-hot-toast";
import { message } from "../../../../Utils/functions";
import { useSelector } from "react-redux";

const BoletinarAgregar = () => {
   const navigate = useNavigate();
   // const { state } = useLocation();
   const [estatusConcluido, setEstatusConcluido] = useState(false);
   const [fechaInicio, setFechaInicio] = useState("21-09-2023 09:00")
   const [fechaFin, setFechaFin] = useState("21-09-2023 09:00")
   const userId = sessionStorage.getItem('userId').trim();
   const [loading, setLoading] = useState(false);
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

   let initial = {
      motivo: "",
      motivo_id: "",
      comentarios: "",
      reg_concluido: false
   }

   const dataMotivos = [
      {value: 1, label: "Motivo 1"},
      {value: 2, label: "Motivo 2"},
      ]

   const [initialValue, setInitialValue] = useState(initial); 
   const validate = Yup.object({
      motivo: Yup.string()
         .required("El campo es requerido."),
   });

   const handleGuardar = async (values) =>{
      setLoading(true);
      const data = {
         "siniestroClienteBoletinarId": crypto.randomUUID(),
         "siniestroId": datosConsulta.ruta_item_id,
         "siniestroClienteBoletinarStatusId": 0,
         "siniestroClienteBoletinarMotivoId": values.motivo_id.toString(),
         // "usuarioBoletina": userId,
         "comentariosEjecutivo": values.comentarios,
         "comentariosSupervisor": "string",
         "clienteBoletinado": true,
         "concluido": true,
         "status": false,
         "autorizado": false,
         "rechazado": false,
         "contrato": datosConsulta.contrato,
         "siniestroClienteBoletinarMotivo": values.motivo,
         "sol_id": datosConsulta.sol_id,
         "vin": datosConsulta.vin,
         "proceso": "string",
         "nombreCliente": datosConsulta.nombre_cliente
      }

      await CobranzaService.CreateBoletinarCliente(data)
      .then(res => {
         console.log(res)
         toast.success(message("¡Operación exitosa!", res.data.mensaje));
         setLoading(false)
         navigate(-1)
      }).catch(e => {
         setLoading(false)
         if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
         else
            toast.error(message("¡Error!", e.message));
      })

      console.log(data)
   }

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                              <h2 className="fw-bold ">Boletinar</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                              <div className="row mt-4">
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-11 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
               <Formik
                  enableReinitialize={true}
                  validationSchema={validate}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                     handleGuardar(values);
               }}>
               {({ isValid, values, setFieldValue, errors }) => (
                  <>
                     <Form>
                        <div className="px-4">
                           <div className="row mt-2 px-4">
                              <div className="col-2 mt-3">
                                 <p >Detalle de Boletinado</p>
                              </div>
                              <div className="col-6 mt-3">
                                 <div className="row justify-content-end">
                                    <div className="col-auto">
                                       <button className='btn col-12' type='submit' disabled={!(isValid)} >Guardar</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-2">
                              <SelectField  label="Motivo boletinado" name="motivo" items={dataMotivos} onChange={(e) => {
                                 setFieldValue("motivo", e.value === "" ? "" : e.label);
                                 setFieldValue("motivo_id", e.value === "" ? "" : e.value);
                              }} />
                           </div>
                           <div className="mt-4">
                              <TextAreaField label="Comentarios del boletinado:" className="col-3 mt-2" rows="4" name="comentarios" type="text" holder="Escribe" />
                           </div>
                           <div className="mb-3 row">
                              <div className="form-check mx-1 col-sm-6">
                                 <span><input className="form-check-input" type="checkbox" checked={estatusConcluido} value={"rangodefechas"} onChange={(e) => { setEstatusConcluido(e.target.checked) }} /> Registro concluido </span>
                              </div>
                           </div>
                           <div className="row  mb-3">
                              <div className="col-2 mb-3">
                                 <span className="text-sm">Inicio de proceso: {fechaInicio} </span>
                              </div>
                              <div className="col-2">
                                 <span className="text-sm">Fin de proceso: {fechaFin} </span>
                              </div>
                           </div>
                        </div>
                     </Form>
                  </>)}
               </Formik>
            </div>
         </div>
      </>
   );
}

export default BoletinarAgregar;