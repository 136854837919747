import { useState, useEffect } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import DiasFestivosService from "../../Services/DiasFestivos/DiasFestivosService";
import AuthLogin from '../../Utils/AuthLogin';

const auth = new AuthLogin();

const useDiasFestivos = () => {
    const [fechas, setFechas] = useState(new Date());
    const [selectedDates, setSelectedDates] = useState([]);
    //Datos para el servicio.
    const [year, setYear] = useState(new Date().getFullYear())
    const [calendarActive, setCalendarActive] = useState(false)
    //Modal
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [isOpenModalDiasHabiles, setIsOpenModalDiasHabiles] = useState(false);
    //loading 
    const [loading, setLoading] = useState(false);
    //Fechas guardadas
    const [fechasAntiguas, setFechasAntiguas] = useState([])
    const [fechasOficiales, setFechasOficiales] = useState([])
    const userId = auth.getSesionValue("userId");

    function onChange(nextValue) {
        setFechas(nextValue);
    }

    useEffect(() => {
        handleGetDiaFestivos()
    }, []);

    const handleGetDiaFestivos = async () => {
        setLoading(true);
        await DiasFestivosService.getDiaFestivos()
            .then(res => {
                let fechas = []
                let fechasOficiales = []
                res.data.forEach(item => {
                    fechas.push(new Date(item.diaFestivo));
                    if (item.oficial === 1) fechasOficiales.push(new Date(item.diaFestivo));
                });

                //guardo fechas
                setFechasOficiales(fechasOficiales)
                setSelectedDates(fechas)
                setFechasAntiguas(fechas)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })

    }

    function formatWeekDate(date, format) {
        // Custom day names
        const customNames = [
            "D", "L", "M", "M", "J", "V", "S",
        ];
        // Extract day, month, and year
        const day = date.getDay()//0-6
        const month = date.getMonth(); // 0-11
        const year = date.getFullYear();

        // Remplaza "d" para cambiar el custom.
        const formattedDate = format
            .replace("d", customNames[day])
            .replace("MM", month)
            .replace("yyyy", year);

        return formattedDate;
    }

    function formatDate(date) {
        const newDate = new Date(date)
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }

    function isSameDay(a, b) {
        return differenceInCalendarDays(a, b) === 0;
    }

    function tileClassName({ date, view }) {
        const fechaActual = new Date()
        const yesterday = new Date(fechaActual)
        // Restar 1 día
        yesterday.setDate(fechaActual.getDate() - 1);

        // Add class to tiles in month view only
        if (view === 'month') {
            if (selectedDates.find(dDate => isSameDay(dDate, date))) {
                return 'disable-react-calendar';
            }
            else if (date < yesterday) {
                return 'disable-react-calendar';
            }
            else {
                return 'active-react-calendar'
            }
        }
    }

    const toggleDate = (newDate, event) => {
        if (!calendarActive) return null
        event.target.blur();

        const fechaActual = new Date()

        //Si es una fecha oficial, no hace nada
        if (fechasOficiales.some(fecha => fecha.getTime() === newDate.getTime())) {
            return null
        }
        //Si es una fecha mas antigua que hoy, no hace nada
        if (newDate < fechaActual) {
            return null
        }
        //conparo primero si existe en as oficiales o si es menor a la fecha. 
        setSelectedDates(prevDates => {

            const isDateExists = prevDates.some(
                date => date.getTime() === newDate.getTime()
            );

            if (isDateExists) {
                // If the date already exists, remove it
                return prevDates.filter(
                    date => date.getTime() !== newDate.getTime()
                );
            } else {
                // If the date doesn't exist, add it
                return [...prevDates, newDate];
            }
        });
    };

    // Function to increase the year
    const incrementYear = () => {
        setYear(year + 1);
    };

    // Function to decrease the year
    const decrementYear = () => {
        setYear(year - 1);
    };

    //Function to return to day
    const actualDay = (event) => {
        event.target.blur();
        setFechas(new Date())
        setYear(new Date().getFullYear())
    }

    const changeCalendarActive = async () => {
        if (calendarActive) {
            const formattedArray1 = selectedDates.map(date => date.toISOString());
            const formattedArray2 = fechasAntiguas.map(date => date.toISOString());

            //Si existe en ambos arreglos no hacer nada. 
            // Loop through the first array // Si existe en el primer arreglo pero no en el segundo Agregar
            formattedArray1.forEach(async (date) => {
                if (!formattedArray2.includes(date)) {
                    let modificar = formatDate(date)
                    let data = {
                        diaFestivoId: null,
                        diaFestivo: modificar,
                        // oficial: 1
                    }

                    await DiasFestivosService.SaveDiaFestivo(data)
                }
            });

            // Loop through the second array // Si existe en el segundo arreglo pero no en el primero Eliminar
            formattedArray2.forEach(async (date) => {
                if (!formattedArray1.includes(date)) {
                    let data = {
                        diaFestivoId: null,
                        diaFestivo: formatDate(date),
                        oficial: 0,
                        fechaAlta: null,
                        usuarioAlta: userId,
                        fechaUpd: new Date,
                        usuarioUpd: userId,
                        status: 1
                    }

                    await DiasFestivosService.DeleteDiaFestivo(data)
                }
            });

            setFechasAntiguas(selectedDates)
        }
        setCalendarActive(!calendarActive)
    };

    const handleCancel = () => {
        setSelectedDates(fechasAntiguas)
        setCalendarActive(!calendarActive)

    }

    //Modal
    const handleEdit = (item) => {
        if (!calendarActive) return null
        setModalMessage({ isOpen: true, title: 'Configuración días no hábiles', type: 5, idAdeudo: item.siniestroPagoTramitesAdeudoId })
        setIsOpenModalDiasHabiles(true)
    }

    return {
        loading,
        modalMessage,
        setModalMessage,
        isOpenModalDiasHabiles,
        selectedDates,
        setSelectedDates,
        year,
        actualDay,
        decrementYear,
        incrementYear,
        handleEdit,
        calendarActive,
        setCalendarActive,
        onChange,
        fechas,
        formatWeekDate,
        tileClassName,
        toggleDate,
        changeCalendarActive,
        handleCancel
    }
}

export default useDiasFestivos