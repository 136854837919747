import React, { useState } from "react";
import SiniestroDetalle from "./SiniestroDetalle";
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard";
import ContratoHeaderCard from "../ContratoHeaderCard";
import InformacionSeguroCard from "../InformacionSeguroCard";
import SegumientoProcesoDatamovil from "./SeguimientoProcesoDatamovil";
import RegistroSiniestroCard from "./Registro/RegistroSiniestroCard";
import RecoleccionUnidadCard from "./Registro/RecoleccionUnidadCard";
import ResguardoUnidadCard from "./Registro/ResguardoUnidadCard";

const DetalleRegistroSiniestro = React.forwardRef(({ ...props }, ref) => {
    const [stepe, setStepe] = useState([
        {
            "valuacionId": "68221ee6-9088-44c4-9433-3dfa00f60896",
            "paso": 1,
            "pasoDescripcion": "Registro de siniestro",
            "pasoSubStatus": 2,
            "pasoSubStatusDescripcion": "En Proceso",
            "porcentaje": 0
        },
        {
            "valuacionId": "68221ee6-9088-44c4-9433-3dfa00f60896",
            "paso": 2,
            "pasoDescripcion": "Liberación de unidad",
            "pasoSubStatus": 1,
            "pasoSubStatusDescripcion": "Pendiente",
            "porcentaje": 0
        },
        {
            "valuacionId": "68221ee6-9088-44c4-9433-3dfa00f60896",
            "paso": 3,
            "pasoDescripcion": "Valuación",
            "pasoSubStatus": 1,
            "pasoSubStatusDescripcion": "Pendiente",
            "porcentaje": 0
        },
        {
            "valuacionId": "68221ee6-9088-44c4-9433-3dfa00f60896",
            "paso": 4,
            "pasoDescripcion": "Cierre de siniestro",
            "pasoSubStatus": 1,
            "pasoSubStatusDescripcion": "Pendiente",
            "porcentaje": 0
        }
    ]);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    
    const handleClickTerminar = async () => {
        setModalConfirm(true);
    }

    const handlerDatadocs = () => {

    }

    const handlerCostos = () => {

    }

    const handlerCalendario = () => {

    }

    return (
        <>
            <SiniestroDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={'746e27c0-e67e-45fa-aa9e-96e3324d3d43'} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                <InformacionSeguroCard generalId={'B6955895-EE4F-41CA-AFF0-FFFF151AD05E'} />
                <SegumientoProcesoDatamovil />
                <RegistroSiniestroCard />
                <RecoleccionUnidadCard handlerDatadocs={handlerDatadocs} handlerCostos={handlerCostos} handlerCalendario={handlerCalendario} />
                <ResguardoUnidadCard handlerDatadocs={handlerDatadocs} handlerCostos={handlerCostos} handlerCalendario={handlerCalendario} />
            </div>
        </>
    )
});

export default DetalleRegistroSiniestro;