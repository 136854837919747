import React, { useState } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import RowPages from '../../../Components/RowPages';
import Select from 'react-select';
import SubMenuSupervisionDeCarga from './SubMenuSupervisionDeCarga';
import useSupervisionCargaNivel1 from '../../../Hooks/Cobranza/useSupervisionCargaNivel1';
import BusquedaAvanzadaModal from './BusquedaAvanzadaModal';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
// import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
// import { Section, SubModule } from '../../Auth/Authorization'

const SupervisionDeCargaNivel1 = () => {
  let {
    columns,
    columnsDetail,
    data,
    pageCount,
    dataCobradores,
    setIdCobrador,
    setStr,
    isOpenModal, 
    setIsOpenModal,
    loading,
    // handlePageClick,
    // handleEdit,
    // page,
    setpageRows,
    setAccionBuscar,
    handleDetails
  } = useSupervisionCargaNivel1();

  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <SubMenuSupervisionDeCarga/>
      <section className='mx-5 py-4'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Supervisión de carga</strong></span>
          <div>
            <button id="AT_btnDistribucion" className='btn mx-2' type="button" onClick={() => {console.log("Acción por realizar")}} >Distribución manual de cartera</button>
            <button id="AT_btnExportar" className='btn mx-2' type="button" onClick={() => {console.log("Acción por realizar")}} >Exportar Excel</button>
          </div>
        </div>
      </section>
      <section className='mt-3 mx-4'>
        <div className='col-lg-4 col-sm-12 d-flex'>
          <div className="col-6 mx-1  inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
          </div>
          <div className='col-6 d-flex'>
            <Select options={dataCobradores} className='col-12 mx-1' onChange={(e) => { setIdCobrador(e.value) }} placeholder="Cobrador" />
            <button id="AT_btnBusquedaAvanzada" className='btn mx-2 col-8' type="button" onClick={() => {setIsOpenModal(true)}} >Búsqueda Avanzada</button>
          </div>
        </div>
      </section>
      {/* <ValidatePermission
        isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
      > */} 
        <div className='mx-4 table-responsive' >
          <DataTable
            column={columns}
            data={data}
            masterDetail={true}
            detailable={true}
            handleDetail={handleDetails}
            columnDetail={columnsDetail}
            extrainfopromedio = {true}
          />
        </div>
      {/* </ValidatePermission> */}
        {
          isOpenModal &&(
          <>
            <BusquedaAvanzadaModal
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
              setAccionBuscar={setAccionBuscar}
            />
          </>)
        }
    </>
  );
}

export default SupervisionDeCargaNivel1;