import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../../../Components/TextAreaField';
import handlePhonNumberFormatter, { getDateFormat, message, pageRows } from '../../../../../Utils/functions'
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import ErrorModal from '../../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../../Components/SelectField';
import { TextField } from '../../../../../Components/TextField';
import Siniestros from '../../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../../Services/Catalogos/Catalogo';

const RegistroSiniestroCard = React.forwardRef(({ ...props }, ref) => {
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();
    const { id, generalid, siniestroid } = useParams();
    const navigate = useNavigate();
    const { fechaEntrega,
        nombreYear,
        vin,
        color,
        nombreMarca,
        kilometraje,
        nombreModelo,
        procedenciaAuto,
        nombreVersion,
        numContrato,
        numSolicitud } = useSelector((state) => state.DataVinSlice);
    let { state } = props;
    let initial = {}

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);
    const [UnidadDetenida, setUnidadDetenida] = useState();
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
    }, []);


    /* CONSULTAS */
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const getTipoSiniestro = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroTipo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.SiniestroTipoId, label: item.SiniestroTipo })
                });
                setDataTipoSiniestro(items);
            })
            .catch(err => {
                console.error(err);
            });
    };
    /* TERMINA CATALOGOS */

    const formatMoney = (value) => {
        if (value === undefined) {
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const validate = Yup.object().shape({
        siniestroTipoId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        unidadDetenida: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),       
        folioAseguradora: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        correoElectronico: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .trim("El texto no debe empezar ni terminar con espacios")
                    .strict(true)
                    .email("Ingrese un email valido."),
            }),
        telefonoMovil: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
            }),
        reportaNombre: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            })

    });
    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${siniestroid}`, {
            state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }

        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    //submitForm(values);
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <>
                                        <Form>
                                            <div className="row mt-2 px-4">
                                                <div className="col-10">
                                                    <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Registro de siniestro </strong></h6>
                                                </div>
                                                <div className="col-2" style={{ 'textAlign': 'right' }}>
                                                    <button
                                                        className='btn'
                                                        type="submit"
                                                        disabled={!(isValid)}>
                                                        Guardar
                                                    </button>
                                                </div>
                                                <div className='col-12'>
                                                    <small>{initialValue.siniestroStatusNombre}</small>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de contacto</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="reportaNombre"
                                                            
                                                            label="Nombre de quien reporta"
                                                            holder=""
                                                            name="reportaNombre"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("reportaNombre", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoMovil"  label="Teléfono móvil"
                                                            holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                                setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoFijo"  label="Teléfono fijo"
                                                            holder="" name="telefonoFijo" type="text" onChange={(event) => {
                                                                setFieldValue("telefonoFijo", handlePhonNumberFormatter(event.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoTrabajo"  label="Teléfono de trabajo" holder="" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                            setFieldValue("telefonoTrabajo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="correoEletronico"  label="Correo electrónico" holder="" name="correoElectronico" type="text" onChange={(event) => {
                                                            setFieldValue("correoElectronico", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Detalle de siniestro</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <fieldset  onClick={handleUploadFile} className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="unidadDetenida"
                                                            label="Unidad detenida por autoridad"
                                                            name="unidadDetenida"
                                                            
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadDetenida", event.value);
                                                                setUnidadDetenida(event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="siniestroTipoId"
                                                            label="¿Se requiere Grúa?"
                                                            name="siniestroTipoId"
                                                            
                                                            items={dataTipoSiniestro}
                                                            onChange={(event) => {
                                                                setFieldValue("siniestroTipoId", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="cuentaFolioAseguradora"
                                                            label="¿Cuenta con folio aseguradora?"
                                                            name="cuentaFolioAseguradora"
                                                            
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("cuentaFolioAseguradora", event.value);
                                                            }} />
                                                    </div>
                                                    {/* {
                                                        values.cuentaFolioAseguradora === 1 && */}
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            
                                                            label="Folio siniestro aseguradora"
                                                            holder="Escribe"
                                                            name="folioAseguradora"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                    {/* } */}
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios del siniestro"
                                                            
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input"  type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
export default RegistroSiniestroCard