import React, { useState } from 'react'
import Stepper, { status } from '../../../../Components/Stepper/Stepper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import ClienteEntregaUnidadModal from '../../../../Components/Modal/ClienteEntregaUnidadModal';
import BoletinarClienteModal from '../../../../Components/Modal/BoletinarClienteModal';
import PagoTramitesModal from '../../../../Components/Modal/PagoTramitesModal';
import DatamovilModal from '../../../../Components/Modal/DatamovilModal';
import ClienteDesisteModal from '../../../../Components/Modal/ClienteDesisteModal';
const data = {
    step1: status.Active,
    porcentage1: 100,
    step2: status.Inactive,
    porcentage2: 0,
    step3: status.Inactive,
    porcentage3: 0,
    step4: status.Inactive,
    porcentage4: 100,
};
const SiniestroDetalle = ({ handleClickContinuar, dataStep = data, activeBtn = false }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let url = location.pathname.split("/");
    let paso = url[4];
    const handleNavigate = (step) => {
        navigate(`/seguro/siniestros/${id}/${step.paso}`, {
            state: state
        })
    }

    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [enabledE, setEnabledE] = useState(true);
    const [items, setItems] = useState(10);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, width: '' });

    const clienteUnidadSubmit = (values) => {

    }


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={modalMessage.width}>
                {
                    modalMessage.type === 1 &&
                    <ClienteEntregaUnidadModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleOk={clienteUnidadSubmit}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <BoletinarClienteModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleOk={clienteUnidadSubmit}
                    />
                }
                {
                    modalMessage.type === 3 &&
                    <PagoTramitesModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleOk={clienteUnidadSubmit}
                    />
                }
                {
                    modalMessage.type === 4 &&
                    <DatamovilModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleOk={clienteUnidadSubmit}
                    />
                }
                {
                    modalMessage.type === 5 &&
                    <ClienteDesisteModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                        handleOk={clienteUnidadSubmit}
                    />
                }
            </Modal>
            <section className='headerTab'>
                <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                    <div>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                        Regresar a listado de incidencias
                    </div>
                    {
                        !state.concluido &&
                        <div className='d-flex align-items-center'>
                            {
                                paso == 4 &&
                                <div className=''>
                                    <div className="d-flex" style={{ color: 'red' }}> <i className='ri-error-warning-line me-1'></i>Favor de hacer el resguardo de la unidad.</div>
                                </div>
                            }
                            <button disabled={!activeBtn} className='btn ms-2' onClick={handleClickContinuar}>{paso == 4 ? "Finalizar" : "Continuar"}</button>
                        </div>

                    }

                </header>
                <div className="row mx-1 mt-3">
                    <div className="col col-12">
                        <div className="row">
                            <div className="col col-auto">
                                <button className={`btn text-sm position-relative ${enabled ? 'enabledButton' : 'disabledButton'}`} disabled={state.concluido} onClick={(e) => {
                                    setModalMessage({ isOpen: true, type: 1, title: 'Cliente entrega la unidad', width: '700' });
                                }}>
                                    Cliente entrega unidad
                                    <span className="badge bg-datamovil badge-circle position-absolute top-0 start-100 translate-middle">
                                    </span>
                                </button>
                            </div>
                            <div className="col col-auto">
                                <button className={`btn text-sm position-relative ${enabled ? 'enabledButton' : 'disabledButton'}`}  disabled={state.concluido} onClick={(e) => {
                                    setModalMessage({ isOpen: true, type: 2, title: 'Boletinar a cliente', width: '780' });
                                }}>
                                    Boletinar a cliente
                                    <span className="badge bg-datamovil badge-circle position-absolute top-0 start-100 translate-middle">
                                        {/* {items < 9 ? '+' + items : '+9'} */}
                                    </span>
                                </button>
                            </div>
                            <div className="col col-auto">
                                <button className={`btn text-sm position-relative ${enabled ? 'enabledButton' : 'disabledButton'}`}  disabled={state.concluido} onClick={(e) => {
                                    setModalMessage({ isOpen: true, type: 3, title: 'Pago de trámites', width: '990' });
                                }}>
                                    Pago de Tramite
                                    <span className="badge bg-datamovil badge-circle position-absolute top-0 start-100 translate-middle">
                                    </span>
                                </button>
                            </div>
                            <div className="col col-auto">
                                <button className={`btn text-sm position-relative ${enabledE ? 'enabledButton' : 'disabledButton'}`}  disabled={state.concluido} onClick={(e) => {
                                    setModalMessage({ isOpen: true, type: 4, title: 'Proceso Datamovil', width: '480' });
                                }}>
                                    Proceso Datamovil
                                    <span className="badge bg-datamovil badge-circle position-absolute top-0 start-100 translate-middle">
                                        {items < 9 ? '+' + items : '+9'}
                                    </span>
                                </button>
                            </div>
                            <div className="col col-auto">
                                <button className={`btn text-sm position-relative ${enabledE ? 'enabledButton' : 'disabledButton'}`}  disabled={state.concluido} onClick={(e) => {
                                    setModalMessage({ isOpen: true, type: 5, title: 'Cliente desiste', width: '700' });
                                }}>
                                    Cliente desiste
                                    <span className="badge bg-datamovil badge-circle position-absolute top-0 start-100 translate-middle">
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center row'>
                    {
                        dataStep.map(x =>
                            <Stepper                            
                                key={x.paso}
                                status={x.pasoSubStatus}
                                module={x.pasoDescripcion}
                                nameStep={`Paso ${x.paso}`}
                                percentage={x.porcentaje}
                                handleClick={() => { handleNavigate(x) }}
                            />
                        )
                    }

                </div>
            </section>
        </>
    )
}

export default SiniestroDetalle