import React from "react";
import SeguimientoPagoUnidadesDetalleHook from "../../../Hooks/UnidadesPendientesPago/SeguimientoPagoUnidadesDetalleHook";
import { useLocation } from "react-router-dom";
import "./PagodeUnidad.css"
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import DataTable from "../../../Components/datatable/DataTable";
import RowPages from "../../../Components/RowPages";
import VisorFile from "../../../Components/VisorFile";
import RegistrarPagoUnidadModal from "./RegistrarPagoUnidadModal";
import PagodeUnidadLimiteModal from "./PagodeUnidadLimiteModal";
import toast, { Toaster } from 'react-hot-toast'

const PagodeUnidadDetalle = () => {
    const { state } = useLocation()

    const {
        columns,
        priceColumns,
        concludeColumns,
        setName,
        handleReturnPage,
        currentData = [],
        filteredData = [],
        sumaTotal,

        currentPage,
        pageCount,
        setpageRows,
        handlePageClick,
        handlechekedItem,

        handleConfiguracionProvisional,
        handleEditar,
        handleAutorizarPago,
        handleRegistrarPago,

        loading,
        setLoading,
        pantalla,
        setPantalla,

        metodosPago,
        formaPago,

        handleViewdocs,
        handleUploadFile,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,

        handleModalRegistroPago,
        isOpenModalRegistrarPago,
        setisOpenModalRegistrarPago,
        infoRegistro,
        isOpenModalLimite,
        setisOpenModalLimite,
        handleDesasignarVehiculo,

        unidadesPagadas,
        unidadesPendientes,
        montoTotalPagado,
    } = SeguimientoPagoUnidadesDetalleHook(state)

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            {isOpenModalRegistrarPago &&
                <RegistrarPagoUnidadModal
                    isOpen={isOpenModalRegistrarPago}
                    setIsOpen={setisOpenModalRegistrarPago}
                    handleRegistrarPago={handleRegistrarPago}
                    state={infoRegistro}
                    folio={state.id}
                    metodosPago={metodosPago}
                    formaPago={formaPago}
                    setLoading={setLoading}
                    toast={toast}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
            {isOpenModalLimite &&
                <PagodeUnidadLimiteModal
                    isOpen={isOpenModalLimite}
                    setIsOpen={setisOpenModalLimite}
                    state={state}
                />

            }
            {/* <SubMenuPagoUnidad/> */}
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                    <i className="bx bxs-chevron-left mx-2" ></i>
                    Regresar a listado de Seguimiento
                </div>
            </header>
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>{`Pago de Unidad ${state.tipoCompra} - ${state.id}`}</strong></span>
                    {pantalla === "Inicial" ?
                        <div>
                            <button className='btn btn-outline me-3' onClick={() => { handleConfiguracionProvisional() }}>
                                Configuración Provisional
                            </button>
                            <button className='btn btn-blue' onClick={() => { handleAutorizarPago() }}>
                                Autorizar Pago
                            </button>
                        </div>
                        :
                        null
                    }
                    {pantalla === "Configuración provisional" ?
                        <div>
                            <button className='btn btn-dark' style={{ width: "10rem" }} onClick={() => { handleEditar() }}>
                                Editar
                            </button>
                        </div>
                        :
                        null
                    }
                    {pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" || pantalla === "Concluido" ?
                        <button className='btn btn-blue' onClick={console.log()}>
                            Exportar Excel
                        </button>
                        :
                        null
                    }
                </div>
                {pantalla === "Inicial" || pantalla === "Configuración provisional" ?
                    <span className='mt-2'>{`Selecciona los vehículos a pagar tomando en cuenta tu presupuesto y concluye la configuración cuando este completo tu presupuesto.`}</span>
                    : null}

                {pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ?
                    <span className='mt-2'>{`Registra los pagos de unidad para el presupuesto creado.`}</span>
                    : null}

            </section>

            <div>
                <div className="col-12 d-flex my-4">
                    <div className="col-2">
                        <div className="pago-unidad-card-primary">
                            <label className="text-sm">Presupuesto</label>
                            <label className="text-xl">{`$${state.presupuesto}`}</label>
                        </div>
                    </div>
                    {pantalla === "Inicial" || pantalla === "Configuración provisional" ?
                        <>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Unidades seleccionadas</label>
                                    <label className="text-xl">{`${state.numeroitemsxfolio}`}</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Monto total por pagar</label>
                                    <label className="text-xl">{`$${sumaTotal}.00`}</label>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ?
                        <>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Unidades pagadas</label>
                                    <label className="text-xl">{unidadesPagadas}</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Unidades pendientes por pagar</label>
                                    <label className="text-xl">{unidadesPendientes}</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Monto total por pagar</label>
                                    <label className="text-xl">{`$${sumaTotal}.00`}</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Monto total pagado</label>
                                    <label className="text-xl">${montoTotalPagado}.00</label>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {pantalla === "Concluido" ?
                        <>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Unidades pagadas</label>
                                    <label className="text-xl">{`${state.numeroitemsxfolio}`}</label>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="pago-unidad-card-secondary">
                                    <label className="text-sm">Monto total pagado</label>
                                    <label className="text-xl">{`$${montoTotalPagado}.00`}</label>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
            {/* Aqui van otras dos pero hasta que tenga como cambiar el estatus */}
            <div className="col-12 mb-4 mx-4">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 mt-4 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setName(e.target.value) }} />
                    </div>
                </div>
            </div>
            <RowPages setpageRows={setpageRows} />

            <div className=" table-responsive mx-4">

                {loading ? (
                    <p>Loading data...</p>
                ) : currentData.length > 0 ?
                    <>
                        {pantalla === "Concluido" &&
                            <DataTable
                                column={concludeColumns}
                                data={filteredData} // Use the `currentData` from the hook

                                paginate
                                pageCount={pageCount}
                                currentPage={currentPage - 1}

                                handleDocuments={handleViewdocs}
                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                            />
                        }
                        {
                            pantalla !== "Inicial" && pantalla !== "Concluido" &&
                            <DataTable
                                column={pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ? priceColumns : columns}
                                checkbox={pantalla === "Inicial" ? true : false}
                                handleCheck={handlechekedItem}
                                data={filteredData} // Use the `currentData` from the hook

                                paginate
                                pageCount={pageCount}
                                currentPage={currentPage - 1}

                                actionColumns={pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ? true : false}
                                desasignarColumn={pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ? true : false}
                                handleDesasignar={handleDesasignarVehiculo}
                                moneyColumn={pantalla === "Pendiente por pagar" || pantalla === "Pago en proceso" ? true : false}
                                handleMoney={handleModalRegistroPago}

                                handleDocuments={handleViewdocs}
                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                            />
                        }
                        {
                            pantalla === "Inicial" &&
                            <DataTable
                                column={columns}
                                checkbox={true}
                                handleCheck={handlechekedItem}
                                data={currentData} // Use the `currentData` from the hook

                                paginate
                                pageCount={pageCount}
                                currentPage={currentPage - 1}
                            />
                        }
                    </>
                    : (
                        <p>No hay datos disponibles.</p>
                    )}
            </div>
        </>
    )

}

export default PagodeUnidadDetalle