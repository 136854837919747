import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../Redux/Slice/Cobranza/ReasignarCargaNivelUnoSlice';

const useSupervisionCargaNivel1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Columnas de vista principal
  const columns = [
    { field: 'nombrecobrador', headerName: 'Cobrador', width: 200 },
    { field: 'cantidadcontratos', headerName: 'Cantidad contratos', width: 200 },
    { field: 'fechabalanceo', headerName: 'Fecha balanceo', width: 170 },
  ]

  const columnsDetail = [
    { field: 'numero', headerName: '#', width: 50 },
    { field: 'contrato', headerName: 'Contrato', width: 150 },
    { field: 'diasvencidos', headerName: 'Días vencidos', width: 170 },
    { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 220 },
    { field: 'montovencido', headerName: 'Monto vencido', width: 200 },
    { field: 'saldoinsoluto', headerName: 'Saldo insoluto', width: 200 },
  ];

  const { strBusqueda, fechaInicio, fechaFin, concluido} = useSelector((state) => state.ParametrosBusquedaSlice);
  const [data, setData] = useState([]);
  const [dataCobradores, setDataCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState();
  const [page, setPage] = useState(1);
  const [idCobrador, setIdCobrador] = useState(0);
  const [str, setStr] = useState("");
  const [isOpenModal, setIsOpenModal] = useState();
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [item, setItem] = useState(null);
  const [accionBuscar, setAccionBuscar] = useState(false);

  // useEffect(() => {
  //     handleGetallCobradores(1, pageRows, idCobrador, idNivel, str);
  //     setPage(1);
  // }, [item])
  //}, [item, pageRows, idCobrador, idNivel, str])

  useEffect(() =>{
    handleGetAllData();
    getAllCobradores();
  }, []);

  const handleGetAllData = async () => {
    let dataContratos = [];
    let dataUsuarios = [];
    await Cobranza.getEjecutivosFullDetail()
      .then(res => {
        if(res.data.callSucceded){
          res.data.responseData.map(item => {
            if(item.nivel_cobrador == "1")//La comparación debe ser con 1, se usa 2 solo para pruebas ya que no hay registros con nivel 1
            {
              let count = 1;
              item.contratos.map(cont => {
                dataContratos.push({
                  numero: count,
                  contrato: cont.contrato_id,
                  diasvencidos: cont.dias_vencido,
                  diassinconsultarclave: cont.dias_sin_consultar_clave,
                  montovencido: "0",
                  saldoinsoluto: "0"
                });
                count++;
              });
              dataUsuarios.push({
                userid:item.userid,
                nombrecobrador: item.user_detail.names,
                cantidadcontratos: item.numContratos,
                fechabalanceo: "0",
                detail: dataContratos
              });
            }
          })
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(`ERROR -- ${e}`)
      })
      setData(dataUsuarios);
      setLoading(false);
  }

  // const handlePageClick = (event) => {
  //     setPage(event.selected + 1);
  //     handleGetallCobradores(event.selected + 1);
  //   };

  const getAllCobradores = async () => {
    let NewData = [{value:null, label:"Seleccionar"}];
    //Se usa el endpoint solo para pruebas, se debe cambiar por el correspondiente
    await Cobranza.getAllUsersNotInCobranza()
        .then(res => {
            if(res.data.callSucceded){
                res.data.responseData.map(item => {
                    NewData.push({value: item.userId, label: item.names})
                })
            }
        })
        .catch(e => {
            console.log(`ERROR -- ${e}`)
        })
        setDataCobradores(NewData);
  }

  const handleDetails = (item) =>{
    dispatch(setUsuario({
      userid: item.userid,
      nombrecobrador: item.nombrecobrador,
      numerocontratos: item.cantidadcontratos,
      contratos: item.detail
    }));

    navigate(`/cobranza/reasignarcargatrabajoniveluno`, {
      state: {
          page: 'Detalle',
          title: "Editar usuario"
      },
      });
  }
  
  return {
    columns,
    columnsDetail,
    data,
    pageCount,
    dataCobradores,
    setIdCobrador,
    setStr,
    isOpenModal,
    setIsOpenModal,
    loading,
    // handlePageClick,
    // handleEdit,
    // page,
    setpageRows,
    setAccionBuscar,
    handleDetails
  }
}

export default useSupervisionCargaNivel1