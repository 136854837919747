import { Formik } from "formik";
import { SelectField } from "../SelectField";
import { useEffect, useRef, useState } from "react";

const CalendarDinamical = ({ sundayOn = false, type = 1, halfDay = 12, hourClose = 20 }) => {
    const refFormik = useRef();
    const parentDiv = useRef(null);
    const sucursales = [
        { value: 1, label: 'Dummy 1' },
        { value: 2, label: 'Dummy 2' }
    ];
    let initial = {
        sucursalId: null,
        vista: 1
    }
    const [initialValue, setInitialValue] = useState(initial);
    const Week = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    const [tamanoCasilla, setTamanoCasilla] = useState(0);

    const handleGetWidth = (vista) => {
        let cells = vista === 1 ? 7 : 8;
        const width = parentDiv.current ? (parentDiv.current.offsetWidth - 40) : 0;
        const widthDiv = Math.ceil((width /cells)-10);
        setTamanoCasilla(widthDiv);
    };

    
    useEffect(() => {
        // setLoading(true);
        handleGetWidth(initialValue.vista);
    }, []);

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <div className="row justify-content-center mt-4">
                            <div className="col-11 px-1">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="fw-bold">Calendario para agendar citas</h4>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-3">
                                        <button className="btn">Hoy</button>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <button className="btn btn-indicator">
                                                    <i className="glyphicon fas fa-angle-left"></i>
                                                </button>
                                            </div>
                                            <div className="col-8 text-center px-md-2">
                                                <h4 className="fw-bold">Mes 2024</h4>
                                            </div>
                                            <div className="col-2">
                                                <button className="btn btn-indicator">
                                                    <i className="glyphicon fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <SelectField id="sucursalId" name="sucursalId" items={sucursales} onChange={(event) => {
                                                    setFieldValue("sucursalId", event.value);
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <SelectField id="sucursalId" name="sucursalId" items={sucursales} onChange={(event) => {
                                                    setFieldValue("sucursalId", event.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* VISTA MENSUAL */}
                                {
                                    values.vista === 1 &&
                                    <div className="row my-4 justify-content-center" ref={parentDiv}>
                                        {
                                            Week.map((i, idx) => {
                                                return <div key={'dia_' + idx} className='text-center cell-calendar' style={{ maxWidth: tamanoCasilla, minHeight: '40px', lineHeight: '40px' }}><small><strong>{i}</strong></small></div>
                                            })
                                        }
                                    </div>
                                }
                                {/* VISTA SEMANAL */}

                            </div>
                        </div>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default CalendarDinamical;