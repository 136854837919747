import React, { useRef, useState } from 'react';
import { Formik, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import RowPages from '../../../Components/RowPages';
import DataTable from "../../../Components/datatable/DataTable";
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermissionById } from '../../../Auth/ValidatePermission';
import SubMenuPagoUnidad from './SubMenuPagoUnidad';
import SeguimientoPagoUnidadesHook from '../../../Hooks/UnidadesPendientesPago/SeguimientoPagoUnidadesHook';
import { SelectField } from '../../../Components/SelectField';
import RegistrarPagoModal from '../../../Components/Modal/RegistrarPagoModal';
import BusquedaAvanzadaModal from '../../Cobranza/SupervisionDeCarga/BusquedaAvanzadaModal';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';

const SeguimientoUnidades = () => {
  const refFormik = useRef();
  const [selectedData, setSelectedData] = useState(null);
  const [isOpenBusquedaAvanzada, setIsOpenBusquedaAvanzada] = useState(false);
  // const [cobradorSeleccionado, setCobradorSeleccionado] = useState('');

  const {
    setPageRows,
    columns,
    currentData = [], // Initialize `currentData` as an empty array to avoid errors
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    loading,  // Destructure `loading` from the hook
    modalLoading,
    isOpenModalRegistrar, 
    setIsOpenModalRegistrar,

    tipoCompraSelect,
    estatusSelect,
    setTipoCompra,
    setEstatus,
    setName,
    RegistrarPago,

    getReporteExcel,
  } = SeguimientoPagoUnidadesHook();

  // const randomCobradores = Array.from({ length: 5 }, (_, index) => ({
  //     label: `Cobrador ${index + 1}`,
  //     value: `cobrador_${index + 1}`,
  // }));

  const handleOpenModal = () => {
    setIsOpenModalRegistrar(true); // Abre el modal
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246',
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a',
            },
          },
        }}
      />

      {isOpenModalRegistrar && (
        <RegistrarPagoModal
          setIsOpen={setIsOpenModalRegistrar}
          isOpen={isOpenModalRegistrar}
          toast={toast} // Si necesitas el prop toast
          RegistrarPago={RegistrarPago}
        />
      )}
      {
        isOpenBusquedaAvanzada && (
          <>
            <BusquedaAvanzadaModal
              isOpen={isOpenBusquedaAvanzada}
              setIsOpen={setIsOpenBusquedaAvanzada}
            // setAccionBuscar={setAccionBuscar}
            />
          </>)
      }
      <Modal isOpen={modalLoading} color='transparent'><Spinner message={""} /></Modal>
      <Formik
        innerRef={refFormik}
        initialValues={{
          buscar: "",
          cobradorSeleccionado: "",
          page: 1,
          rows: 10,
        }}
        onSubmit={(values) => {
          // Lógica para manejar el envío del formulario
        }}
      >
        {({ setFieldValue }) => (
          <>
            <SubMenuPagoUnidad />
            <section>
              <div className="pb-4">
                <section className="mx-4 my-4 d-flex flex-column">
                  <div className="col-12">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <strong className="mt-2">Seguimiento pago de unidades</strong>
                        <p className="mt-4">
                          <strong>Selecciona los vehículos a pagar tomando en cuenta tu presupuesto</strong>
                        </p>
                      </div>
                      <div className="col-auto ">
                        <button className='btn me-3' onClick={getReporteExcel}>
                          Exportar Excel
                        </button>
                        <button className='btn' onClick={handleOpenModal}>
                          Registrar Pago
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-2 mt-4 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <Field
                          type="text"
                          name="buscar"
                          className="form-control mb-4"
                          placeholder="Buscar"
                          onChange={(e) => {
                            setFieldValue('buscar', e.target.value)
                            setName(e.target.value)  
                          }}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 mt-4">
                        <SelectField
                          id="TipoCompraSeleccionado"
                          name="tipoCompraSeleccionado"
                          placeholder="Tipo de compra"
                          items={tipoCompraSelect}
                          onChange={(option) => {
                            setFieldValue('tipoCompraSelect', option.value);
                            setTipoCompra(option.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 mt-4">
                        <SelectField
                          id="estatusSeleccionado"
                          name="estatusSeleccionado"
                          placeholder="Estatus"
                          items={estatusSelect}
                          onChange={(option) => {
                            setFieldValue('estatusSelect', option.value);
                            setEstatus(option.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 mt-4">
                        <button className='btn btn-blue' onClick={() => { setIsOpenBusquedaAvanzada(true) }}  >Búsqueda Avanzada</button>
                      </div>
                    </div>
                  </div>
                  <RowPages setpageRows={setPageRows} />
                  <div className="mx-4 table-responsive">
                    {loading ? (
                      <p>Cargando datos...</p>
                    ) : currentData.length > 0 ? (
                      <DataTable
                        column={columns}
                        data={currentData}
                        handleDetail={handleDetails}
                        detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                        paginate
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currentPage}
                      />
                    ) : (
                      <p>No hay datos disponibles.</p>
                    )}
                  </div>
                </section>
              </div>
            </section>
          </>
        )}
      </Formik>
    </>
  );
};

export default SeguimientoUnidades;
