import React from 'react'
import img from '../../../Assets/img/datadocs.png';
import { useLocation } from 'react-router-dom'
import imgIsotopo from '../../../Assets/img/Isotipo.png';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import toast, { Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Watermark from '@uiw/react-watermark';
import AuthLogin from '../../../Utils/AuthLogin';
import FileComponent from './FileComponent';
import useDataDocs from '../../../Hooks/Docs/useDataDocs';
import { useSelector } from 'react-redux';
import ModalFileNameAndPM from './modalFileNameAndPM';
import ModalNumPoliza from './modalNumPoliza';
import ModalInstalacionConfir from './modalInstalacionconfirm';
import ModalSolFirma from './modalSolFirma';
import ModalConfirmGestoriaAutorizada from './modalConfirmGestoriaAutorizada';
import ModalConfirmGestoriaRechazada from './modalConfirmGestoriaRechazada';
import ModalConfirmEndoso from './modalConfirmEndoso';
import ModalConfirmEntrega from './modalConfirmEntrega';
import ModalConfirmEntregaRechazada from './modalConfirmEntregaRechazada';
import ModalExpediente from './modalExpediente';
import { ValidatePermission } from '../../../Auth/ValidatePermission';


const auth = new AuthLogin();

const AltaDataDocs = () => {
  const { role } = useSelector((state) => state.loginSlice);
  const location = useLocation();
  let url = location.pathname.split("/");
  let module = url[2];

  const {
    dataDocuments,
    handleGetFileDataDocs,
    itemSelected,
    fileName,
    setopenModalChangeName,
    handledownloadFile,
    setloadModalConfirmDelete,
    setLoadModalConfirm,
    existFile,
    selectedDocs,
    setSelectedFile,
    fileRef,
    navigate,
    actions,
    handleChangeFile,
    changeHandler,
    refbtn,
    handleSubmit,
    loadModalConfirmDelete,
    handleSubmitDelete,
    openModalChangeName,
    setFileName,
    loading,
    setOpenModalDocs,
    openModalDocs,
    selectedFile,
    loadModalConfirm,
    setItemSelected,
    isPruebaManejo,
    state,
    setEstadoId,
    handleSubmitFile,
    isEmisionPoliza,
    setEsFirmaContrato,
    esFirmaContrato,
    esGestoriaAutorizado,
    setOpenModalGestoriaRechazada,
    openModalGestoriaRechazada,
    setOpenModalGestoriaAutorizada,
    openModalGestoriaAutorizada,
    setesEndoso,
    esEndoso,
    openModalEndoso,
    setOpenModalEndoso,
    esEntregaRealizada,
    openModalEntrega,
    setOpenModalEntrega,
    setOpenModalEntregaRechazada,
    openModalEntregaRechazada,
    esinstalacionDatamovil,
    setEsinstalacionDatamovil,
    esExpediente,
    openModalExpediente,
    setOpenModalExpediente,
    handleUploadFileToAcendes,
    setselectedItem,
    setOpenModalEmisionPoliza,
    openModalEmisionPoliza,
    setOpenModalPM,
    openModalPM,
    handlePermisosById,
    permisoDocumento
  } = useDataDocs(toast);

  const handleOpenModals = (item, value) => {
    setOpenModalGestoriaAutorizada(null);
    setOpenModalGestoriaRechazada(null);
    setOpenModalEndoso(null);
    setOpenModalEntrega(null);
    setOpenModalEntregaRechazada(null);
    setEsFirmaContrato(null);
    setOpenModalExpediente(null);
    setOpenModalEmisionPoliza(null);
    setOpenModalPM(null);

    if (item.etapaId === 13 && esGestoriaAutorizado)
      setOpenModalGestoriaAutorizada(handleCheckValue(item, value));
    if (item.etapaId === 13 && esGestoriaAutorizado === false)
      setOpenModalGestoriaRechazada(handleCheckValue(item, value));
    if (item.etapaId === 14 && esEndoso)
      setOpenModalEndoso(true);
    if (item.etapaId === 15 && esEntregaRealizada)
      setOpenModalEntrega(handleCheckValue(item, value));
    if (item.etapaId === 15 && esEntregaRealizada === false)
      setOpenModalEntregaRechazada(handleCheckValue(item, value));
    if (item?.etapaId === 11 && esinstalacionDatamovil)
      setEsFirmaContrato(handleCheckValue(item, value))
    if (item?.etapaId === 11 && !esinstalacionDatamovil)
      setEsFirmaContrato(null)
    if (item.etapaId === 16 && esExpediente)//esExpediente
      setOpenModalExpediente(handleCheckValue(item, value));
    if (item.etapaId === 8 && isEmisionPoliza)//esExpediente
      setOpenModalEmisionPoliza(true);
    if (item.etapaId === 5 && isPruebaManejo)
      setOpenModalPM(true);
  }
  const handleCheckValue = (item, value) => {
    return ((item.totalDoc - item.totalSubidos === 1) && !value.existe) ? true : false
  }
  const handleReturnPage = () => {
    if (state?.placas)
      navigate(`/inventario/gestoria/placas/${state.generalId}`, {
        state: { ...state, action: 1 },
      });
    else if (state?.revisionUnidad) {
      navigate(`/inventario/venta/revisiondeunidad/${state.generalId}`, {
        state: { ...state, action: 1 },
      });
    } else if (module === 'comisiones') {
      navigate('/inventario/comisiones')
    } else if (state?.pruebaManejo) {
      navigate('/inventario/pruebamanejo')
    } else if (state?.esSocioComercial) {
      navigate('/inventario/sociocomercial')
    } else if (state?.endoso) {
      navigate('/inventario/endoso')
    } else if (state?.vehGestoria) {
      navigate('/inventario/gestoria')
    }else if(state?.entrega){
      navigate('/inventario/entrega')
    }
    else if(state?.instalacionDM || state?.instalacionDatamovil){
      navigate('/inventario/instalaciones')
    }
    else if (state?.emisionPoliza || state?.reenvioPoliza) {
      navigate('/seguro/poliza')
    }else if(state?.expediente){
      navigate('/inventario/expediente');
    }
    else if (state?.datamovil)
      navigate('/inventario/vehiculo')
    else if (state?.pagoUnidadDetalles)
      navigate('/inventario/pagoUnidad/seguimiento-pago-unidades/')
    else
      navigate('/inventario/vehiculo')
  }
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
        <i className="bx bxs-chevron-left mx-2" onClick={() => { handleReturnPage() }}></i>
        Regresar a Listado general | <strong className='ms-2 me-1'>{state?.vin} - {state?.nombreMarca} {state?.nombreModelo} {state?.nombreVersion} {state?.nombreYear}</strong>
      </header>
      <div className='row m-0' style={{ height: '89.5vh' }}>
        {/* <SubMenuPrealta /> */}
        <aside id="sidebar" className="sub-sidebar col-2 p-0">
          <ul className="sidebar-nav" id="sidebar-nav">
            {
              dataDocuments?.map((item, index) => {
                if (item.documentos.length > 0) {
                  return (
                    <li className="nav-item my-0" key={index}>
                      <a className="nav-link listDocs" data-bs-target={`#components-${item.etapaId}`} data-bs-toggle="collapse">
                        <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}> <label><strong>{item.etapa}</strong></label> <label className='me-2' style={{ fontSize: '13px' }}>{item.conteoDoc}</label></div>
                        <i className="bi bi-chevron-down ms-auto"></i> </a>
                      <ul id={`components-${item.etapaId}`} className="nav-content collapse show" data-bs-parent="#sidebar-nav" >
                        {
                          item.documentos?.map((value, i) => {
                            return (
                              <div key={i}>
                                <section className='d-flex align-items-center justify-content-between' key={i} onClick={value.reemplazar ? () => { handlePermisosById(value); handleGetFileDataDocs(value, value); handleOpenModals(item, value); } : undefined}>
                                  <div className='d-flex align-items-centerpx-2 my-2'>
                                    {
                                      value.existe && value.reemplazar ?
                                        <i className={`ri-file-upload-fill`} style={{ fontSize: '2rem', color: '#47a066' }}></i>
                                        : null
                                    }
                                    <div className='ms-2'>
                                      <p style={{ fontSize: '13px' }} className='my-0'>{value.nombre}</p>
                                      {
                                        value.existe && value.reemplazar &&
                                        <p style={{ fontSize: '12px' }} className='my-0'>{value.documentoEnDatadocs}</p>
                                      }
                                    </div>
                                  </div>
                                  {
                                    !value.reemplazar &&
                                    !state?.reenvioPoliza &&
                                    <ValidatePermission isActive={value?.agregar}>
                                      <div className='pe-4' key={i} onClick={() => {
                                        handlePermisosById(value);
                                        setItemSelected(value); setOpenModalDocs(true);
                                        handleOpenModals(item, value);
                                        setselectedItem(value);
                                      }}><li className='ri-add-circle-fill'></li></div>
                                    </ValidatePermission>
                                  }
                                  {
                                    value.reemplazar ?
                                      value.cargaAcendes === 1 ?
                                        <span className='me-4'><i className='icon-green ri-checkbox-circle-fill'></i></span>
                                        : value.cargaAcendes === 0 ? <span className='me-4'><i className='icon-gray ri-checkbox-blank-circle-fill'></i></span> : null : null
                                  }
                                </section>
                                {
                                  value.documentosSubidos && !value.reemplazar &&
                                  <FileComponent
                                    list={value.documentosSubidos}
                                    handleClick={(item) => { handlePermisosById(item); handleGetFileDataDocs(value, item); }}
                                  />
                                }
                              </div>
                            )
                          })
                        }
                      </ul>
                    </li>
                  )
                }
              })
            }
          </ul>
        </aside>
        <section className='col-10 px-0 mx-0'>
          <header style={{ height: '44px' }} className='heder-subtitle d-flex justify-content-between align-items-center border-bottom'>
            <span style={{ fontSize: '13px' }} className='ms-4'><strong>Nombre del archivo para {itemSelected?.nombre}: {fileName}</strong></span>
            <div className='d-flex me-4 align-items-center'>
              {/* {
                actions.replazed && actions.continue &&
                <i className="icon ri-ball-pen-fill mx-2" onClick={() => { setopenModalChangeName(true) }}></i>
              } */}
              {
                actions.acendes &&
                <ValidatePermission isActive={permisoDocumento?.reenviar}>
                  <i className="icon fs-4 ri-send-plane-fill mx-2" onClick={() => { handleUploadFileToAcendes() }}></i>
                </ValidatePermission>
              }
              {
                actions.replazed &&
                <ValidatePermission isActive={permisoDocumento?.editar}>
                  <i className="icon fs-3 bx bx-sync mx-2" onClick={() => { setOpenModalDocs(true) }}></i>
                </ValidatePermission>
              }
              {
                actions.downloaded &&
                <ValidatePermission isActive={permisoDocumento?.descarga}>
                  <>
                    <i className="icon fs-3 bx bx-download mx-2" onClick={handledownloadFile}></i>
                  </>
                </ValidatePermission>
              }
              {
                actions.deleted &&
                <ValidatePermission isActive={permisoDocumento?.eliminar}>
                  <i className="icon fs-3 bx bxs-trash-alt mx-2" onClick={() => { setloadModalConfirmDelete(true) }}></i>
                </ValidatePermission>
              }
              {
                actions.continue &&
                <ValidatePermission isActive={permisoDocumento?.agregar}>
                  <button className='btn' onClick={() => { setLoadModalConfirm(true) }}>Continuar</button>
                </ValidatePermission>
              }
            </div>
          </header>
          <div className='visorDatadocs' style={{ height: '100% !important' }}>
            {
              existFile === null &&
              <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '80vh !important' }}>
                <img src={img} style={{ width: '30rem' }} />
                <div className='fs-4 text-center mb-3'>Documentos</div>
                <div className='fs-6 text-center' style={{ width: '600px' }}>Para continuar, selecciona el documento correspondiente que deseas visualizar.</div>
              </div>
            }
            {
              existFile &&
              <Watermark
                // content="DataMovil"
                // fontSize={70}
                // fontColor="red"
                // style={{ background: '#fff' }}
                rotate={0}
                height={265}
                width={265}
                image={imgIsotopo}
              >
                <DocViewer
                  className="my-doc-viewer-style"
                  documents={selectedDocs.map((file) => ({
                    uri: window.URL.createObjectURL(file),
                    fileName: file.name,
                  }))}
                  pluginRenderers={DocViewerRenderers}
                />
              </Watermark>

            }
            {
              existFile === false &&
              <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '80vh' }}>
                <span><strong>Cargar Documentos</strong></span>
                <span style={{ fontSize: '12px' }}>Da click en el botón para continuar</span>
                <button className='btn mt-2' onClick={() => { setOpenModalDocs(true) }}>Subir Documentos</button>
              </div>
            }
          </div>

        </section>
      </div >
      {
        openModalDocs &&
        <Modal isOpen={openModalDocs} color='#fff'>
          <div className='container-buttons'>
            <div className='d-flex justify-content-end'>

              <i className="icon ri-close-circle-line fs-3" onClick={() => { setOpenModalDocs(false); setSelectedFile(null) }}></i>
            </div>
            <h4><strong>Subir Archivos</strong></h4>
            <span>
              Selecciona el archivo que desees subir
            </span>
            <div className="mt-5">
              <div className="my-3">
                <input className="form-control d-none" type="file" id="formFile" ref={fileRef} onChange={handleChangeFile} />
                <section className='section-file d-flex flex-column' onClick={changeHandler}>
                  <i className='icon-up ri-upload-cloud-2-line'></i>
                  <span>{selectedFile?.name ?? fileName}</span>
                </section>
              </div>
            </div>
          </div>
        </Modal>
      }
      {
        loadModalConfirmDelete &&
        <Modal isOpen={loadModalConfirmDelete} color='#fff'>
          <div className='container-buttons'>
            <h5><strong>Confirmar eliminación</strong></h5>
            <span style={{ fontSize: '12px' }}>
              ¿Estás seguro que deseas eliminar el archivo seleccionado? <br /> <br /> Todos los archivos eliminados serán almacenados en un contenedor ubicado en el panel administrativo.
            </span>
            <div className="mt-5">

            </div>
            <div className='d-flex justify-content-around mt-5'>
              <button className="btn btn-outline col-5" type="button" onClick={() => { setloadModalConfirmDelete(false) }}>Regresar</button>
              <button className="btn col-5" type="button" onClick={() => { handleSubmitDelete() }}>Aceptar</button>
            </div>
          </div>
        </Modal>
      }
      {/* {
        (loadModalConfirm && !isPruebaManejo) &&
        <ModalFileName
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={fileName}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(name) => { setFileName(name); handleSubmit(name) }}
        />
      } */}
      {
        (loadModalConfirm) &&
        <ModalInstalacionConfir
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalPM) &&
        <ModalFileNameAndPM
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={state}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); setEstadoId(item.estadoId); handleSubmitFile(item) }}
        // submit={(item)=>{setFileName(item.filename);setEstadoId(item.estadoId);handleSubmit(item.filename)}}
        />
      }
      {
        (loadModalConfirm && openModalEmisionPoliza) &&
        <ModalNumPoliza
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); setEstadoId(item.estadoId); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && esFirmaContrato === false) &&
        <ModalInstalacionConfir
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && esFirmaContrato) &&
        <ModalSolFirma
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalGestoriaAutorizada) &&
        <ModalConfirmGestoriaAutorizada
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalGestoriaRechazada) &&
        <ModalConfirmGestoriaRechazada
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalEndoso) &&
        <ModalConfirmEndoso
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalEntrega) &&
        <ModalConfirmEntrega
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalEntregaRechazada) &&
        <ModalConfirmEntregaRechazada
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      {
        (loadModalConfirm && openModalExpediente) &&
        <ModalExpediente
          isOpen={loadModalConfirm}
          setIsOpen={setLoadModalConfirm}
          item={{ ...state, filename: fileName }}
          handleGetAll={(name) => { setFileName(name) }}
          submit={(item) => { setFileName(item.filename); handleSubmitFile(item) }}
        />
      }
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
    </>
  )
}

export default AltaDataDocs