import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Formik } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { message, FormatNumberMoney } from '../../Utils/functions';


const RegistrarPagoModal = ({ isOpen, setIsOpen, data, toast , RegistrarPago}) => {
  const refFormik = useRef();
  const [resultado, setResultado] = useState('');
  const [motivoItems, setmotivoItems] = useState([]);


  const handleSubmit = (values) => {
    RegistrarPago(values)
  };

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={{
          resultado: '', // Valor inicial de 'resultado'
          motivo: '', // Agrega motivo al initialValues
          message: '' // Agrega message al initialValues
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Modal isOpen={isOpen} color='#fff' width={550}  >
            <div>
              <div className='justify-content-start p-3 align-items-center'>
                <div className="row">
                  <div className="col col-12 ">
                    <div>
                      <h4><strong>Registrar pago</strong></h4>
                      <p>Por favor indica el tipo de unidad y presupuesto.</p>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-6 col-6 ">
                    <SelectField
                      id="tipocompra"
                      label="Tipo de Compra"
                      name="tipocompra"
                      items={[
                        { label: "Inventario", value: "Inventario" },
                        { label: "Externo", value: "Externo" }
                      ]}
                      onChange={(option) => {
                        setFieldValue("tipocompra", option.value);
                        setResultado(option.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-6 col-6 ">
                    <TextField
                      id="presupuesto"
                      label="Presupuesto"
                      name="presupuesto"
                      holder="Escribir"
                      isMoney={true}
                      onChange={(event) => {
                        setFieldValue("presupuesto", FormatNumberMoney(event.target.value));
                    }}
                    />
                  </div>

                </div>

                <div className="row justify-content-end mt-4">
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnCloseModal"
                      className='btn btn-outline btn-block'
                      onClick={()=> setIsOpen(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnConfirmModal"
                      className='btn btn-blue btn-block'
                      onClick={handleSubmit}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default RegistrarPagoModal;
