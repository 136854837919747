import axios from '../../Utils/ApiService';

const DiasFestivosService = {
    getDiaFestivos: async(data) => {
        return (await axios.get(`/Cartera/GetDiasFestivos`)).data;
    },
    SaveDiaFestivo: async(data) => {
        return (await axios.post(`/Cartera/SaveDiaFestivo`, data)).data;
    },
    DeleteDiaFestivo: async(data) => {
        return (await axios.post(`/Cartera/DeleteDiaFestivo`, data)).data;
    },
}

export default DiasFestivosService