import React, { useEffect, useState } from 'react'
import Parametros from '../../Services/Parametros/Parametros';
import { FormatFecha } from '../../Utils/functions'


const ParametrosCarteraHooks = () => {
  const [strFiltro, setStrFiltro] = useState("")
  const [dataParametroSelect, setDataParametroSelect] = useState("");
  const [parametroSelect, setParametroSelect] = useState("");
  const [dataCategorias, setDataCategorias] = useState([]);
  // const [dataCategoriasWithParameters, setDataCategoriasWithParameters] = useState([]);
  const [dataAllParametrosCartera, setDataAllParametrosCartera] = useState([]);



  const columns = [
    { field: 'id', headerName: '#' },
    { field: 'descripcion', headerName: 'Parámetro' },
    { field: 'valor', headerName: 'Valor' },
    { field: 'extravalue', headerName: 'Valor extra' },
    { field: 'last_update', headerName: 'Última Modificación' }
  ];

  // Use Inicial 
  useEffect(() => {
    handleGetAllParametrosCartera();
    handleGetCategories();
    // handleGetParametrosByCategoria()
  }, [])

  useEffect(() => {

    // Hacer Proceso para  strFiltro

  }, [strFiltro])

  useEffect(() => {

    // Hacer Proceso para  strFiltro

  }, [parametroSelect])

  useEffect(() => {

    // Hacer Proceso para  strFiltro
  }, [dataCategorias])

  // Consulta de apis
  /* Todos los parametros */
  const handleGetAllParametrosCartera = async () => {
    await Parametros.GetAllParametrosCartera()
      .then(res => {
        let NewData = [
          { value: '', label: 'Parámetro' }
        ]

        res.data.responseData?.map(item => {
          NewData.push({
            value: item.id,
            label: item.descripcion
          })
          // }
        })

        let datosFormateados = res.data.responseData?.map((item) => {
          const fechaPago = FormatFecha(item.dateupdate)
          return {
            ...item,
            last_update: fechaPago
          }
        })

        setDataParametroSelect(NewData)
        setDataAllParametrosCartera(datosFormateados)
      }).catch(e => {
      })
  }

  /* Todos los parametros */
  const handleGetCategories = async () => {
    await Parametros.GetAllParametrosCartera()
      .then(res => {
        let NewData = [];

        res.data.responseData?.map(item => {
          NewData.push(
            item.categoria
          )
        })

        let result = NewData.filter((item, index) => {
          return NewData.indexOf(item) === index;
        })

        setDataCategorias(result)
      }).catch(e => {
      })
  }

  /* Todos los parametros */
  // const handleGetParametrosByCategoria = async (value) => {

  //   let data = {
  //     FIELD: "Categoria",
  //     VALUE: "seguimiento_contacto_telefonico"
  //   }

  //   await Parametros.GetAllParametrosByField(data)
  //     .then(res => {
  //       let NewData = [];

  //       res.data.responseData?.map(item => {
  //         NewData.push(
  //           value,
  //           item
  //         )
  //       })

  //       // return data
  //       setDataCategoriasWithParameters(NewData);
  //       // return res.data.responseData;
  //     });
  // }

  return { columns, setStrFiltro, dataParametroSelect, setParametroSelect, dataCategorias, dataAllParametrosCartera };
}
export default ParametrosCarteraHooks