import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import JobsCarteraService from '../../Services/Jobs/JobsCartera';
import * as Yup from 'yup';
import { message } from '../../Utils/functions';
import Spinner from '../Loadig';

const ActualizarJobs = ({ isOpenModalActualizar, setisOpenModalActualizar, state, setModalMessage, UpdateInfo }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const [frecuenciaMes, setFrecuenciaMes] = useState("")
    const inicial = {
        plazoId: "",
        horario: "",
        aplicaRangoHorario: "",
        horaInicio: "",
        horaFin: "",
        tiempo: 1,
        frecuenciaMensual: "",
        frecuenciaSemanal: []
    }

    let initialArray = [
        { active: false, name: 'D' },
        { active: false, name: 'L' },
        { active: false, name: 'Ma' },
        { active: false, name: 'Mi' },
        { active: false, name: 'J' },
        { active: false, name: 'V' },
        { active: false, name: 'S' },
    ]

    const mapeoDias = {
        Lunes: 'L',
        Martes: 'Ma',
        Miércoles: 'Mi',
        Jueves: 'J',
        Viernes: 'V',
        Sábado: 'S',
        Domingo: 'D'
    };

    const diasSemana = [
        "Domingo", // índice 0
        "Lunes",   // índice 1
        "Martes",  // índice 2
        "Miércoles", // índice 3
        "Jueves",  // índice 4
        "Viernes", // índice 5
        "Sábado"   // índice 6
    ];

    const [weeklyDays, setWeeklyDays] = useState(initialArray)
    const [initialValue, setInitialValue] = useState(inicial)
    const [frecuenciaSelect, setFrecuenciaSelect] = useState([])
    const [aplicaRangoHorario, setAplicaRangoHorario] = useState([{ value: 0, label: "Solo una vez" }, { value: 1, label: "Rango de horarios" }])
    const [frecuenciaMesSelect, setFrecuenciaMesSelect] = useState([
        { value: "primer", label: "Primer día hábil del mes" },
        { value: "segundo", label: "Segundo día hábil del mes" },
        { value: "ultimo", label: "Último día hábil del mes" },
    ])


    const getFrecuecia = async () => {
        let frecuencias = await JobsCarteraService.getAllFrecuenciaCartera()
            .then(res => {
                let NewData = []
                res.data?.map(item => {
                    if (item.nombre === "Minutos")
                        return null
                    if (item.nombre === "Días")
                        NewData.push({
                            value: item.plazoId,
                            label: "Diaria"
                        })
                    if (item.nombre === "Semanas")
                        NewData.push({
                            value: item.plazoId,
                            label: "Semanal"
                        })
                    if (item.nombre === "Mes")
                        NewData.push({
                            value: item.plazoId,
                            label: "Mensual"
                        })
                })
                return NewData
            }).catch(e => {
            })
        setFrecuenciaSelect(frecuencias)


        let horaFormateada = ""
        let frecuenciaMesId = ""
        if (state.horaProgramado) horaFormateada = convertirHoraA24Horas(state.horaProgramado)
        const frecuenciaId = frecuencias.find(item => item.label === state.frecuenciaFormateada);
        if (state.frecuenciaFormateada === "Mensual") {
            frecuenciaMesId = frecuenciaMesSelect.find(item => item.label === state.diaProgramado);
        }
        if (state.frecuenciaFormateada === "Semanal") {
            const diasArray = state.diaProgramado.split(",")
            if (diasArray.length > 0) {
                diasArray.forEach(dia => {
                    const abreviatura = mapeoDias[dia];
                    initialArray.forEach(diaAbreviado => {
                        if (diaAbreviado.name === abreviatura) {
                            diaAbreviado.active = true;
                        }
                    });
                });
            }
        }

        setInitialValue((prevValues) => ({
            ...prevValues,
            horario: horaFormateada,
            plazoId: frecuenciaId?.value || "",
            frecuenciaMensual: frecuenciaMesId?.value || ""
        }));
    }

    function convertirHoraA24Horas(hora12) {
        // Descomponemos la hora en su parte de tiempo y AM/PM
        const [time, modifier] = hora12.split(' ');
        // Descomponemos la hora y minutos
        let [hours, minutes] = time.split(':');
        // Convertimos a número la hora para poder manipularla
        hours = parseInt(hours, 10);
        // Si es "PM" y no es la hora 12, sumamos 12 para convertir a formato 24 horas
        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        }
        // Si es "AM" y es la hora 12, la cambiamos a 0 (medianoche)
        if (modifier.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }
        // Formateamos las horas para que siempre tenga dos dígitos
        const hoursFormatted = hours.toString().padStart(2, '0');
        return `${hoursFormatted}:${minutes}`;
    }

    function convertirHoraA12Horas(hora24) {
        // Descomponemos la hora en horas y minutos
        let [hours, minutes] = hora24.split(':');
        hours = parseInt(hours, 10); // Convertimos a número la hora

        // Determinamos si es AM o PM
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convertimos las horas de 24 a 12
        hours = hours % 12 || 12; // Si es 0 o 12, mostramos 12

        // Formateamos las horas para evitar un solo dígito
        const hoursFormatted = hours.toString();

        // Devolvemos la hora en formato 12 horas con AM/PM
        return `${hoursFormatted}:${minutes} ${ampm}`;
    }


    useEffect(() => {
        getFrecuecia()
    }, [])

    const toogleDate = (name) => {
        setWeeklyDays(prevItems =>
            prevItems.map(item =>
                item.name === name
                    ? { ...item, active: !item.active }
                    : item
            )
        )
    }

    const validateTimeFormat = (time) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/
        return regex.test(time)
    }

    const validationSchema = Yup.object({
        plazoId: Yup.string().required('Frecuencia is required'),
        horario: Yup.string().required('horario is required')
            .test('time-format', 'Invalid time Format', validateTimeFormat),
        aplicaRangoHorario: Yup.boolean().required('Repeticion is required'),

        // horaInicio: Yup.string()
        //     .when('aplicaRangoHorario', {
        //         is: 1, // Condition to check
        //         then: Yup.string().required('horario Inicio is required')
        // otherwise: Yup.string(), // Validation for other cases
        // }),

        horaFin: Yup.string()
            .when('aplicaRangoHorario', {
                is: true, // Condition to check
                then: Yup.string()
                    .required('horario Fin is required')
                    .test('time-format', 'Invalid time Format', validateTimeFormat)
                    .test(
                        'greater-than-min',
                        'Hora Inicio debe ser menor a Hora Fin',
                        function (value) {
                            const { horario } = this.parent
                            if (horario && value) {
                                return new Date(`1970-01-01T${value}:00`) > new Date(`1970-01-01T${horario}:00`)
                            }
                        }
                    ),
            }),

        tiempo: Yup.number()
            .when('aplicaRangoHorario', {
                is: true, // Condition to check
                then: Yup.number().min(1)
                    .required('Lapso de Repetición is required')
            }),

        frecuenciaMensual: Yup.string()
            .when('plazoId', {
                is: "4", // Condition to check
                then: Yup.string().required('Frecuencia Mes is required'),
            }),

    })

    const handlerGuardar = async (values) => {
        const activeItems = weeklyDays.filter(item => item.active)
        if (values.plazoId === 3 && activeItems.length === 0) {
            toast.error(message("Agregar al menos un Dia Semanal!"))
            return null
        }

        values.frecuenciaSemanal = []
        weeklyDays.map((data, index) => {
            if (!data.active) return null
            values.frecuenciaSemanal.push(index + 1)
        })

        let frecuenciaNombre
        frecuenciaSelect.map(item => {
            if (values.plazoId === item.value)
                frecuenciaNombre = item.label
        })

        values.nombreClaveJob = state.campo

        let horario = convertirHoraA12Horas(values.horario)

        if (values.plazoId === 3) {
            const diasSeleccionados = values.frecuenciaSemanal.map(index => diasSemana[index - 1])
            values.diaProgramado = diasSeleccionados.join(",")

        }
        if (values.plazoId === 4) {
            values.diaProgramado = frecuenciaMes
        }


        setLoading(true)
        await JobsCarteraService.updateJobCartera(values)
            .then(resp => {
                if (resp.data.estatus === true) {
                    // toast.success(message("Se modificó correctamente."))
                    setModalMessage({ isOpen: true, type: 2, message: "Se modificó correctamente." })
                    UpdateInfo(frecuenciaNombre, horario, values)
                    setisOpenModalActualizar(false)
                    refFormik.current.resetForm()
                    setTimeout(() => {
                        setModalMessage({ isOpen: false, type: 1, message: "" })
                    }, 3000)
                }
                else {
                    toast.error(message("¡Error, al intentar crear el Job!"))
                }
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handlerGuardar(values)
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Toaster
                            position="top-right"
                            toastOptions={{
                                success: {
                                    style: {
                                        background: '#47a066',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #2f7246'
                                    },
                                },
                                error: {
                                    style: {
                                        background: '#d53f3f',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #ac241a'
                                    },
                                },
                            }}
                        />
                        <Modal isOpen={isOpenModalActualizar} color='#fff'>
                            <Form className='programacionJobsCarteraModal'>
                                <div className="row">
                                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                                        <div className='d-flex align-items-center dias-festivos-modal-icon'>
                                            <i className='bx bx-md bxs-calendar-edit' ></i>
                                        </div>
                                        <div className='px-4'>
                                            <h4><strong>Job control</strong></h4>
                                            <small>Programa la ejecución del job</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-12 col-12">
                                        <SelectField
                                            id="frecuencia"
                                            label="Frecuencia:"
                                            name="plazoId"
                                            disabled={false}
                                            items={frecuenciaSelect}
                                            onChange={(event) => {
                                                setFieldValue("plazoId", event.value)
                                            }} />
                                    </div>
                                    {/* Semana */}
                                    {values.plazoId === 3 ?
                                        <div className="col col-12 d-flex">
                                            <div>
                                                <label className='text'>Días:</label>
                                                <div className=' d-flex'>
                                                    {weeklyDays.map((item, index) => (
                                                        <div key={index} className={`d-flex  dias-festivos-modal-icon-days ${item.active ? "active-day" : "inactive-day"}`}
                                                            onClick={() => toogleDate(item.name)}>
                                                            <p>{item.name.charAt(0)}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> : <></>}
                                    {/* Mes */}
                                    {values.plazoId === 4 ?
                                        <div className="col-lg-12 col-12">
                                            <SelectField
                                                id="frecuenciaMensual"
                                                label="Frecuencia mensual:"
                                                name="frecuenciaMensual"
                                                disabled={false}
                                                items={frecuenciaMesSelect}
                                                onChange={(event) => {
                                                    setFieldValue("frecuenciaMensual", event.value)
                                                    setFrecuenciaMes(event.label)
                                                }} />
                                        </div> : <></>}
                                    <div className="col-lg-12 col-12" style={{
                                        display: 'flex',
                                        flexFlow: 'column'
                                    }}>
                                        <label className='text'>Horario:</label>
                                        <Field type="time" name="horario" placeholder="12:00"
                                            step="60" className='programacionJobsCarteraModalSelect' />
                                        <ErrorMessage name="horario" component="div" className="error" />
                                    </div>
                                    <div className="col-lg-12 col-12">

                                        <SelectField id="aplicaRangoHorario"
                                            label="Repeticion:"
                                            disabled={false}
                                            name="aplicaRangoHorario"
                                            items={aplicaRangoHorario}
                                            onChange={(event) => {
                                                setFieldValue("aplicaRangoHorario", event.value)
                                            }} />
                                    </div>
                                    {values.aplicaRangoHorario === 1 ?
                                        <>
                                            <div className="col-lg-6 col-6" style={{
                                                display: 'flex',
                                                flexFlow: 'column'
                                            }}>
                                                <label className='text'>Hora Inicio:</label>
                                                <Field type="time" name="horaInicio" placeholder="12:00" value={values.horario}
                                                    step="60" className='programacionJobsCarteraModalSelect' disabled={true} />
                                            </div>
                                            <div className="col-lg-6 col-6" style={{
                                                display: 'flex',
                                                flexFlow: 'column'
                                            }}>
                                                <label className='text' >Hora Fin:</label>
                                                <Field type="time" name="horaFin" placeholder="12:00"
                                                    step="60" className='programacionJobsCarteraModalSelect' />

                                            </div>
                                            <ErrorMessage name="horaFin" component="div" className="error" />

                                            <div className="col-lg-12 col-12 mb-4" style={{
                                                display: 'flex',
                                                flexFlow: 'column'
                                            }}>
                                                <label className='text'>Lapso de repetición:</label>
                                                <Field type="number" name="tiempo" placeholder="minutos"
                                                    step="1" className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="tiempo" component="div" className="error" />
                                            </div>
                                        </> : <></>
                                    }
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setisOpenModalActualizar(false)
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    disabled={!(isValid)}
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    type="submit"
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )}
            </Formik >
        </>
    )
}
export default ActualizarJobs