import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';
import InicioDeValuacionService from '../../../../Services/Inventario/Valuacion/InicioDeValuacionService';
import { message } from '../../../../Utils/functions';

const ModalCostos = ({ isOpen, setIsOpen, item, handleGetAll, toast, valuesInit }) => {
    const refbtn = useRef();
    let initial = {
        valuacionRegistroGastos_AdicionalesId: item?.valuacionRegistroGastos_AdicionalesId ?? "",
        valuacionRegistroGastosId: item.valuacionRegistroGastosId ?? "",
        concepto: item?.concepto ?? "",
        subTotal: item?.subTotal ?? "",
        iva: item?.iva ?? "",
        total: item?.total ?? "",
        concluido: item?.concluido ?? false,
        fechaInicio: item?.fechaInicio ?? "",
        fechaFin: item?.fechaFin ?? "",
        ValuacionInicioId: item?.valuacionInicioId ?? ""
    };
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        subTotal: Yup.number()
            .required("El campo es requerido."),
        iva: Yup.number()
            .required("El campo es requerido."),
        total: Yup.number()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (initialValue.valuacionRegistroGastos_AdicionalesId !== "") {
            await InicioDeValuacionService.UpdCostosAdicionales(item)
                .then(res => {
                    setIsOpen(false);
                    // if (res.data.data.ban === 1) {
                    //     setIsOpen(false);
                    //     toast.success(message("¡correcto!", res.data.data.mensaje));
                    // } else {
                    //     toast.error(message("Error!", res.data.data.mensaje));
                    // }
                    handleGetAll(1);
                }).catch(e => {
                    setIsOpen(false);
                    // if (e.response !== undefined)
                    //     toast.error(message("Error!", e.response.data.message));
                    // else
                    //     toast.error(message("Error!", e.message));
                })
        }
        else {
            await InicioDeValuacionService.addCostosAdicionales(item)
                .then(res => {
                    setIsOpen(false);
                    // if (res.data.data.ban === 1) {
                    //     setIsOpen(false);
                    //     toast.success(message("¡correcto!", res.data.data.mensaje));
                    // } else {
                    //     toast.error(message("Error!", res.data.data.mensaje));
                    // }
                    handleGetAll(1);
                }).catch(e => {
                    setIsOpen(false);
                    if (e.response !== undefined)
                        toast.error(message("Error!", e.response.data.message));
                    else
                        toast.error(message("Error!", e.message));
                })
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                        <section className='py-2 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className=''><strong>Registrar costo</strong></span>
                            </div>
                            <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costo.</span>
                        </section>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="Subtotal" name="subTotal" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("subTotal", event.target.value)
                                        let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                        setFieldValue("total", total);
                                    }} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="IVA (%)" name="iva" type="number" holder="Escribe" onChange={(event) => {
                                        setFieldValue("iva", event.target.value);
                                        let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                        setFieldValue("total", total);
                                    }} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="Total" disabled name="total" type="number" holder="Escribe" />
                                </div>
                            </div>
                            <section className='my-3'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{initialValue.valuacionRegistroGastos_AdicionalesId == "" ? "Guardar" : "Editar"}</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalCostos