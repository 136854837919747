import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { setUsuario } from '../../Redux/Slice/Cobranza/UsuarioSeleccionadoSlice';
import { useDispatch } from 'react-redux';

const useCobradoresNivel2 = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const columns = [
      { field: 'nombrecobrador', headerName: 'Nombre', width: 210 },
      { field: 'dispobilidad', headerName: 'Disponibilidad', width: 150 },
      { field: 'fechainhabilitado', headerName: 'Fecha inhabilitado', width: 190 },
      { field: 'estatusRol', headerName: 'Estatus', width: 140 },
      { field: 'zonasasignadas', headerName: 'Zonas asignadas', width: 350 },
      { field: 'contratos', headerName: 'Contratos', width: 150 },
   ];


   const dataDisponibilidad= [
      { value: 0, label: 'Disponibilidad' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'No disponible' },
      { value: 3, label: 'Vacaciones' }
   ];

   const dataEstatus = [
      { value: 0, label: "Estatus"},
      { value: 1, label: "Activo"},
      { value: 2, label: "Inactivo"}
   ]

   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [dataZonas, setDataZonas] = useState([])
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10)
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [ actualizar, setActualizar ] = useState("");
   const [nivelDefault, setNivelDefault] = useState({value: 2, label: "Nivel 2"});
   const [currentPage, setCurrentPage] = useState(1);
   const [currentData, setCurrentData] = useState(data)
   const [filtroDisponibilidad, setFiltroDisponibilidad] = useState("")
   const [filtroEstatus, setFiltroEstatus] = useState("")
   const [filtroZonas, setFiltroZonas]=useState("")

   useEffect(() => {
      handleGetAllData();
      setActualizar("");
      setPage(1);
   }, [actualizar])

   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      let filteredData = data.filter(item => {
         const nameMatches = str === '' || (item.nombrecobrador && item.nombrecobrador.toLowerCase().includes(str.toLowerCase()));

         return (
            nameMatches &&
            (filtroDisponibilidad === "" || item.dispobilidad === filtroDisponibilidad || filtroDisponibilidad === "Disponibilidad") &&
            (filtroEstatus === "" || item.estatusRol === filtroEstatus || filtroEstatus=== "Estatus") &&
            (filtroZonas === "" || item.zonasasignadas === filtroZonas || filtroZonas === "Zonas")
         )
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)
  
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
      // if (currentPage > newTotalPages) {
      //   setCurrentPage(newTotalPages)
      // } else if (currentPage <= 0) {
      //   setCurrentPage(1)
      // }
  
   }, [currentPage, str, pageRows, data, filtroDisponibilidad,filtroEstatus, filtroZonas])


   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      //handleGetAllCobradores(event.selected + 1);
   };

   const handleGetAllData = async () => {
      let NewData = [];
      let newDataDetalles = [];
      let counterZonas = 1;
      let newDataZonas = [{ value: 0, label: "Zonas"}];
      await Cobranza.getEjecutivosFullDetail()
         .then(res => {
            console.log(res);
            if(res.data.callSucceded){
               if(res.data.callSucceded){
                  res.data.responseData.map(item => {
                     if(item.nivel_cobrador === "2"){
                        let count = 1;
                        item.contratos.map(contrat =>{
                           newDataDetalles.push({numero: count, estado: contrat.estado, municipio: contrat.municipio, colonia: contrat.colonia});
                           count++;
                        })

                        newDataZonas.push({value: counterZonas, label:item.zona})

                        NewData.push({
                           id: item.userid,
                           nombrecobrador: item.user_detail.names,
                           nivel: item.nivel_cobrador,
                           dispobilidad: item.disponible ? item.disponible : "No disponible",
                           fechainhabilitado: "0",
                           estatusRol: "Inactivo",
                           zonasasignadas: item.zona,
                           contratos: item.numContratos,
                           status: false,
                           telefono: item.numTelefono,
                           data:[
                              {zonacobranza: item.zona, constratosasignados: item.numContratos, zonaasignadatooltip: item.user_detail.names, detail: newDataDetalles}
                           ]
                        });
                        counterZonas++
                     }
                  })
               }
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      setData(NewData);
      setLoading(false);
      setDataZonas(newDataZonas)
   }

   const handleDetails = (item) =>{
      dispatch(setUsuario(
         {
            userid: item.id,
            nombrecobrador: item.nombrecobrador,
            usuariosistema: "0",
            nivel: item.nivel,
            telefono: item.telefono,
            status: true,
            estatusdesistema: "Disponible",
            data: item.data
         })
      );

      navigate(`/cobranza/cobradoresdetalle`, {
         state: {
            page: 'Detalle',
            title: "Editar usuario"
         },
         });
   }

   const handleNewCobrador = async () =>{
      setIsOpenModal(true);
      //   setLoading(true);
      //   await Cobranza.getPersonal()
      //       .then(res => {
      //           let NewData = [];
      //           NewData.push({ value: 0, label: "Seleccionar"})
      //           res.data.data?.map(item => {
      //               NewData.push({
      //                   value: item.idRole,
      //                   label: item.role
      //               })
      //           });
      //           setDataPersonal(NewData);
      //           setLoading(false);
      //       }).catch(e => {
      //           setLoading(false);
      //       })

   }

   return {
      columns,
      currentData,
      pageCount,
      dataDisponibilidad,
      dataEstatus,
      dataZonas,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      setActualizar,
      nivelDefault,
      setFiltroDisponibilidad,
      setFiltroEstatus,
      setFiltroZonas
   }
}

export default useCobradoresNivel2