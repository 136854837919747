import React from 'react'
import Select from 'react-select'
import { useEffect } from 'react';
import DataTable from '../../Components/datatable/DataTable';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import SubMenuParametrosCartera from '../../Components/SubMenuParametrosCartera';
// import AcendesCus from '../../Hooks/Parametros/Acendes';
// import TenenciaModal from './ParametrosModal';
import { pageRows } from '../../Utils/functions';
// import RowPages from '../../Components/RowPages';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';


const ParametrosCarteraHistorial = () => {


    return (
        <>
            <SubMenuParametrosCartera />
            {/* <p>Hola mundo</p> */}
            {/* <div className="tab-content" id="myTabContent">
                <Outlet/>
            </div>            */}
        </>
    )

}


export default ParametrosCarteraHistorial