import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select';
import { useSelector } from 'react-redux';
import DataTable from '../../../Components/datatable/DataTable';
import EditarCobradorModal from './EditarCobradorModal';
import ReasignacionCobradorModal from './ReasignacionCobradorModal';
import Modal from '../../../Components/Modal/Modal';

const CobradoresDetalle = () => {
   const {usuario} = useSelector((state) => state.UsuarioSeleccionadoSlice);
   const [btnEditConfirm, setBtnEditConfirm] = useState(1);
   const [isOpen, setIsOpen] = useState(false);

   let initial = {
      userid: usuario.userid,
      nombrecobrador: usuario.nombrecobrador,
      usuariosistema: usuario.usuariosistema,
      nivel: usuario.nivel,
      telefono: usuario.telefono,
      status: usuario.status,
      estatusdesistema: usuario.estatusdesistema,
      estado: "",
      municipio: ""
   }
   const [initialValue, setInitialValue] = useState(initial);
   const { state } = useLocation();
   const navigate = useNavigate();
   const [str, setStr] = useState("");
   const [OpenModalEditar, setIsOpenModalEditar] = useState(false);
   const [OpenModalReasignacion, setIsOpenModalReasignacion] = useState(false);

   const dataEstados = [
      {value: 0, label: "CDMX"},
      {value: 1, label: "Puebla"},
      {value: 2, label: "Aguscalientes"}
   ]

   const dataMunicipio = [
      {value: 0, label: "San Nicolás de los Garza"}
   ]

   const columns = [
      { field: 'zonacobranza', headerName: 'Zona de cobranza', width: 350 },
      { field: 'constratosasignados', headerName: 'Contratos asignados', width: 200 },
      { field: 'zonaasignadatooltip', headerName: 'Zona asignada', width: 150 },
   ]

   const columnsDetail = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'estado', headerName: 'Estado', width: 180 },
      { field: 'municipio', headerName: 'Municipio', width: 200 },
      { field: 'colonia', headerName: 'Colonia', width: 200 }
   ];

   const handleCheck = (checked, value) => {
      console.log(value)
   }

   const handleOption = (value) =>{
      if(value){

      }
      else{

      }
      setIsOpen(false);
      setBtnEditConfirm(1);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         //validationSchema={validate}
         onSubmit={(values) => {
         }}
      >{
               ({ isValid, values, handleChange, setFieldValue }) => (
                  <>
                     {/* <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal> */}
                     <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                           <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                           Regresar a Cobradores {state.page}
                     </header>
                     <Form className='mx-5' style={{width: "90rem"}}>
                     <section style={{ height: "14rem"}}>
                        <section className=' py-4'>
                           <div className='d-flex'>
                              <span className='fs-5 d-flex' >
                                 <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={"Cobrador Activo"}>
                                    <i className="icon-dark ri-account-circle-line" style={{fontSize: "30px", marginRight: "5px"}}></i>
                                 </fieldset>
                                 <span style={{fontSize: "30px", color: "#000", fontWeight: "bold"}}>
                                    <strong>{values.nombrecobrador}</strong>
                                 </span>
                              </span>
                              <div className='px-4'>
                                    <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModalEditar(true); }} >
                                       <i className='ri-pencil-fill' style={{fontSize:"22px", color: "#032fe7"}}></i>
                                    </fieldset>
                              </div>
                           </div>
                           <div className='mt-3' >
                              <span style={{fontSize: "17px", fontWeight: "bold"}}>Datos de cobrador:</span>
                           </div>
                        </section>
                        <div className="mb-3 row" style={{fontSize: "17px"}}>
                           <div className='col-2'>
                              <span style={{ fontWeight: "bold"}}><strong>Usuario</strong></span> <br />
                              <span >{values.usuariosistema}</span>
                           </div>
                           <div className='col-2'>
                              <span style={{ fontWeight: "bold"}}><strong>Nivel de cobranza</strong></span> <br />
                              <span >{values.nivel}</span>
                           </div>
                           <div className='col-2'>
                              <span style={{fontWeight: "bold"}}><strong>Número de teléfono</strong></span> <br />
                              <span >{values.telefono}</span>
                           </div>
                           <div className='col-2'>
                              <span style={{ fontWeight: "bold"}}><strong>Estatus de sistema</strong></span> <br />
                              <span style={{display: 'flex'}}><i className="ri-checkbox-circle-fill" style={{color: values.status===true ? "#3ed800" : "#b9b9b9"}}></i> {values.estatusdesistema}</span>
                           </div>
                           <div className='col-3'>
                              <span style={{ fontWeight: "bold"}}><strong>Historial Estatus de sistema</strong></span> <br />
                              <span style={{display: 'flex'}}><i className="icon-dark ri-file-search-line" style={{fontSize:"20px"}}></i> Ver historial</span>
                           </div>
                        </div>
                     </section>
                     <section>
                        <section className=' py-4'>
                           <div className='d-flex row'>
                              <span className='fs-5 col-6'><span style={{fontSize: "25px", color: "#000", fontWeight: "bold"}}><strong>Zona de cobranza</strong></span></span>
                              <section className='d-flex justify-content-end row col-6'>
                                 {
                                    btnEditConfirm == 1 &&(
                                       <button id="AT_btnReasignar" className='btn btn-outline col-4' type='button' onClick={() => { setBtnEditConfirm(2) }}>Editar</button>
                                    )
                                 }
                                 {  
                                    btnEditConfirm == 2 &&(
                                       <button id="AT_btnAgregar" className='btn mx-2 col-4' type="button" onClick={() => { setIsOpen(true);}}>Confirmar</button>
                                    )
                                 }
                              </section>
                           </div>
                        </section>
                        <div className="mb-3 row">
                           <div className="col-sm-3">
                                 <label className='col-12 mx-1'>
                                    Estado
                                    <Select name='estado' options={dataEstados} onChange={(e) => {
                                       console.log(e);
                                       setFieldValue("estado", e.value != 0 ? e.value : null);
                                       }}
                                       placeholder="Seleccionar" />
                                 </label>
                           </div>
                           <div className="col-sm-3">
                                 <label className='col-12 mx-1'>
                                    Municipio
                                    <Select name='municipio'options={dataMunicipio} onChange={(e) => {
                                       console.log(e);
                                       setFieldValue("municipio", e.value != 0 ? e.value : null);
                                       }}
                                       placeholder="Seleccionar" />
                                 </label>
                           </div>
                           <div className="col-sm-4 inner-addon right-addon mt-4">
                              <i className="glyphicon fas fa-search"></i>
                              <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                           </div>
                        </div>

                        {/* <ValidatePermission
                           isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                           > */}
                           {
                              btnEditConfirm == 2 &&(
                                 <div className='mx-4 table-responsive' >
                                    <DataTable
                                       column={columns}
                                       data={usuario.data}
                                       checkbox={true}
                                       handleCheck={handleCheck}
                                       masterDetail={true}
                                       columnDetail={columnsDetail}
                                    />
                                 </div>
                              )
                           }
                           {
                              btnEditConfirm == 1 &&(
                                 <div className='mx-4 table-responsive' >
                                    <DataTable
                                       column={columns}
                                       data={usuario.data}
                                       masterDetail={true}
                                       columnDetail={columnsDetail}
                                    />
                                 </div>
                              )
                           }
                           {/* </ValidatePermission> */}

                     </section>
                     </Form>
                     {
                        OpenModalEditar &&(
                        <>
                           <EditarCobradorModal
                              isOpen={OpenModalEditar}
                              setIsOpen={setIsOpenModalEditar}
                              item={{nombrecobrador: values?.nombrecobrador, usuariosistema: values?.usuariosistema,telefono: values?.telefono }}
                           />
                        </>)
                     }
                     {
                        OpenModalReasignacion &&(
                        <>
                           <ReasignacionCobradorModal
                              isOpen={OpenModalReasignacion}
                              setIsOpen={setIsOpenModalReasignacion}
                              item={{nombrecobrador: values?.nombrecobrador, usuariosistema: values?.usuariosistema,telefono: values?.telefono }}
                           />
                        </>)
                     }
                     <Modal isOpen={isOpen} color='#fff'>
                        <section className='my-2 mx-4' style={{ width: '35rem' }}>
                              <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Confirma los cambios</strong></span>
                              <p>¿Estas seguro de los cambios realizados al cobrador:<span style={{fontWeight:"bold"}}>{usuario.nombrecobrador}</span>?</p>
                              <section className='d-flex justify-content-end mt-5'>
                                    <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='button' onClick={() => { handleOption(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type="button" onClick={() => { handleOption(true)}}>Confirmar</button>
                              </section>
                        </section>
                     </Modal>
                  </>
               )
         }
      </Formik>
   )
}

export default CobradoresDetalle