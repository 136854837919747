import { Formik,  } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import DataTable from '../../../Components/datatable/DataTable';

const HistorialDeEstatusDeSistema = ({ isOpen, setIsOpen, item }) => {
   const [historial, setHistorial] = useState([]);

   useEffect(()=>{
      let newdata = [];
      item.diasinhabiles?.map(element => {
         let estatusSistema = ""
         let date = new Date(element.fecha);
         switch(element.enabled){
            case 0: estatusSistema = "No disponible"
                     break;
            case 1: estatusSistema = "Disponible"
                     break;
            case 2: estatusSistema = "Vacaciones"
                     break;
            case 3: estatusSistema = "Permiso"
                     break;
            default: estatusSistema = "No disponible"
                     break;
         }

         newdata.push(
            { 
               usuariosistema: item.nombrecobrador, 
               estatussistema: estatusSistema, 
               fechainicio: `${date.getDate()} - ${date.getMonth() + 1} - ${date.getFullYear()}`, 
               hora: `${date.getHours()}:${date.getMinutes()}`, 
               fechafin: "0"
            })
      });
      setHistorial(newdata);
   },[]);

   const columns = [
      { field: 'usuariosistema', headerName: 'Usuario', width: 200 },
      { field: 'estatussistema', headerName: 'Estatus de sistema', width: 200 },
      { field: 'fechainicio', headerName: 'Fecha inicio', width: 170 },
      { field: 'hora', headerName: 'Hora', width: 100 },
      { field: 'fechafin', headerName: 'Fecha fin', width: 170 },
    ]

   return (
   <Formik
      enableReinitialize={true}
   >{
      ({ isValid, values, setFieldValue }) => (
         <>
         <Modal isOpen={isOpen} color='#fff'>
            <section className='my-2 mx-4'>
               <span className='fs-5' style={{fontWeight:'bold', color:"#000"}}><strong>Historial de Estatus de sistema</strong></span>
            </section>
            <section className='my-2 mx-4' >
                  {/* <ValidatePermission
                  isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                  > */}
                  <div className='mx-4 table-responsive' style={{ width: '50rem', height: '25rem', overflowY: "scroll"}}>
                     <DataTable
                        column={columns}
                        data={historial}
                        placeholderSelectField = {"Seleccionar"}
                     />
                  </div>
                  {/* </ValidatePermission> */}
                  <section className='my-4 d-flex justify-content-end'>
                     <button id="AT_btnCancelar" className='btn col-2 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cerrar</button>
               </section>
            </section>
         </Modal>
         </>
      )
   }
   </Formik>
   )
}

export default HistorialDeEstatusDeSistema