import axios from '../../Utils/ApiService';

const JobsCarteraService = {
    getJobsCartera:(data)=> {        
        return axios.post(`/Cartera/GetAllJobs`,  data);
    },
    getAllProcesosCartera: () => {
        return axios.post('/cartera/GetProcesosJobxEtapaJobId', {etapaJobId: '34D70496-1A3B-47E0-8D66-99DD28E9169B'})
    },
    getAllFrecuenciaCartera: () => {
        return axios.get('/cartera/GetPlazo')
    },
    getHistorialJobCartera: (jobId) => {
        return axios.post('/cartera/GetHistorialJob', {jobId})
    },
    updateJobCartera: (data) => {
        return axios.post('/cartera/UpdateJob', data)
    },
    ejecutarJobCarteraManual: (jobId) => {
        return axios.post('/cartera/EjecutarJobManual', {jobId})
    }
  
}

export default JobsCarteraService