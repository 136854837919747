import React from 'react'
import '../Modal/Modal.css'

const Modal = ({children,isOpen,closeModal,color,width,height=null, overflow}) => {
  return (
    <>
    <article className={`modal ${isOpen && "is-open"}`} >
        <div className='modal-container' style={
           overflow
           ? {backgroundColor:`${color}`,width:`${width}px`, overflowY: 'auto' , maxHeight:"80%", overflowX: "hidden"}
           : {backgroundColor:`${color}`,width:`${width}px` }
        }
          >                        
            {children}
        </div>
    </article>
    </>
  )
}

export default Modal