import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { Form } from 'formik'
import Spinner from '../../../Components/Loadig'
import { useParams } from 'react-router-dom'
import Siniestros from '../../../Services/Siniestro/Siniestro'
import { FormatNumberMoney2 } from '../../../Utils/functions'

const ModalConfirm = ({ isOpen, setIsOpen, title, handleConfirm, procesoId = 1 }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({adeudo:false,monto:0});
    
    useEffect(() => {
        if (procesoId == 2)
            getPagoTramites();
    }, [])

    
    const getPagoTramites = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesGet(id)
            .then((response) => {                
                let totalAdeudos = 0;
                let totalPagos = 0;
                let adeudoTotal = 0;
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                response.data.adeudos.map((item, index) => {
                    item.total = item.subtotal + item.iva;                    
                    totalAdeudos += item.total;                    
                });                                

                response.data.pagos.map((item, index) => {                    
                    totalPagos += item.monto;                    
                });      
                adeudoTotal = totalAdeudos - totalPagos; 
                setItem({adeudo:adeudoTotal > 0?true:false,monto:`$${FormatNumberMoney2(adeudoTotal)}`})
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }
    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-between mx-4'>
                    <span className=''><strong>{item?.adeudo ? "¿Estas seguro que deseas continuar?" : title}</strong></span>
                </section>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>
                    <div className="mb-3">
                        {
                            !item?.adeudo ?
                                <label htmlFor="staticEmail" className="col-sm-12 col-form-label">¿Estas seguro que deseas guardar y continuar <br /> con {title}?</label>
                                : <label htmlFor="staticEmail" className="col-sm-12 col-form-label">Te recordamos que tienes un adeudo de <br /> {item?.monto}</label>
                        }
                    </div>
                    <section className='my-3 d-flex justify-content-end'>
                        <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                        <button id="AT_btnGuardar" className='btn mx-2' onClick={handleConfirm}> Confirmar</button>
                    </section>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ModalConfirm