import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import jsonData from '../../Utils/data.json';
import { FormatFecha } from '../../Utils/functions';
import { chekItem } from '../../Redux/Slice/quickActionsSlice';
import PagoUnidad from '../../Services/PagoUnidad/PagoUnidad';

const UnidadesPendientesHooks = () => {
  const dispatch = useDispatch();
  const columns = [
    { field: 'vin', headerName: 'VIN' },
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'fechaEntregaFormat', headerName: 'Fecha entrega' },
    { field: 'tipoCompra', headerName: 'Tipo de compra' },
    { field: 'razonSocial', headerName: 'Razón social' },
    { field: 'precioUnidad', headerName: 'Valor unidad' },
  ];

  const [pageRows, setPageRows] = useState(10);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    isOpen: false,
    type: 1,
    title: '',
    message: '',
    data: [],
    authorizationType: 2,
    Origin: 'Listado'
  });

  const [currentChecked, setCurrentChecked] = useState([]);
  const [total, setTotal] = useState(0);

  const [isOpenModalInfo, setisOpenModalInfo] = useState(false)
  const [currentPago, setCurrentPago] = useState()

  const [name, setName] = useState('')
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState('');

  // Configura la página inicial al cargar el componente
  useEffect(() => {
    setCurrentPage(0);
  }, []);

  // Maneja la paginación de datos
  // useEffect(() => {
  //   const indexOfLastItem = currentPage * pageRows;
  //   const indexOfFirstItem = indexOfLastItem - pageRows;
  //   const newData = data.slice(indexOfFirstItem, indexOfLastItem);
  //   setCurrentData(newData);
  //   setPageCount(Math.ceil(data.length / pageRows));
  // }, [currentPage, pageRows, data]);


  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.vin && item.vin.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches
        // &&
        // (proceso === '' || item.proceso === proceso) &&
        // (frecuencia === '' || item.frecuenciaFormateada === frecuencia)
      )
    })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, pageRows, data])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const handlechekedItem = (check, item, i) => {
    if (check) {
      setTotal((prevTotal) => prevTotal + item.precioUnidad); // Suma si está seleccionado
    } else {
      setTotal((prevTotal) => prevTotal - item.precioUnidad); // Resta si se deselecciona
    }
    // check ? dispatch(chekItem({ check, item: { ...item, ventaContado: true } })) : dispatch(chekItem({ check, item: null }));
    let table = document.querySelectorAll('.tbl-custome >tr');
    // setCurrentChecked(...currentChecked, item)
    for (let index = 0; index < table.length; index++) {
      const element = table[index];
    }
  }


  useEffect(() => {
    getAllData()
  }, [])



  const getAllData = async () => {
    setLoading(true)
    let frecuencias = await PagoUnidad.getAllReportePagoUnidad()
      .then(res => {
        let response = res.data.map(item => {

          const fechaFormat = FormatFecha(item.fechaEntrega)

          return {
            ...item,
            fechaEntregaFormat: fechaFormat
          }
        })
        setLoading(false)
        return response
      }).catch(e => {
        setLoading(false)
      })

    setData(frecuencias)
  }

  const getReporteExcel = async () => {
    // Your Excel export logic
    // Lógica para exportar el reporte en Excel
    setLoading(true);
    await PagoUnidad.exportarReportePagoUnidad()
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `UnidadesPendientesDePago-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  };


  const handleInfo = async (obj) => {
    setCurrentPago(obj)
    setisOpenModalInfo(true)
  }


  return {
    setPageRows,
    columns,
    currentData,
    loading,
    pageCount,
    currentPage,
    handlePageClick,
    handlechekedItem,
    isOpenModalInfo,
    setisOpenModalInfo,
    handleInfo,
    currentPago,
    total,
    setName,
    setCobradorSeleccionado,

    getReporteExcel
  };
};

export default UnidadesPendientesHooks;
