import React, { useRef, useState } from 'react';
import { Formik, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import RowPages from '../../../Components/RowPages';
import DataTable from "../../../Components/datatable/DataTable";
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermissionById } from '../../../Auth/ValidatePermission';
import SubMenuPagoUnidad from './SubMenuPagoUnidad';
import UnidadesHooks from '../../../Hooks/UnidadesPendientesPago/UnidadesPendientesHooks';
import { SelectField } from '../../../Components/SelectField';
import InfoPagoUnidadModal from '../../../Components/Modal/InfoPagoUnidadModal';
import BusquedaAvanzadaModal from '../../Cobranza/SupervisionDeCarga/BusquedaAvanzadaModal';

const UnidadesPendientes = () => {
  const refFormik = useRef();
  const [isOpenBusquedaAvanzada, setIsOpenBusquedaAvanzada] = useState(false);

  const {
    columns,
    currentData = [], // Initialize `currentData` as an empty array to avoid errors
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    handlechekedItem,
    loading,  // Destructure `loading` from the hook
    isOpenModalInfo,
    setisOpenModalInfo,
    handleInfo,
    currentPago,
    total,
    setName,
    setCobradorSeleccionado,
    setPageRows,

    getReporteExcel,
  } = UnidadesHooks();

  const tipoCompraSelect = [
    { value: 1, label: "Interno" },
    { value: 2, label: "Externo" },
  ]

  const handleDetailClick = (item) => {
    console.log(item)

  };
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246',
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a',
            },
          },
        }}
      />

      {isOpenModalInfo && (
        <InfoPagoUnidadModal
          data={currentPago} // Pass the `selectedData` object
          setIsOpen={setisOpenModalInfo}
          isOpen={isOpenModalInfo}
          toast={toast}
        />
      )}
      {
        isOpenBusquedaAvanzada && (
          <>
            <BusquedaAvanzadaModal
              isOpen={isOpenBusquedaAvanzada}
              setIsOpen={setIsOpenBusquedaAvanzada}
            // setAccionBuscar={setAccionBuscar}
            />
          </>)
      }
      <Formik
        innerRef={refFormik}
        initialValues={{
          buscar: "",
          cobradorSeleccionado: "",
          page: 1,
          rows: 10,
        }}
        onSubmit={(values) => {
          // Handle form submission logic here
        }}
      >
        {({ setFieldValue }) => (
          <>
            <SubMenuPagoUnidad />
            <section>
              <div className="pb-4">
                <section className="mx-4 my-4 d-flex flex-column">
                  <div className="col-12">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <strong className="mt-2">Unidades pendientes de pago</strong>
                        <p className="mt-4">
                          <strong>Visualiza los vehículos pendientes de pago del tipo de compra interno o externo</strong>
                        </p>
                      </div>
                      <div className="col-auto justify-content-end">
                        <button className='btn' onClick={getReporteExcel}>
                          Exportar Excel
                        </button>
                        <p className="mt-2" style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          {`Presupuesto provisional: ${total}`}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-4">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-3 mt-4 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <Field
                          type="text"
                          name="buscar"
                          className="form-control mb-4"
                          placeholder="Buscar"
                          onKeyUp={(e) => {
                            setName(e.target.value)
                            setFieldValue('buscar', e.target.value)
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-2 mt-4 me-1">
                      <SelectField
                          id="TipoCompraSeleccionado"
                          name="tipoCompraSeleccionado"
                          placeholder="Tipo de compra"
                          items={tipoCompraSelect}
                          onChange={(option) => {
                            setFieldValue('tipoCompraSelect', option.value);
                            setCobradorSeleccionado(option.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-4 mt-4">
                      <button className='btn btn-blue' onClick={() => { setIsOpenBusquedaAvanzada(true) }}  >Búsqueda Avanzada</button>
                      </div>
                    </div>
                  </div>
                  <RowPages setpageRows={setPageRows} />

                  <div className=" table-responsive">

                    {loading ? (
                      <p>Loading data...</p>
                    ) : currentData.length > 0 ? (
                      <DataTable
                        column={columns}

                        checkbox={true}
                        handleCheck={handlechekedItem}

                        data={currentData} // Use the `currentData` from the hook

                        paginate
                        pageCount={pageCount}
                        handlePageClick={handleDetails}
                        currentPage={currentPage - 1}

                        infoColumn={true}
                        handleInfo={handleInfo}
                      />
                    ) : (
                      <p>No hay datos disponibles.</p>
                    )}
                  </div>
                </section>
              </div>
            </section>
          </>
        )}
      </Formik>
    </>
  );
};

export default UnidadesPendientes;
