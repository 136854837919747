import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import ProcesosSidebar from '../Components/ProcesosSidebar';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch } from 'react-redux';
import { chekItem } from '../Redux/Slice/quickActionsSlice';
import { AuthExpireSesion } from '../Utils/functions';
import CobranzaSidebar from '../Components/CobranzaSidebar';
import { Module } from '../Auth/Authorization';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import NotFound from '../Pages/NotFound';
const LayoutMenuCobranza = () => {
  const dispatch = useDispatch();
  const auth = new AuthLogin();
  const usuario = auth.getUser()

  useEffect(() => {
    dispatch(chekItem({ check: false, item: null }))
  }, [])

  return (

    <>
      {
        (usuario && !AuthExpireSesion()) ?
          <>
            <Header />
            <CobranzaSidebar />
            <main id="main" className="main">
              {
                ValidatePermissionById(1, Module.Cobranza) ? <Outlet /> : <NotFound />
              }
            </main>
          </> 
          : <Navigate to={'/'} />
      }
    </>
  )
}

export default LayoutMenuCobranza