import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import Cobranza from '../../../Services/Cobranza/Cobranza';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

const ReasignarColoniasDeZonaModal = ({ isOpen, setIsOpen, item }) => {

   console.log(item);
   const refbtn = useRef();
   let initial = {
      areaactual: item?.item
   }
   const dispatch = useDispatch();
   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const [zonaSeleccionada, setzonaSeleccionada] = useState(0);
   const [idZonaSeleccionada, setIdZonaSeleccionada] = useState(0);

   

   const zonaDestino = item.colonias.map(x => {
      return {
         value: x.idrow,
         label: x.colonia
      };
   });

   const handleClickZonaDestino = (label, value) => {
      setzonaSeleccionada(label);
      setIdZonaSeleccionada(value);
  }

   //  const { 
   //      setNewArea,
   //      dataEstado,
   //      dataMunicipio,
   //      defaultValueMunicipio,
   //      handleGetMunucipios,
   //      handleChangeMunicipio,
   //      //handleCheck,
   //      columnsZonas,
   //      dataZonas
   //  } = useAreasCobranza();
   //  const validate = Yup.object({
   //    nuevaarea: Yup.string()
   //          .required("El campo es requerido.")
   //          .min("4", "Minimo 4 caracteres")
   //          .max("50", "Maximo 50 caracteres")
   //  });

   const handleAdd = async () => {
      setLoading(true);
      const dataZona = item.colonias.map(x => x.item);
      let data = {
          "verb": zonaSeleccionada,
          "id": idZonaSeleccionada,
          "field": item.nombreEstado,
          "value": item.nombreMunicipio,
          "flag": true,
          "anyObject": dataZona
      }
      await Cobranza.postCreateZonaItemBatch(data)
          .then(res => {
              setIsOpen(false);
              setLoading(false);
          }).catch(e => {
              setLoading(false);
              console.log(`ERROR -- ${e}`)
          })
  }

   const handleCheck = (checked, value) => {
      console.log(`${checked} - ${value.idrow} - ${value.municipio} - ${value.colonia}`);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         //validationSchema={validate}
         onSubmit={(values) => {
            handleAdd(values);
         }}
      >{
            ({ isValid, values, setFieldValue }) => (
               <>
                  <Modal isOpen={isOpen} color='#fff'>
                     <section className='my-2 mx-4' style={{ width: '45rem', height: '17rem' }}>
                        <Form>
                           <div className="row" style={{ marginTop: 20 }}>
                              <div className="col-sm-12">
                                 <span className='fs-4' style={{ fontWeight: 'bold', color: "#000" }}><strong>Reasignar colonias de zona: <b>{item.nombreEstado}: {item.nombreMunicipio
                                 }</b> </strong></span>
                              </div>
                           </div>
                           <div className="row mt-4" >
                              <p><b>1. Selecciona la zona destino donde se reasignaran las {item.colonias.length} colonias seleccionadas</b></p>
                           </div>
                           <div className='mb-3 row mt-4'>
                              <div className="col-sm-6">
                                 Zona destino
                                 <Select options={item.zonas} className='col-12' onChange={(event) => { handleClickZonaDestino(event.label, event.value); }} placeholder="Seleccionar" />
                              </div>
                              {/* <div className="col-sm-6">
                                 Colonias actuales
                                 <Select options={zonaDestino} className='col-12' onChange={(e) => { console.log(e) }} placeholder="Seleccionar" />
                              </div> */}
                           </div>
                           <section className='my-4 d-flex justify-content-end'>
                              <button id="AT_btnCancelar" className='btn btn-outline col-3 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                              {/* <button id="AT_btnGuardar" className='btn mx-2 col-3 mw-100' type='reset' onClick={() => { setIsOpen(false) }} >Continuar</button> */}
                              <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Continuar</button>
                           </section>
                        </Form>
                     </section>
                  </Modal>
                  {
                     loading &&
                     <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                  }
               </>
            )
         }
      </Formik>
   )
}

export default ReasignarColoniasDeZonaModal