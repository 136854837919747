import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import '../datatable/DataTable.css'
import { Fragment } from 'react';
import Select from 'react-select';


const DataTable = ({ id, iswidth, column, columnDetail, data, editable, comments, downtable, downloable, pageCount, deleteable, detailable, recover, addtable, handleEdit,
    handleAdd, handleDownload, handleDelete, handleDetail, handleRecover, handlePageClick, paginate = true, checkbox = false, handleCheck,
    currentPage, actions, handleAction, type = 0, handleSort, handleDocuments, isStatus, handleChange, testDriverMantener, testDriverLiberar, testDriverHistorial, handleTDrive,
    handleEndTDrive, handleRender, handleHistoryTDrive, tagCovert = false, autorizar, rechazar, handleAutorizaCita, autorizaCita, sinRenovar, masterDetail, labelAutorizar, labelRechazar, btnPagadoDetail, handlePagadoDetail,
    desagrupable, pagadoPR, reenviarAcendes, isStycky, actionColumns, deleteableColumn, commentColumn, registroColumn, handleRegistro, dataSelectField, funcSelectField, placeholderSelectField, handleHistorial, extrainfopromedio = false,
    infoColumn, handleInfo, desasignarColumn, handleDesasignar, moneyColumn, handleMoney,
}) => {
    const [columns, setColumns] = useState([]);
    const [columnsDetail, setColumnsDetail] = useState([]);
    const [rows, setRows] = useState([]);
    const [TotalPage, setTotalPage] = useState(null);
    const [short, setShort] = useState(false)

    useEffect(() => {
        setColumns(column);
        setTotalPage(pageCount);

        if (data === null)
            setRows([]);
        else {
            if (masterDetail) {
                setColumnsDetail(columnDetail);
                setRows(data.map(x => ({
                    ...x,
                    active: false
                })));
            }
            else
                setRows(data);

        }
    }, [data])

    let FieldsArray = ['status', 'estatus', 'interfaz', 'costo', 'tiempo', 'requerido', 'valor', 'valorAnterior', 'valorNuevo', 'contenido'];
    const handlegetPropertyValue = (object, field, handler) => {
        let value = object[field];
        if (FieldsArray.includes(field) && typeof (value) === 'boolean') {
            value = value ? 'Activo' : 'Inactivo'
        }
        if (FieldsArray.includes(field)) {
            value = value?.length > 30 ? `${value.substr(0, 40)}...` : value;
        }
        if (field === "historialdesistema")
            value = <u onClick={() => { handleHistorial(object) }}><i className="icon-dark ri-file-list-2-line" style={{ fontSize: "15px" }}></i>Ver historial</u>
        if (field === "")
            value = <u onClick={() => { handleDocuments(object) }}>Ver</u>;//<button onClick={() => { handleDocuments(object) }} className='btn-tbl mx-1 btnAdd'><i className="ri-folder-3-fill"></i></button>
        if (field === "nombreDocumento")
            value = <u onClick={() => { handleDocuments(object) }}>{value}</u>;
        if (field === "verEvidencia") {
            value = <span><fieldset className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content' }} onClick={() => { handleDocuments(object) }}><i className="fa fa-solid fa-eye icon"> ver</i></fieldset></span>;
        }
        if (field === "acciones") {
            if (object.subido) {
                value = <span><fieldset className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content', color: 'black' }} onClick={() => { handleAction(object, object.subido) }}><i className="icon fa fa-solid fa-eye"></i> ver</fieldset></span>;
            } else {
                value = <span><fieldset className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content', color: 'black' }} onClick={() => { handleAction(object, object.subido) }}><i className="icon fa fa-file"></i> Subir Documentos</fieldset></span>;
            }
        }
        if (field === "descarga") {
            value = <span><fieldset className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content' }} onClick={() => { handleDownload(object) }}><i className="icon ri-download-line">{value}</i></fieldset></span>;
        }
        if (field === "comentariosRU" || field === "comentariosDictamen") {
            value = <span><fieldset data-toggle="tooltip" data-placement="top" title={`${value ?? ''}`} className='btn-tbl none-color mx-1 px-2' style={{ width: 'max-content' }}><i className="bx bxs-message icon"></i></fieldset></span>;
        }
        if (field === "gestoria") {
            // value == "" || value==null ? value = "Sin gestoria" : value = "Con gestoria";
            // value = <u onClick={() => { handler("hola gestoria") }}>{value}</u>;
        }
        if (field === "estatus") {
            // value = value == "" || value == null ? 'Sin estatus' : 'Con estatus'
            // value = <u onClick={() => { handler("hola estatus") }}>{value}</u>;
        }
        if (field === "condicionPoliza") {
            if (object._CondicionPoliza)
                return <span className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: '#47A066' }}></i>{value}</span>
            else
                return <span className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: 'gray' }}></i>{value}</span>
        }
        if (field === "estatusRol") {
            if (object.status)
                return <span style={object?.status ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: '#47A066' }}></i>{value}</span>
            else
                return <span style={!object?.status ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: 'gray' }}></i>{value}</span>
        }
        if (field === "estatusdesistema") {
            if (object.statussistem)
                return <span style={object?.statussistem ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: '#47A066' }}></i>{value}</span>
            else
                return <span style={!object?.statussistem ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: 'red' }}></i>{value}</span>
        }
        if (field === "estatulaboral") {
            if (object.statuslab)
                return <span style={object?.statuslab ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: 'blue' }}></i>{value}</span>
            else
                return <span style={!object?.statuslab ? { borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` } : ''} className='d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: 'gray' }}></i>{value}</span>
        }
        if (field === "direcciontooltip") {
            return <span className='d-flex'>
                <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={value}>
                    <i className='icon-blue ri-information-fill' style={{ fontSize: "30px" }}></i>
                </fieldset>
            </span>
        }
        if (field === "zonaasignadatooltip") {
            return <span className='d-flex'>
                <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={value}>
                    <i className='icon-black ri-account-circle-line' style={{ fontSize: "30px" }}></i>
                </fieldset>
            </span>
        }
        if (field === "promesa_status") {
            return <span style={{ borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` }} className='d-flex align-items-center justify-content-center'>{value}</span>
        }
        if (field === "contrato_status") {
            return <span style={{ borderRadius: '5px', backgroundColor: `${object?.colorFondo}`, color: `${object?.colorTexto}` }} className='d-flex align-items-center justify-content-center'>{value}</span>
        }
        if (field === "comentariosTooltip"){
            return <span className='d-flex'>
                        <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={value}>
                            <i className='icon-blue ri-message-2-fill' style={{fontSize:"30px"}}></i>
                        </fieldset>
                    </span>
        }
        if (field === "extraInfoTooltip"){
            if(value != null && value != '')
                {
                    return <span className='d-flex'>
                        <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={value}>
                            <i className='icon-blue ri-error-warning-fill' style={{fontSize:"15px"}}></i>
                        </fieldset>
                    </span>
                }
                else{
                    return <span></span>
                }
        }
        return value

    }
    const gravedad = (value) => {
        if (value == 1) {
            return <span className='badge bg-danger'>!!!</span>
        }
        if (value == 2) {
            return <span className='badge bg-warning'>!!</span>
        }
        if (value == 3) {
            return <span className='badge bg-secondary'>!</span>
        }
    }
    const handleDetalShow = (index) => {
        setRows((current) =>
            current?.map((data, i) =>
                i == index
                    ? {
                        ...data,
                        active: !data.active
                    }
                    : data
            )
        );
    }
    return (
        <>
            <table id={id} className="table table-striped table-hover" style={{ width: iswidth ? '100vw' : ``, overflowX: 'auto' }}>
                <thead id="headerId">
                    <tr className='custom-table'>
                        {
                            checkbox &&
                            <th scope="col" style={{ width: `35px` }} ></th>
                        }
                        {
                            masterDetail &&
                            <th scope="col" style={{ width: `35px` }} ></th>
                        }
                        {
                            columns?.map((item, ind) => {
                                // if (!item.hasOwnProperty('isActive') || item?.isActive) {
                                return <th key={ind} scope="col" style={{ width: `${item.width}px` }}>{item.headerName} {item.short ? <i className="bx bx-sort" onClick={() => { setShort(!short); handleSort(short ? "asc" : "desc") }}></i> : null}</th>
                                // }
                            })
                        }
                        {
                            actionColumns &&
                            <th scope="col" style={{ width: `35px` }} >Acciones</th>
                        }
                        {
                            commentColumn &&
                            <th scope="col" style={{ width: `35px` }} >Comentarios</th>
                        }
                        {
                            infoColumn &&
                            <th scope="col" style={{ width: `35px` }} >Info</th>
                        }
                        {/* {

                            downloable &&
                            <th scope="col" style={{ width: `35px` }} >Recibo</th>
                        } */}
                    </tr>
                </thead>
                <tbody className='tbl-custome'>
                    {
                        rows.length > 0 ?
                            rows?.map((rowObj, index) => {
                                // if (handleRender !== undefined) rowObj = handleRender() ?? rowObj;
                                if (handleRender !== undefined) handleRender(rowObj)
                                return <Fragment key={index}>
                                    <tr key={index} className=''>
                                        {
                                            checkbox
                                                ? <td><input id={rowObj.logUsuarioId} className='form-check-input chk' type="checkbox" defaultChecked={rowObj.statusCheckBox ? rowObj.statusCheckBox : false} onChange={(e) => { handleCheck(e.target.checked, rowObj, index) }} /></td>
                                                : null
                                        }
                                        {
                                            masterDetail ? <td>
                                                <i className={`${rowObj?.active ? "bx bx-minus" : "ri-add-line"} icon`} onClick={() => { handleDetalShow(index) }}></i>
                                            </td> : null
                                        }
                                        {
                                            columns?.map((column, index) => {
                                                // if (!column.hasOwnProperty('isActive') || column?.isActive) {
                                                if (column.field === "selectField") {
                                                    return <td key={index}>
                                                        <Select options={dataSelectField} className='col-12 mx-1' onChange={(e) => { funcSelectField({ dataRow: rowObj, valueOption: e.value }) }} placeholder={placeholderSelectField} />
                                                    </td>
                                                }
                                                else {
                                                    return <td key={index}><span className=''
                                                        style={
                                                            { borderRadius: '5px', padding: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' || column.field === 'tipoDictamen' ? '3px 10px 3px 10px' : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'tipoDictamen' ? `${rowObj.colorTexto}` : 'none', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' || column.field === 'tipoDictamen' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'tipoDictamen' ? `${rowObj.colorTexto}` : 'none' }}>{column.field == 'tipoCorreo' && gravedad(rowObj.prioridadId)} {handlegetPropertyValue(rowObj, column.field)}</span></td>
                                                }
                                                // }
                                            })
                                        }
                                        {
                                            tagCovert &&
                                            <td style={{ width: '60px' }}>
                                                {
                                                    rowObj?.convertido_inventario &&
                                                    <span className='icon d-flex align-items-center'><i className="icon status-icon mx-2" style={{ backgroundColor: '#004C97' }}></i>Convertido</span>
                                                }

                                            </td>
                                        }
                                        {
                                            actionColumns &&
                                            <td className={``}>
                                                <div className='d-flex'>
                                                    {
                                                        deleteableColumn ? <fieldset className='icon-datatable-action' onClick={() => { handleDelete(rowObj) }}><i className='bx bxs-trash' style={{ fontSize: '1.5rem' }}></i></fieldset> : null
                                                    }
                                                    {
                                                        registroColumn? <fieldset className='icon-datatable-action' onClick={() => { handleRegistro(rowObj) }}><i className='bx bxs-book-open' style={{ fontSize: '1.5rem' }} ></i></fieldset> : null
                                                    }
                                                    {
                                                        moneyColumn  && rowObj.visibleColumnMoney? <fieldset className='icon-datatable-action' onClick={() => { handleMoney(rowObj) }}><i className='bx bxs-dollar-circle' style={{ fontSize: '1.5rem' }} ></i></fieldset> : null
                                                    }
                                                    {
                                                        desasignarColumn && rowObj.visibleColumnDesasignar? <fieldset className='icon-datatable-action' onClick={() => { handleDesasignar(rowObj) }}><i className='bx bxs-minus-circle' style={{ fontSize: '1.5rem' }} ></i></fieldset> : null
                                                    }
                                                </div>
                                            </td>
                                        }

                                        {
                                            commentColumn &&
                                            <td className={``}>
                                                <div className='d-flex'>
                                                    <div className='tooltip-container '>
                                                        <span className='icon-datatable-action'><i className='bx bxs-comment' style={{ fontSize: '1.5rem' }}></i></span>
                                                        <div className="tooltip-text">{`${rowObj.message}`}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {
                                            infoColumn &&
                                            <td className={``}>
                                                <div className='d-flex'>
                                                    <div className='tooltip-container '>
                                                        <span className='icon-datatable-action' onClick={() => { handleInfo(rowObj) }}><i className="ri-file-text-line" style={{ fontSize: '1.5rem' }}></i></span>
                                                        {/* <div className="tooltip-text">{`${rowObj.message}`}</div> */}
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {
                                            actions &&
                                            <td style={{ width: '40px' }} className='pe-0'>
                                                {/* style={{position: 'absolute',height:'37px'}} */}
                                                <div className='d-flex'>

                                                    <div className="dropdown">
                                                        <button className="btn-tbl mx-1 btnActions dropdown-toggle" type="button" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                                                            <i className="bx bx-dots-horizontal-rounded"></i>
                                                        </button>
                                                        <ul className='dropdown-content-tbl pt-2 dropdown-menu'>
                                                            <li><a className="px-1" style={{ fontSize: '12px', color: 'gray' }}>acciones</a></li>
                                                            {
                                                                type === 1 &&
                                                                <li onClick={() => { handleAction(rowObj) }}><a className="dropdown-item"> <span className='bx bxs-folder'></span> Asignar Folder</a></li>
                                                            }
                                                            {
                                                                type === 2 && rowObj.usuarioUtilitarioId === null &&
                                                                <li onClick={() => { handleAction(rowObj, 1) }}><a className="dropdown-item"> <span className='bx bxs-car'></span> Vehículo utilitario</a></li>
                                                            }
                                                            {
                                                                type === 2 && rowObj.usuarioUtilitarioId &&
                                                                <li onClick={() => { handleAction(rowObj, 4) }}><a className="dropdown-item"> <span className='ri-user-3-fill'></span> Ver Detalle</a></li>
                                                            }
                                                            {
                                                                type === 2 && rowObj.usuarioUtilitarioId &&
                                                                <li onClick={() => { handleAction(rowObj, 2) }}><a className="dropdown-item"> <span className='bi bi-arrow-counterclockwise'></span> Historial utilitario</a></li>
                                                            }
                                                            {
                                                                type === 2 && rowObj.usuarioUtilitarioId &&
                                                                <li onClick={() => { handleAction(rowObj, 3) }}><a className="dropdown-item"> <span className='bx bxs-trash-alt'></span> Desasignar</a></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        }

                                        <td className={`${isStycky ? "stycky" : "pe-0"}`}>
                                            {/* style={{position: 'absolute',height:'37px'}} */}
                                            <div className='d-flex'>
                                                {
                                                    isStatus ? <div className="form-check form-switch mx-1">
                                                        <input className="form-check-input" type="checkbox" checked={rowObj.status} onChange={(e) => { handleChange(e.target.checked, rowObj) }} />
                                                    </div> : null
                                                }
                                                {
                                                    downtable ? <button className='btn-tbl mx-1 none-color mx-1' data-toggle="tooltip" data-placement="top" title={`${labelAutorizar ?? ""}`}><i className="ri-download-line icon" onClick={() => { handleDownload(rowObj) }}></i></button> : null
                                                }
                                                {
                                                    downloable && rowObj.descargable === 1 ? <fieldset className='btn-tbl mx-1 none-color mx-1 px-2'><i className="ri-download-line icon" onClick={() => { handleDownload(rowObj) }}></i></fieldset> : null
                                                }
                                                {
                                                    addtable ? <button className='btn-tbl mx-1 btnAdd'><i className="bx bx-list-plus" onClick={() => { handleAdd(rowObj) }}></i></button> : null
                                                }
                                                {
                                                    editable ? <button className='btn-tbl mx-1 btnEdit' onClick={() => { handleEdit(rowObj) }}><i className="bx bx-pencil"></i></button> : null
                                                }
                                                {
                                                    deleteable ? <fieldset className='btn-tbl mx-1 btnDelete' style={{ paddingLeft: '6px', paddingRight: '6px' }} onClick={() => { handleDelete(rowObj) }}><i className="bx bxs-trash-alt"></i></fieldset> : null
                                                }
                                                {
                                                    comments ? <fieldset className='btn btn-tbl' style={{ paddingLeft: '11px', paddingRight: '11px' }} data-bs-toggle="tooltip" data-bs-placement="top" title={`${rowObj.comentarios}`} ><i className="fa fa-comment-alt"></i> </fieldset> : null
                                                }
                                                {
                                                    detailable ? <button className='btn-tbl mx-1 btnDetail' onClick={() => { handleDetail(rowObj) }}><i className="bx bxs-chevron-right"></i></button> : null
                                                }
                                                {
                                                    recover ? <button className='btn-tbl mx-1 btnDetail' onClick={() => { handleRecover(rowObj) }}><i className="bi bi-reply-fill"></i></button> : null
                                                }
                                                {
                                                    testDriverMantener ? <button className={`btn-tbl-circle mx-1 ${rowObj.mantener ? "active-color" : "none-color"} `} data-toggle="tooltip" data-placement="top" title={`${rowObj.mantener ? "Dejar de mantener" : "Mantener prueba"}`} onClick={() => { handleTDrive(rowObj) }}><i className={`bi bi-lightbulb ico ${rowObj.mantener ? "icon-white" : "icon"}`}></i></button> : null
                                                }
                                                {
                                                    testDriverLiberar ? <button className={`btn-tbl-circle mx-1 none-color`} data-toggle="tooltip" data-placement="top" title="Concluir prueba de manejo" onClick={() => { handleEndTDrive(rowObj) }}><i className={`ri-check-fill ico icon`}></i></button> : null
                                                }
                                                {
                                                    testDriverHistorial ? <button className={`btn-tbl-circle mx-1 none-color`} data-toggle="tooltip" data-placement="top" title="Ver historial de estatus" onClick={() => { handleHistoryTDrive(rowObj) }}><i className={`ri-file-list-line ico icon`}></i></button> : null
                                                }
                                                {
                                                    autorizar ? <button className={`btn-tbl-circle-st mx-1`} data-toggle="tooltip" data-placement="top" title={`${labelAutorizar ?? "Autorizar"}`} onClick={() => { handleAction(rowObj, 1) }}><i className={`ri-check-fill ico`}></i></button> : null
                                                }
                                                {
                                                    rechazar ? <button className={`btn-tbl-circle-st mx-1`} data-toggle="tooltip" data-placement="top" title={`${labelRechazar ?? "Rechazar"}`} onClick={() => { handleAction(rowObj, 0) }}><i className={`ri-close-line ico`}></i></button> : null
                                                }
                                                {
                                                    autorizaCita ? <button className={`btn-transparent mx-1`} data-toggle="tooltip" data-placement="top" title="Autorizar Cita" onClick={() => { handleAutorizaCita(rowObj) }}><i className={`ri-arrow-right-s-line ico`}></i></button> : null
                                                }
                                                {
                                                    rowObj?.sinRenovar &&
                                                        sinRenovar ? <button className={`btn-tbl mx-1 mx-1`} data-toggle="tooltip" data-placement="top" title="Sin Renovar" onClick={() => { handleAction(rowObj) }}><i className={`bx bxs-shield-x ico `}></i></button> : null
                                                }
                                                {
                                                    rowObj?.folioGrupo !== '-' &&
                                                        desagrupable ? <button className={`btn-tbl mx-1 mx-1`} data-toggle="tooltip" data-placement="top" title="Desagrupar" onClick={() => { handleAction(rowObj, 0) }}><i className={`fa-regular fa fa-object-ungroup ico `}></i></button> : null
                                                }
                                                {
                                                    pagadoPR ? <button className={`btn-tbl mx-1 mx-1`} data-toggle="tooltip" data-placement="top" title="Pagado" onClick={() => { handleAction(rowObj, 1) }}><i className={`bx bx-dollar-circle ico `}></i></button> : null
                                                }
                                                {
                                                    reenviarAcendes ? <button className={`btn-tbl mx-1 mx-1`} data-toggle="tooltip" data-placement="top" title="Enviar" onClick={() => { handleAction(rowObj) }}><i className={`ri-send-plane-fill ico `}></i></button> : null
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        (masterDetail && rowObj?.active) &&
                                        <tr key={index + 1}>
                                            <td colSpan={"12"} style={{ paddingLeft: '4rem' }}>
                                                <table className="table table-striped table-hover" style={{ width: iswidth ? '100vw' : ``, overflowX: 'auto' }}>
                                                    <thead>
                                                        <tr className='custome-table'>
                                                            {
                                                                columnsDetail?.map((item, index) => {
                                                                    return <th scope="col" style={{ width: `${item.width}px` }} key={index}>{item.headerName} {item.short ? <i className="bx bx-sort" onClick={() => { setShort(!short); handleSort(short ? "asc" : "desc") }}></i> : null}</th>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbl-custome'>
                                                        {
                                                            rowObj?.detail?.length > 0 ?
                                                                rowObj?.detail.map((rowDetail, indexDetail) => {
                                                                    return <Fragment key={indexDetail}>
                                                                        <tr>
                                                                            {
                                                                                columnsDetail?.map((column, i) => {
                                                                                    return <td key={i}><span className=''
                                                                                        style={
                                                                                            { borderRadius: '5px', padding: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? '3px 10px 3px 10px' : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none' }}>{column.field == 'tipoCorreo' && gravedad(rowObj.prioridadId)} {handlegetPropertyValue(rowDetail, column.field)}</span></td>
                                                                                })
                                                                            }

                                                                            <td className='ps-0'>
                                                                                <div className='d-flex'>
                                                                                    {
                                                                                        btnPagadoDetail ? <button className='btn-tbl mx-1 btnEdit' onClick={() => { handlePagadoDetail({ ...rowDetail, folio: rowObj.folio }) }}>pagado</button> : null
                                                                                    }

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            extrainfopromedio ?
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td colSpan={3}>
                                                                                        <span style={{ fontWeight: "bold", fontSize: "16px", color: "#000" }}>Promedia días: 0 días</span>
                                                                                    </td>
                                                                                    <td colSpan={3}>
                                                                                        <span style={{ fontWeight: "bold", fontSize: "16px", color: "#000" }}>Total: $ 0</span>
                                                                                    </td>
                                                                                </tr>
                                                                                : null

                                                                        }
                                                                    </Fragment>
                                                                })
                                                                : <tr><td colSpan={(columnsDetail?.length + 1)} className="text-center">No se encontrarón registros</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>

                                        </tr>
                                    }
                                </Fragment>

                            }) : <tr><td colSpan={(columns?.length + 1)} className="text-center">No se encontrarón registros</td></tr>

                    }
                </tbody>
            </table>
            {
                paginate && TotalPage > 0 &&
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={TotalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                />
            }

        </>
    )
}

export default DataTable