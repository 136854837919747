import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions';
import { useParams } from 'react-router-dom';
import Siniestros from '../../Services/Siniestro/Siniestro';



const AgregarPagoModal = ({ modalMessage, getPagoTramites, setModalMessage, siniestroPagosData, idAdeudo, setIsOpenModalPago, isOpenModalPago }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const { id, generalid, siniestroid } = useParams();
    const dispatch = useDispatch();

    const inicial = {
        siniestroPagoTramitesPagoId: null,
        siniestroId: siniestroPagosData?.siniestroId ?? "",
        usuarioEjecutivo: null,
        nota: null,
        metodoPagoId: null,
        monto: null,
        recibo: null,
        tipoPago: null

    };

    const [initialValue, setInitialValue] = useState(inicial);
    const [dataTipoPago, setDataTipoPago] = useState([]);    

    useEffect(() => {
        getTipoPago();
    }, []);

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        monto: Yup.number()
            .positive('El valor debe ser positivo')
            .typeError('Debe ser un número')
            .test('decimal-places', 'Máximo 2 decimales', value => {
                if (value) {
                    const decimalPlaces = value.toString().split('.')[1];
                    return !decimalPlaces || decimalPlaces.length <= 2;
                }
                return true;
            })
            .required('Campo requerido')
    });



    /////////////////
    /* CATALOGOS */
    const getTipoPago = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.TipoPago')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.TipoPagoId, label: item.TipoPago })
                });
                setDataTipoPago(items);
            })
            .catch(err => {
                console.error(err);
            });
    };
    /* TERMINA CATALOGOS */

    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.subtotal = 0
        initialValue.iva = 0

        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const handlerGuardar = (values) => {
        values.siniestroPagoTramitesId = siniestroPagosData.siniestroPagoTramitesId;
        Siniestros.siniestroPagoTramitesPagoCrear(values).then(resp => {
            console.log(resp)
            toast.success(message("¡Operación exitosa!", "Pago registrado correctamente"));
            getPagoTramites();
            handleRegresar();
        }).catch(error => {
            toast.error(message("¡Operación fallida!", "Error al agregar un pago"));
            console.log(error)
        })
    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    nota: true,
                    monto: true
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <Modal isOpen={isOpenModalPago} color='#fff'>
                        <div>
                            {/* <Modal isOpen={isOpenModalAdeudo} color='transparent'><Spinner message={""} /></Modal> */}
                            <section className='d-flex justify-content-end'>
                                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                    onClick={() => setIsOpenModalPago(false)}><i className="ri-close-fill"></i></button>
                            </section>
                            <div className='justify-content-start p-2 align-items-center'>
                                <div className="row">
                                    <div className="col col-12">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-2">
                                    <div className="col col-12">
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">
                                                A continuación registra el pago correspondiente del adeugo: <strong>{siniestroPagosData.totalAdeudos}</strong>
                                            </small>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-lg-6 col-12">
                                                <TextField
                                                    id="nota"
                                                    disabled={false}
                                                    label="Nota"
                                                    name="nota"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        setFieldValue("nota", event.target.value);
                                                    }} />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <SelectField id="metodoPagoId"
                                                    label="Método de pago"
                                                    disabled={false}
                                                    name="metodoPagoId"
                                                    items={dataTipoPago}
                                                    onChange={(event) => {
                                                        setFieldValue("metodoPagoId", event.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mt-2">

                                            <div className="col-lg-6 col-12">
                                                <TextField
                                                    id="monto"
                                                    label="Monto($)"
                                                    name="monto"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        console.log(event.target.value);
                                                        setFieldValue("monto", event.target.value);

                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline d-flex justify-content-center align-items-center'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={()=>{setIsOpenModalPago(false)}}>
                                                Cancelar
                                            </button>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                id="AT_BtnCloseModal"
                                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                                disabled={!isValid}
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => {
                                                    handlerGuardar(values);
                                                    resetForm();
                                                }}
                                            >
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Modal>

                )}
            </Formik>
        </>
    );
}

export default AgregarPagoModal;
