import React from 'react'
import { useState } from 'react';
import Modal from '../Modal/Modal';
import Spinner from '../Loadig';


const ConfiguracionDiasHabiles = ({ modalMessage, setModalMessage, setSelectedDates, selectedDates, year }) => { 

    let initialArray = [
        { active: false, name: 'D' },
        { active: false, name: 'L' },
        { active: false, name: 'Ma' },
        { active: false, name: 'Mi' },
        { active: false, name: 'J' },
        { active: false, name: 'V' },
        { active: false, name: 'S' },
    ]
    const [loading, setLoading] = useState(false);
    const [weeklyDays, setWeeklyDays] = useState(initialArray)

    //funcion foreach que vaya por cada 
    function getAllWeeklyDayInYear(year) {
        let alldays = []
        weeklyDays.map((data, index) => {
            if(!data.active) return null
            const days = [];
            const date = new Date(year, 0, 1); // Empieza el 1 de enero del año
    
            // Recorrer todos los días del año
            while (date.getFullYear() === year) {
                if (date.getDay() === index) { // 1 es Lunes (0 es Domingo)
                    alldays.push(new Date(date)); // Agregar el lunes al arreglo
                }
                date.setDate(date.getDate() + 1); // Avanza al siguiente día
            }
            return days;
        })
        setSelectedDates(prevData => [...prevData, ...alldays])
        setModalMessage({ isOpen: false, title: '', message: '' })
    }

    const toogleDate = (name) => {
        setWeeklyDays(prevItems =>
            prevItems.map(item =>
                item.name === name
                    ? { ...item, active: !item.active }
                    : item
            )
        );
    }

    const handleRechazar = () => {
        setModalMessage({ isOpen: false, type: 4, title: '', message: '' })
    }

    return (
        <div>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                        <div className='d-flex align-items-center dias-festivos-modal-icon'>
                            <i className='bx bx-md bxs-calendar-edit' ></i>
                        </div>
                        <div className='px-4'>
                            <h4><strong>{modalMessage.title}</strong></h4>
                            <small>Programa los dias de descanso</small>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <div className="row">
                            <p>
                                Fines de semana / Días de descanso
                            </p>
                        </div>
                    </div>


                    <div className="col col-12 d-flex">
                        {weeklyDays.map(item => (
                            <div className={`d-flex  dias-festivos-modal-icon-days ${item.active ? "active-day" : "inactive-day"}`}
                                onClick={() => toogleDate(item.name)}>
                                <p>{item.name.charAt(0)}</p>
                            </div>
                        ))}
                    </div>


                    <div className="col col-12">
                        <div className="row">
                            <p>
                                <small className='dias-festivos-modal-smalltext'>Los cambios realizados en la configuración afectarán en todo el sistema.</small>
                            </p>
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal"
                                className='btn btn-outline d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => { handleRechazar() }}>
                                Cancelar</button>
                        </div>
                        <div className="col-auto">
                            <button id="AT_BtnCloseModal"
                                className='btn btn-blue d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => { getAllWeeklyDayInYear(year) }}>
                                Confirmar</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ConfiguracionDiasHabiles