import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PagoUnidad from '../../Services/PagoUnidad/PagoUnidad';
import { FormatFecha } from '../../Utils/functions'

const SeguimientoPagoUnidadesHook = () => {

  const navigate = useNavigate();
  //Selects
  const [tipoCompra, setTipoCompra] = useState('');
  const [estatus, setEstatus] = useState('');
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([])

  const [pageRows, setPageRows] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpenModalRegistrar, setIsOpenModalRegistrar] = useState(false);

  const [numeroVinsXFolio, setNumeroVinsXFolio] = useState(0)

  //Cambiar columnas
  const columns = [
    { field: 'id', headerName: 'Folio' },
    { field: 'tipoCompra', headerName: 'Tipo de compra' },
    { field: 'totalVins', headerName: 'Vehiculos' },
    { field: 'presupuesto', headerName: 'Presupuesto' },
    { field: 'MontoPagado', headerName: 'Monto Pagado' },
    { field: 'estatus', headerName: 'Estatus' },
    { field: 'fechaAltaFormat', headerName: 'Fecha de creación' },
  ];

  const tipoCompraSelect = [
    { value: "Inventario", label: "Inventario" },
    { value: "Externo", label: "Externo" },
  ]

  const estatusSelect = [
    { value: "Inicial", label: "Inicial" },
    { value: "Configuración provisional", label: "Configuración provisional" },
    { value: "Pendiente por pagar", label: "Pendiente por pagar" },
    { value: "Pago en proceso", label: "Pago en proceso" },
    { value: "Concluido", label: "Concluido" },
  ]

  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/inventario/pagoUnidad/seguimiento-pago-unidades/${value.id}`, {
      state: value
    })
  }


  useEffect(() => {
    getAllData()
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.id && item.id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (estatus === '' || item.estatus === estatus) &&
        (tipoCompra === '' || item.tipoCompra === tipoCompra)
      )
    })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, estatus, tipoCompra, pageRows, data])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows, estatus, tipoCompra])

  //Modificar este servicio cuando se pueda. 
  const getAllData = async () => {
    setLoading(true)
    let frecuencias = await PagoUnidad.getAllSeguimientoPagoUnidad()
      .then(res => {
        const update = res.data.responseData.map(item => {
          
          const fechaFormat = FormatFecha(item.fechaAlta)
          let totalPagado = 0
          if (item.items !== null) {
            item.items.map(item => {
              totalPagado = totalPagado + item.montopagado
            })
          }

          let VINsUnicos = []
          if (item.items !== null){
            VINsUnicos = [...new Set(item.items.map(objeto => objeto.vin))];
          }

          return {
            ...item,
            fechaAltaFormat: fechaFormat,
            MontoPagado: totalPagado,
            totalVins: VINsUnicos.length
          }
        })

        setLoading(false)
        setData(update)

      }).catch(e => {
        setLoading(false)
      })

  }


  const RegistrarPago = async (item) => {
    const data = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
      tipoCompra: item.tipocompra,
      presupuesto: item.presupuesto.toString(),
      estatus: 'Inicial',
      fechaAlta: new Date()
    }
    setModalLoading(true)
    try {
      await PagoUnidad.creaSeguimientoPago(data)
      getAllData()
      setIsOpenModalRegistrar(false)
      setModalLoading(false)

    } catch (error) {
      setModalLoading(false)
    }


  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  const getReporteExcel = async () => {
    // Lógica para exportar el reporte en Excel
    setLoading(true);
    await PagoUnidad.exportarSeguimientoPagoUnidad()
        .then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `SeguimientoPagoUnidades-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
        })
  };

  return {
    handleDetails,
    loading,
    isOpenModalRegistrar,
    setIsOpenModalRegistrar,

    columns,
    currentData,
    modalLoading,

    tipoCompraSelect,
    estatusSelect,
    setTipoCompra,
    setEstatus,
    setName,

    RegistrarPago,

    //Paginacion
    currentPage,
    pageCount,
    setPageRows,
    handlePageClick,
    handleDetails,

    getReporteExcel,
  };
}

export default SeguimientoPagoUnidadesHook;