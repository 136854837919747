import { useEffect, useState } from 'react'
import Cobranza from '../../Services/Cobranza/Cobranza';

const useConfigurarZonaCobranza = () => {
   const columns = [
      { field: 'municipio', headerName: 'Municipio', width: 200 },
      { field: 'colonia', headerName: 'Colonia', width: 300 },
      { field: 'cp', headerName: 'CP', width: 300 }
   ]

   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [dataEstado, setDataEstado] = useState([]);
   const [idEstado, setIdEstado] = useState(0);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [idMunicipio, setIdMunicipio] = useState(0);
   const [defaultValueMunicipio, setDefaulValudeModelo] = useState({})
   const [zonaValue, setzonaValue] = useState('');
   const [suggestions, setSuggestions] = useState([]);
   const [zonas, setZonas] = useState([]);
   const [todasZonas, setTodasZonas] = useState([]);
   const [dataZonas, setdataZonas] = useState([]);
   const [sinZonasSeleccionadas, setSinZonasSeleccionadas] = useState(false);
   const [coloniaAsignada, setColoniaAsignada] = useState(false);
   const [msjColoniaAsignada, setMsjColoniaAsignada] = useState('');

   useEffect(() => {
      handleGetEstados();
   }, []);

   const handleGetEstados = async () => {
      setLoading(true);
      await Cobranza.getAllEstados()
         .then(res => {
            let NewData = [];
            res.data.responseData.map(element => {
               NewData.push({
                  value: element.cpId,
                  label: element.d_estado
               })
            });
            setDataEstado(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetMunucipios = async (value) => {
      let data = {
         "verb": "string",
         "id": value,
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {}
      }
      setLoading(true);
      await Cobranza.getMunicipios(data)
         .then(res => {
            let NewData = [];
            res.data.responseData?.map(element => {
               NewData.push({
                  value: element.cpId,
                  label: element.d_mnpio
               })
            });
            setDataMunicipio(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetdataZonas = async (nombreEstado, nombreMunicipio, zonaItems = []) => {
      let data = {
         "verb": "string",
         "id": nombreEstado,
         "field": "string",
         "value": nombreMunicipio,
         "flag": true,
         "anyObject": {}
      }
      setLoading(true);
      await Cobranza.getColonias(data)
         .then(res => {
            let NewData = [];
            res.data.responseData?.map(element => {
               console.log(zonaItems);
               let existe = zonaItems.some(obj => obj.sepomexid === element.cpId);

               if (existe) {
                  NewData.push({
                     item: element,
                     statusCheckBox: false,
                     idrow: element.cpId,
                     municipio: element.d_mnpio,
                     colonia: element.d_asenta,
                     cp: element.d_codigo,
                     status: true
                  })
               } else {
                  NewData.push({
                     item: element,
                     statusCheckBox: false,
                     idrow: element.cpId,
                     municipio: element.d_mnpio,
                     colonia: element.d_asenta,
                     cp: element.d_codigo,
                     status: false
                  })
               }
            });

            setdataZonas(NewData);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleGetzonas = async (nombreEstado, nombreMunicipio) => {
      setLoading(true);
      await Cobranza.getAllZonaHead()
         .then(res => {
            let NewData = [];
            res.data.responseData.filter(x => x.estado == nombreEstado && x.municipio == nombreMunicipio).map(element => {
               NewData.push({
                  value: element.id,
                  label: element.descripcion
               })
            });


            let NewData2 = [];
            res.data.responseData.map(element => {
               NewData2.push({
                  value: element.id,
                  label: element.descripcion
               })
            });


            setZonas(NewData);
            setTodasZonas(NewData2);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   const handleChangeMunicipio = async (item, zonaItems = []) => {
      setDefaulValudeModelo(item);
      setIdMunicipio(item.value);
      handleGetzonas(item.value, item.label);
      handleGetdataZonas(item.value, item.label, zonaItems);
   }

   const handleChangeMunicipio2 = async (nombreEstado, municipio, zonaItems = []) => {
      setDefaulValudeModelo(municipio);
      setIdMunicipio(municipio.value);
      handleGetzonas(nombreEstado, municipio.label);
      handleGetdataZonas(nombreEstado, municipio.label, zonaItems);
   }

   const handleChangeStatus = (checked, row) => {
      row.status = checked;
      const newStatusList = [...dataZonas];
      newStatusList.forEach(item => {
         if (item == row.idrow)
            item.status = checked;
      });
      setdataZonas(newStatusList);

      if (dataZonas.length > 0)
         setSinZonasSeleccionadas(false);

      console.log(`${checked} - ${row.idrow} - ${row.municipio} - ${row.colonia}`);
   }

   // Manejar el cambio de input y actualizar las sugerencias
   const handleInputChange = (e, mostrarOpciones) => {
      const value = e.target.value;
      setzonaValue(value);

      if (mostrarOpciones && zonas.length > 0) {
         const filteredSuggestions = zonas.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
         );
         setSuggestions(filteredSuggestions);
      } else {
         setSuggestions([]);
      }
   };

   // Manejar clic en sugerencias
   const handleSuggestionClick = (suggestion) => {
      setzonaValue(suggestion.label);
      setSuggestions([]);
      handleGetAllZonaItemByField(suggestion.value);
   };

   const handleGetAllZonaItemByField = async (idCp) => {
      let data = {
         "verb": "string",
         "id": "string",
         "field": "headid",
         "value": idCp,
         "flag": true,
         "anyObject": {}
      }
      setLoading(true);
      await Cobranza.getAllZonaItemByField(data)
         .then(res => {
            res.data.responseData?.map(element => {
               const dataZona = dataZonas.filter(x => x.idrow == element.sepomexid);
               dataZona.forEach(x => {
                  x.status = true;
               });
            });
            setdataZonas(dataZonas);
            setLoading(false);
         }).catch(() => {
            setLoading(false);
         })
   }

   return {
      columns,
      dataEstado,
      idEstado,
      dataMunicipio,
      idMunicipio,
      defaultValueMunicipio,
      handleGetMunucipios,
      handleChangeMunicipio,
      handleChangeMunicipio2,
      handleChangeStatus,
      dataZonas,
      openModal,
      setOpenModal,
      zonaValue,
      handleInputChange,
      suggestions,
      handleSuggestionClick,
      zonas,
      setIdEstado,
      setDefaulValudeModelo,
      loading,
      setLoading,
      sinZonasSeleccionadas,
      setSinZonasSeleccionadas,
      setzonaValue,
      todasZonas,
      coloniaAsignada,
      msjColoniaAsignada,
      setColoniaAsignada,
      setMsjColoniaAsignada
   }
}

export default useConfigurarZonaCobranza