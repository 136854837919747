import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Cierres from '../../Services/Resguardos/Cierres';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import Siniestros from '../../Services/Siniestro/Siniestro';

const BuscadorSiniestroVINModal = ({ modalMessage, setModalMessage, handleGetAll}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        buscar: ""
    };

    const [busquedaRealizada, setBusquedaRealizada] = useState(false);
    const [modalNum, setModalNum] = useState(0);
    const [dataVehiculo, setDataVehiculo] = useState({});
    const [dataSiniestro, setDataSiniestro] = useState({});
    const [dataSeguro, setDataSeguro] = useState({});
    const [initialValue, setInitialValue] = useState(initial);
    const [opcion, setOpcion] = useState(0); // O default, 1 nuevo sinietro, 2 continuar siniestro, 3 mensaje de no encontrato

    useEffect(() => {
        setOpcion(0)
    }, []);

    const validate = Yup.object().shape({});

    const handleBuscarVIN = async (values) => {

        if (values.buscar.trim().length === 0) {
            return false;
        }

        setLoading(true);

        await Garantias.getContract(values.buscar)
            .then( resp => {
                setBusquedaRealizada(true);
                if (resp.data.data !== null) {
                    setDataVehiculo(resp.data.data);
                    setDataSiniestro({});
                    setDataSeguro({});
                    //Buscar siniestro existente
                    getSiniestro(resp.data.data);
                    
                } else {
                    setDataVehiculo({});
                    setDataSiniestro({});
                    setDataSeguro({});
                    setOpcion(3)
                }
            })
            .catch(err => {
                setOpcion(3)
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
                modoRespuesta();
            });
    }

    

    const getSiniestro = async (values) => {
        //setLoading(true);
        await Siniestros.siniestroByVinGet(values.vin)
            .then(resp => {
                if (resp.data.length > 0) {
                    setDataSiniestro(resp.data);
                    setOpcion(2)
                    setDataSeguro({});
                }
                else{
                    setDataSiniestro({});
                    getSeguro(dataVehiculo);
                }
            })
            .catch(err => {
                console.error(err);
                setDataSiniestro({});
                getSeguro(dataVehiculo);
            })
            .finally( /* () => modoRespuesta() */ );
    };

    const getSeguro = async (values) => {
        //setLoading(true);
        await Siniestros.getSiniestroInformacionSeguro(values.generalId)
            .then(resp => {

                setDataSeguro(resp.data);
                setOpcion(1)
                setDataSiniestro({});
            })
            .catch(err => {
                console.error(err);
                toast.error(message("El VIN/Contrato buscado no tiene información de seguro"));
                setDataSeguro({});
                //setBusquedaRealizada(false);
            })
            .finally(  /* () => modoRespuesta() */ );
    };

    const modoRespuesta = () => {
        if (dataVehiculo.length > 0 && dataSeguro.length > 0) {
            setOpcion(1);
        }
        else if (dataVehiculo.length > 0 && dataSiniestro.length > 0) {
            setOpcion(2);
        }
        else {
            setOpcion(3);
        }
    }

    const goToRegistro = async () => {
        setLoading(true);

        
        if (dataSiniestro?.siniestroId) {
            setLoading(false);

            setTimeout(() => {
                navigate(`/seguro/siniestros/${dataVehiculo.vin}/${dataSiniestro.generalId}/${dataSiniestro.siniestroId}`);
            }, 1000);
        }
        else {
            let params = {
                generalId: dataVehiculo.generalId,
                siniestroStatusId: 10,
                siniestroProcesoId: 40
            };            
            await Siniestros.siniestroCrear(params)
                .then(async resp => {        
                    console.log("resul->",resp)            
                    if(resp.data.data.Ban === 1){
                        const generalId = resp.data.generalId;
                        const siniestroId = resp.data.data.SiniestroId;
    
                        let paramEntrega = {
                            generalId: dataVehiculo.generalId,
                            SiniestroClienteEntregaUnidadStatusId: 10,
                            siniestroId: siniestroId
                        };
    
                        await Siniestros.siniestroClienteEntregaUnidadCrear(paramEntrega);
    
                        let paramBoletinar = {
                            generalId: dataVehiculo.generalId,
                            SiniestroClienteBoletinarStatusId: 10,
                            siniestroId: siniestroId
                        };
    
                        await Siniestros.siniestroClienteBoletinarCrear(paramBoletinar);
    
                        let paramDesiste = {
                            generalId: dataVehiculo.generalId,
                            siniestroClienteDesisteStatusId: 10,
                            siniestroId: siniestroId
                        };
    
                        await Siniestros.siniestroClienteDesisteCrear(paramDesiste);
    
                        setLoading(false);
                        setModalMessage({ isOpen: false, type: 3, title: 'Buscador de VIN'})
                        setTimeout(() => {
                            toast.success(message("¡Correcto!",resp.data.data.Mensaje));
                        },2000);
                        // setTimeout(() => {
                        //     navigate(`/seguro/siniestros/${dataVehiculo.vin}/${generalId}/${siniestroId}`);
                        // }, 1000);
                        handleGetAll()
                    }else{
                        toast.error(message("¡Error!", resp.data.data.Mensaje));
                    }
                   
                })
                .catch(err => {
                    toast.error(message("¡Error, al intentar crear el siniestro!"));                    
                })
                .finally(() => setLoading(false));
        }

    };   
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px', outline: 'none !important' }} onClick={() => {
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            setDataVehiculo({});
                            setBusquedaRealizada(false);
                        }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h6><strong>{modalMessage.title}</strong></h6>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2">
                            <div className="col col-12">
                                {
                                    !busquedaRealizada &&
                                    <>
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">
                                                A continuación ingresa el VIN de la unidad que deseas registrar un siniestro
                                            </small>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-8">
                                                <TextField id="buscar" label="Buscador" name="buscar" type="text" holder="Escribe VIN/Contrato" onChange={(event) => {
                                                    setFieldValue("buscar", event.target.value);
                                                }} />
                                            </div>
                                            <div className="col-auto mt-4">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        handleBuscarVIN(values);
                                                    }}
                                                >
                                                    Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    // busquedaRealizada &&
                                    // Object.keys(data).length > 0 &&
                                    // //dataSiniestro?.siniestroId === undefined &&
                                    // dataSeguro?.generalId &&
                                    opcion === 1 &&
                                    
                                    <>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>VIN ingresado: {values.buscar}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <span>¿Deseas registrar el siniestro del siguiente vehículo?</span>
                                            </div>
                                            <div className="col-12">
                                                <strong>Marca: {dataVehiculo.nombreMarca} | Modelo: {dataVehiculo.nombreModelo} | Año: {dataVehiculo.nombreYear}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-gray d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        resetForm();
                                                        setDataVehiculo({});
                                                        setBusquedaRealizada(false);
                                                        setOpcion(0)
                                                    }}
                                                >
                                                    Regresar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        goToRegistro(values);
                                                    }}
                                                >
                                                    Registrar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    // busquedaRealizada &&
                                    // Object.keys(data).length > 0 &&
                                    // dataSiniestro?.siniestroId &&
                                    opcion === 2 &&
                                    <>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>VIN ingresado: {values.buscar}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <span>¿Deseas continuar el siniestro del siguiente vehículo?</span>
                                            </div>
                                            <div className="col-12">
                                                <strong>Marca: {dataVehiculo.nombreMarca} | Modelo: {dataVehiculo.nombreModelo} | Año: {dataVehiculo.nombreYear}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-gray d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        resetForm();
                                                        setDataVehiculo({});
                                                        setBusquedaRealizada(false);
                                                    }}
                                                >
                                                    Regresar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        goToRegistro(values);
                                                    }}
                                                >
                                                    Continuar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    // busquedaRealizada &&
                                    // Object.keys(data).length === 0 &&
                                    opcion === 3 &&
                                    <>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>VIN ingresado: {values.buscar}</strong>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-between">
                                            <div className="col-12">
                                                <strong>El VIN/contrato ingresado se encuentra cancelado / cerrado o no existe</strong>
                                            </div>
                                            {/* <div className="col-12">
                                                <strong>Marca: {data.nombreMarca} | Modelo: {data.nombreModelo} | Año: {data.nombreYear}</strong>
                                            </div> */}
                                        </div>
                                        <div className="row mt-3 justify-content-end">
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setModalMessage({ ...modalMessage, isOpen: false });
                                                        resetForm();
                                                        setDataVehiculo({});
                                                        setBusquedaRealizada(false);
                                                    }}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default BuscadorSiniestroVINModal;
