import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import Spinner from '../../../Components/Loadig';
// import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { SelectField } from '../../../Components/SelectField';
import AuthLogin from '../../../Utils/AuthLogin';

const EditarCobradorModal = ({ isOpen, setIsOpen, item }) => {
   const refbtn = useRef();
   const [fechaInicio, setFechaInicio] = useState("01/01/1970");
   const [fechaFin, setFechaFin] = useState("01/01/1970");
   const [opcionDisponibilidad, setOpcionDisponibilidad] = useState(0);
   const [fechaValida, setFechaValida] = useState(false);
   const [username, setUsername] = useState("");

   let initial = {
      nombrecobrador: item?.nombrecobrador,
      usuariosistema: username,
      telefono: item?.telefono,
      estatussistema: "",
      nivelcobranza: ""
   }

   useEffect(()=>{
      const auth = new AuthLogin();
        const storedValue = auth.getNombreUsuario()
        if (storedValue) {
            setUsername(auth.getNombreUsuario());
        }
   },[]);
//  const dispatch = useDispatch();
   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const dataNiveles = [
      { value: 1, label: "Nivel 1"},
      { value: 2, label: "Nivel 2"},
      { value: 3, label: "Nivel 3"},
      { value: 4, label: "Nivel 4"},
   ]

   const dataDisponibilidad = [
      { value: 1, label: "Disponible"},
      { value: 2, label: "Vacaciones"},
      { value: 3, label: "Baja"}
    ]
   const validate = Yup.object({
   //   nombrecobrador: Yup.string()
   //       .required("El campo es requerido."),
      nivelcobranza: Yup.string()
         .required("El campo es requerido."),
      telefono: Yup.string()
         .required("El campo es requerido."),
      estatussistema: Yup.string()
         .required("El campo es requerido."),
   });

   const handleAdd = async (values) => {
      console.log(values);
      //dispatch(setNuevaArea(item));
      //setNewArea(item);
      setIsOpen(false);

      // setLoading(true);
      // await Cobranza.addCobrador(item)
      //     .then(res => {
      //         setNuevaArea(item);
      //         setIsOpen(false);
      //         setLoading(false);
      //     }).catch(e => {
      //         setLoading(false);
      //     })
   }

   const handleValidarFechas = (tipoFecha, fecha) => {
      if(tipoFecha === 1){
         let validarFecha = fechaFin > fecha;
         if(validarFecha){
            setFechaInicio(fecha);
            setFechaValida(true);
         }
         else{
            setFechaInicio(fecha);
            setFechaValida(false);
         }
      }
      if(tipoFecha === 2){
         let validarFecha = fecha > fechaInicio;
         if(validarFecha && fechaInicio !== "01/01/1970"){
            setFechaFin(fecha);
            setFechaValida(true);
         }
         else{
            setFechaFin(fecha);
            setFechaValida(false);
         }
      }
   }

   const handleDisponibilidad = (value) => {
      setOpcionDisponibilidad(value);
      if(value == 0 || value == 2){
         setFechaInicio("01/01/1970");
         setFechaFin("01/01/1970");
         setFechaValida(false);
      }
      else{
         setFechaInicio("NA");
         setFechaFin("NA");
         setFechaValida(true);
      }
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
               handleAdd(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
               <Modal isOpen={isOpen} color='#fff'>
                  <section className='my-2 mx-4' style={{ width: '40rem', height: opcionDisponibilidad == 2 ? '40rem' : '35rem' }}>
                     <Form>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Editar cobrador </strong></span>
                        <div className="mb-3 row" style={{marginTop:30}}>
                              <div className="col-sm-6">
                                 <TextField label="Nombre" name="nombrecobrador" type="text" holder={values.nombrecobrador} disabled={true} />
                              </div>
                              <div className="col-sm-6">
                                 <TextField label="Usuario sistema" name="usuariosistema" type="text" holder={username} disabled={true} />
                              </div>
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <div className="col-sm-6">
                              <SelectField label="Nivel de cobranza" name="nivelcobranza" items={dataNiveles} onChange={(e) => {
                                 setFieldValue("nivelcobranza", e.value != 0 ? e.value : "");
                              }} />
                           </div>
                           <div className="col-sm-6">
                              <TextField label="Número de teléfono" min={0} name="telefono" value={values.telefono} type="text" holder="Escribe"
                                 onChange={(event) => {setFieldValue("telefono", event.target.value);}} />
                           </div>
                        </div>
                        <div className="mb-3 row" style={{marginTop:30}}>
                           <span className='fs-6' style={{fontWeight:'bold', color:"#000"}}><strong>Estatus de sistema</strong></span>
                           <p>El administrador puede modificar el estatus cuando lo vea necesario, en caso de que el cobrador solicite algún permiso o vacaciones el administrador deberá seleccionar el periodo solicitado.</p>
                           <div className="col-sm-6">
                              <SelectField name="estatussistema" items={dataDisponibilidad} onChange={(e) => {
                                 handleDisponibilidad(e.value);
                                 setFieldValue("estatussistema", e.value != 0 ? e.value : "");
                              }} />
                           </div>
                        </div>
                        {
                           opcionDisponibilidad == 2 && (
                              <div className="mb-3 row" style={{marginTop:30}}>
                                 <span className='mx-1 mb-3'>Agrega las fechas</span>
                                 <div className="col-sm-6">
                                    <input type="date" className="form-control mb-4" placeholder="Fecha inicio" onChange={(e) => { handleValidarFechas(1, e.target.value); }} />
                                 </div>
                                 <div className="col-sm-6">
                                    <input type="date" className="form-control mb-4" placeholder="Fecha fin" onChange={(e) => { setFechaFin(e.target.value); handleValidarFechas(2, e.target.value); }} />
                                 </div>
                              </div>)
                        }
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)|| !(fechaValida)}>Guardar</button>
                        </section>
                     </Form>
                  </section>
               </Modal>
               {
                  loading &&
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
               }
            </>
         )
      }
   </Formik>
   )
}

export default EditarCobradorModal