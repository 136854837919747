import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import { FormatFecha } from '../../Utils/functions'

const useUnidadesRecuperadas = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'tipoRecuperacion', headerName: 'Tipo de recuperación', width: 200 },
        { field: 'montoVencido', headerName: 'Monto vencido', width: 150 },
        { field: 'montoRecuperado', headerName: 'Monto recuperado', width: 200 },
        { field: 'fechaVencimiento', headerName: 'Fecha vencimiento', width: 200 },
        { field: 'vigencia', headerName: 'Vigencia', width: 150 },
        { field: 'estatus', headerName: 'Estatus', width: 150 }
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [zonaSeleccionada, setZonaSeleccionada] = useState("")
    //Filtro
    const [data, setData] = useState([{}]);
    const [pageRows, setpageRows] = useState(10);

    const userId = sessionStorage.getItem('userId').trim();
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const datatest = [
        {
            //id: 1,
            vin: '1HGCM82633A123456',
            contrato: 'C-12345',
            tipoRecuperacion: 'Judicial',
            montoVencido: 15000,
            montoRecuperado: 5000,
            fechaVencimiento: '2024-11-15',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 2,
            vin: '1HGCM82633A123457',
            contrato: 'C-12346',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 20000,
            montoRecuperado: 10000,
            fechaVencimiento: '2024-10-10',
            vigencia: 'Vencida',
            estatus: 'Finalizada'
        },
        {
            //id: 3,
            vin: '1HGCM82633A123458',
            contrato: 'C-12347',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 25000,
            montoRecuperado: 12000,
            fechaVencimiento: '2024-12-20',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 4,
            vin: '1HGCM82633A123459',
            contrato: 'C-12348',
            tipoRecuperacion: 'Judicial',
            montoVencido: 30000,
            montoRecuperado: 15000,
            fechaVencimiento: '2024-09-30',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 5,
            vin: '1HGCM82633A123460',
            contrato: 'C-12349',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 18000,
            montoRecuperado: 8000,
            fechaVencimiento: '2024-11-01',
            vigencia: 'Activa',
            estatus: 'En negociación'
        },
        {
            //id: 6,
            vin: '1HGCM82633A123461',
            contrato: 'C-12350',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 22000,
            montoRecuperado: 11000,
            fechaVencimiento: '2024-12-05',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 7,
            vin: '1HGCM82633A123462',
            contrato: 'C-12351',
            tipoRecuperacion: 'Judicial',
            montoVencido: 16000,
            montoRecuperado: 6000,
            fechaVencimiento: '2024-09-25',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 8,
            vin: '1HGCM82633A123463',
            contrato: 'C-12352',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 19000,
            montoRecuperado: 9000,
            fechaVencimiento: '2024-10-15',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 9,
            vin: '1HGCM82633A123464',
            contrato: 'C-12353',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 17000,
            montoRecuperado: 7000,
            fechaVencimiento: '2024-11-30',
            vigencia: 'Activa',
            estatus: 'En negociación'
        },
        {
            //id: 10,
            vin: '1HGCM82633A123465',
            contrato: 'C-12354',
            tipoRecuperacion: 'Judicial',
            montoVencido: 21000,
            montoRecuperado: 10000,
            fechaVencimiento: '2024-10-05',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 11,
            vin: '1HGCM82633A123466',
            contrato: 'C-12355',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 24000,
            montoRecuperado: 12000,
            fechaVencimiento: '2024-09-20',
            vigencia: 'Vencida',
            estatus: 'Finalizada'
        },
        {
            //id: 12,
            vin: '1HGCM82633A123467',
            contrato: 'C-12356',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 27000,
            montoRecuperado: 13000,
            fechaVencimiento: '2024-12-01',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 13,
            vin: '1HGCM82633A123468',
            contrato: 'C-12357',
            tipoRecuperacion: 'Judicial',
            montoVencido: 25000,
            montoRecuperado: 13000,
            fechaVencimiento: '2024-10-20',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 14,
            vin: '1HGCM82633A123469',
            contrato: 'C-12358',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 26000,
            montoRecuperado: 14000,
            fechaVencimiento: '2024-11-10',
            vigencia: 'Activa',
            estatus: 'En negociación'
        },
        {
            //id: 15,
            vin: '1HGCM82633A123470',
            contrato: 'C-12359',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 29000,
            montoRecuperado: 15000,
            fechaVencimiento: '2024-12-25',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 16,
            vin: '1HGCM82633A123471',
            contrato: 'C-12360',
            tipoRecuperacion: 'Judicial',
            montoVencido: 31000,
            montoRecuperado: 16000,
            fechaVencimiento: '2024-09-28',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 17,
            vin: '1HGCM82633A123472',
            contrato: 'C-12361',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 33000,
            montoRecuperado: 17000,
            fechaVencimiento: '2024-11-12',
            vigencia: 'Activa',
            estatus: 'En negociación'
        },
        {
            //id: 18,
            vin: '1HGCM82633A123473',
            contrato: 'C-12362',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 35000,
            montoRecuperado: 18000,
            fechaVencimiento: '2024-12-14',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 19,
            vin: '1HGCM82633A123474',
            contrato: 'C-12363',
            tipoRecuperacion: 'Judicial',
            montoVencido: 37000,
            montoRecuperado: 19000,
            fechaVencimiento: '2024-10-30',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 20,
            vin: '1HGCM82633A123475',
            contrato: 'C-12364',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 40000,
            montoRecuperado: 20000,
            fechaVencimiento: '2024-11-18',
            vigencia: 'Activa',
            estatus: 'En negociación'
        },
        {
            //id: 21,
            vin: '1HGCM82633A123476',
            contrato: 'C-12365',
            tipoRecuperacion: 'Voluntaria',
            montoVencido: 42000,
            montoRecuperado: 21000,
            fechaVencimiento: '2024-12-22',
            vigencia: 'Activa',
            estatus: 'En proceso'
        },
        {
            //id: 22,
            vin: '1HGCM82633A123477',
            contrato: 'C-12366',
            tipoRecuperacion: 'Judicial',
            montoVencido: 44000,
            montoRecuperado: 22000,
            fechaVencimiento: '2024-09-26',
            vigencia: 'Vencida',
            estatus: 'En litigio'
        },
        {
            //id: 23,
            vin: '1HGCM82633A123478',
            contrato: 'C-12367',
            tipoRecuperacion: 'Extrajudicial',
            montoVencido: 45000,
            montoRecuperado: 23000,
            fechaVencimiento: '2024-10-02',
            vigencia: 'Activa',
            estatus: 'En proceso'
        }
    ];

    const [currentData, setCurrentData] = useState(datatest);

    useEffect(() => {
        habdleGetAll();
        // handleGetZonas();
    }, [])

    // useEffect(() => {
    //     // Filtra los datos según los filtros seleccionados
    //     const filteredData = data.filter(x => {
    //         return (textoBusqueda === '' || (x.contrato_id && x.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()))) &&
    //             (zonaSeleccionada === 'Todos' || (x.zona && x.zona.toLowerCase().includes(zonaSeleccionada.toLowerCase())));
    //     });

    //     const indexOfLastItem = currentPage * pageRows
    //     const indexOfFirstItem = indexOfLastItem - pageRows
    //     const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    //     setCurrentData(newData)
    //     const newTotalPages = Math.ceil(filteredData.length / pageRows)
    //     setPageCount(newTotalPages)
    //     // if (currentPage > newTotalPages) {
    //     //     setCurrentPage(newTotalPages)
    //     // } else if (currentPage <= 0) {
    //     //     setCurrentPage(1)
    //     // }

    //     // Solo actualiza currentPage si es necesario para evitar bucles infinitos
    //     if (currentPage > newTotalPages && currentPage !== newTotalPages) {
    //         setCurrentPage(newTotalPages);
    //     } else if (currentPage <= 0 && currentPage !== 1) {
    //         setCurrentPage(1);
    //     }

    // }, [currentPage, textoBusqueda, pageRows, zonaSeleccionada])

    const habdleGetAll = async () => {
        setLoading(true);
        await SeguimientoVisitaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                let datosFormateados = userContracts.contratos.map((item) => {
                    const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                    const fechaClave = FormatFecha(item.fecha_ultima_clave)
                    return {
                        ...item,
                        fecha_ultimo_pago: fechaPago,
                        fecha_ultima_clave: fechaClave,
                    }
                });

                const opcionesZonas = [...new Map(datosFormateados.map(x => [`${x.zonaid}-${x.zona}`, { value: x.zonaid, label: x.zona }])).values()];
                setzonas(opcionesZonas);

                // setData(datatest);
                // setData(datosFormateados);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
        setLoading(false);
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleDetails = (value) => {
        //Movimiento a Detalles del Job
        navigate(`/cobranza/seguimiento-telefonico/detalles`, {
            state: value
        })
    }

    return {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        zonas,
        setpageRows,
        setZonaSeleccionada
    }
}

export default useUnidadesRecuperadas