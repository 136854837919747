import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import CobranzaService from '../../Services/Cobranza/Cobranza'
import DataTable from '../datatable/DataTable'

const DireccionesContactoModal = ({ isOpen, setIsOpen, data }) => {

    const columns = [
        // { field: 'Fecha', headerName: 'Fecha de registro' },
        // { field: 'nombre', headerName: 'Usuario' },
        { field: 'domicilio_ref', headerName: 'Dirección' },
    ];

    const [currentData, setCurrentData] = useState([])

    const handleRechazar = () => {
        setIsOpen(false)
    }

    const handleGetDirecciones = async () => {
        let value = {
            field: "referencia_ascendes_id",
            value: data.idreferencia
        }

        await CobranzaService.getAllContactoReferenciaDireccionByField(value)
            .then(res => {
                let datosFormateados = res.data.responseData?.map(item => {
                    if (item) {
                        let domicilio = `${item.calle} ${item.numero_exterior} ${item.colonia_ref} ${item.colonia_id} ${item.municipio_id} ${item.estado_id}`
                        return {
                            ...item,
                            domicilio_ref: domicilio,
                            message: item.comentarios
                        }
                    }
                });
                setCurrentData(datosFormateados);
            })
    }

    useEffect(() => {
        handleGetDirecciones()
    }, [])

    return (
        <Modal isOpen={isOpen} color='#fff' width={600}>
            <section className='d-flex justify-content-end'>
                <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                    onClick={() => handleRechazar()}><i className="ri-close-fill"></i></button>
            </section>
            <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h5><strong>Direcciones adicionales</strong></h5>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <div className="row">
                            <p id="AT_TxtBasicModalMenssage">
                                <strong>Relación con el solicitante: {data.relacion_solicitante}  |  Nombre: {data.nombre_ref}</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <DataTable
                            column={columns}
                            data={currentData}
                            commentColumn={true}
                        />
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <div className="row">
                        <div className="col-auto">
                            <button
                                type='reset'
                                id="AT_BtnCloseModal"
                                className='btn btn-outline d-flex justify-content-center align-items-center'
                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                onClick={() => handleRechazar()}
                            >
                                Cerrar
                            </button>
                        </div>

                    </div>
                </section>
            </div>
        </Modal>
    )
}

export default DireccionesContactoModal