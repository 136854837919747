import React, { useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
// import SubMenuSeguimientoVisita from './SubMenuSeguimientoVisita'
import useUnidadesRecuperadas from '../../../Hooks/UnidadesRecuperadas/UnidadesRecuperadas'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import ProgressBarHalfCircle from '../../../Components/ProgressBarHalfCircle/ProgressBarHalfCircle'
import ProgressBar from '../../../Components/ProgressBar/ProgressBar'
import '../SeguimientoVisita/SeguimientoVisita/SeguimientoVisita.css'
import ModalBusquedaUnidadesRecuperadas from './Cards/ModalBusquedaUnidadesRecuperadas'

const UnidadesRecuperadas = () => {
    const {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        zonas,
        setpageRows,
        setZonaSeleccionada
    } = useUnidadesRecuperadas();

    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);

    const [dataFiltro, setDataFiltro] = useState({
        page: 1,
        rows: 10,
        buscar: "",
        tipoSiniestroId: "",
        estatus: "",
        //Filtor vanzado
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Unidades Recuperadas</strong></span>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={zonas} className='col-12' onChange={(e) => { setZonaSeleccionada(e.label) }} placeholder="Tipo de recuperación" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={zonas} className='col-12' onChange={(e) => { setZonaSeleccionada(e.label) }} placeholder="Proceso" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={zonas} className='col-12' onChange={(e) => { setZonaSeleccionada(e.label) }} placeholder="Estatus" />
                        </div>
                        <div className="col-12 col-md-auto my-1 px-1">
                            <button className='btn btn-blue me-2' onClick={() => { setOpenModalAvanzado(true) }}>
                                Búsqueda Avanzada
                            </button>
                        </div>

                    </div>
                </section>
                <RowPages setpageRows={setpageRows} />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={currentData}
                        detailable={ValidatePermissionById(4, Section.UnidadesRecuperadas, SubModule.UnidadesRecuperadas).Historial}
                        handleDetail={handleDetails}
                        paginate={true}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currentPage - 1}
                    />
                </div>
            </ValidatePermission>
            {
                openModalAvanzado &&
                <ModalBusquedaUnidadesRecuperadas
                    isOpenModal={openModalAvanzado}
                    setIsOpenModal={setOpenModalAvanzado}
                    handleBusqueda={(value) => { setDataFiltro({ ...dataFiltro, ...value }) }}
                />
            }
        </>
    )
}

export default UnidadesRecuperadas