import React, { useState } from 'react';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div 
        onClick={toggleAccordion} 
        style={{
          display: 'flex', 
          alignItems: 'center', 
          cursor: 'pointer', 
          justifyContent: 'space-between',
          padding: '1rem',
        }}
      >
        <h4 style={{ margin: 0, fontSize:'0.9rem', fontWeight:700}}>{title}</h4>
        <div 
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s'
          }}
        >
          &#9650; {/* This is the arrow icon */}
        </div>
      </div>
      {isOpen && (
        <div style={{ padding: '10px', borderTop: '1px solid #ccc' }}>
          {children}
        </div>
      )}
    </>
  );
};

export default Accordion;