import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import SubMenuSeguimientoVisita from './SubMenuSeguimientoVisita'
import useHistorialVisitas from '../../../Hooks/SeguimientoVisita/HistorialVisitas'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import ProgressBarHalfCircle from '../../../Components/ProgressBarHalfCircle/ProgressBarHalfCircle'
import ProgressBar from '../../../Components/ProgressBar/ProgressBar'
import '../SeguimientoVisita/SeguimientoVisita/SeguimientoVisita.css'

const HistorialVisitas = () => {
    const {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        opcionesTipoVisita,
        setpageRows,
        setTipoVisita,
        handleDownloadFile,
        setFechaInicio,
        setFechaFin,
        rangoFechas,
        totalContratos,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
    } = useHistorialVisitas();

    const userName = sessionStorage.getItem('usuario')

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <SubMenuSeguimientoVisita />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

            <section className='pt-4 px-4'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div>
                            <span className='fs-5'><strong>{`¡Buen día, ${userName}!`}</strong></span>
                            <span className='seguimiennto-telfonico-calendar'> <i className='bx bx-calendar' ></i>{rangoFechas}</span>
                        </div>
                        {/* <div className="">
                            <i className="glyphicon fas fa-search"></i>
                            <p></p>
                        </div> */}
                    </div>
                    <div className='seguimiennto-telfonico-busqueda'>
                        <div className="inner-addon right-addon seguimiennto-telfonico-busqueda-bar">
                            <i className="glyphicon fas fa-search icon"></i>
                            <input type="text" className="form-control mb-4" placeholder="Busqueda General de contratos" onChange={(e) => { console.log(e) }} />
                        </div>
                        <div className='seguimiento-telefonico-dropdown' ref={dropdownRef}>
                            <button className='seguimiento-telefonico-dropdown-button' onClick={toggleDropdown}>
                                <i className='bx bx-dots-vertical-rounded x2 icon'></i>
                            </button>
                            {isOpen && (
                                <div className="seguimiento-telefonico-dropdown-content">
                                    <a onClick={(e) => toggleVisible(true)}
                                        className='seguimiento-telefonico-dropdown-content-link'>Ver todo</a>
                                    <a onClick={(e) => toggleVisible(false)}
                                        className='seguimiento-telefonico-dropdown-content-link'>Listado de contratos</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {isVisible && (
                <div className='d-flex'>
                    <div className='col-12 col-lg-4'>
                        <div className='estadistica-proceso-bar'>
                            <span className='col-12 text-center py-2'><strong>Estadísticas de progreso</strong> </span>
                            <span className='col-12 text-center py-2'>Asignados <strong>{totalContratos}</strong></span>
                            <ProgressBarHalfCircle initialPercentage={percentage} />
                        </div>
                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className='estadistica-promesas-de-pago'>
                            <span className='d-flex justify-content-center icon'><strong>Promesa de pago</strong></span>
                            <div className='d-flex justify-content-center py-2'>
                                <div className='seguimiennto-telfonico-promesa-pago-dias-hoy'>Hoy</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active7 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive7()}
                                >-7 días</div>
                                <div className={`seguimiennto-telfonico-promesa-pago-dias ${active15 ? `active` : `inactive`}`}
                                    onClick={(e) => toggleActive15()}
                                >-15 días</div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    {/* pROGRESS BAR */}
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressVigente.porcentajeActivos} primary={true} secondProgress={secondProgressVigentes.porcentajeActivos} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas vigentes</strong></span>
                                            <span className='col-12'>${firstProgressVigente.sumaTotalDinero}</span>
                                            <span className='col-12'>{firstProgressVigente.idsDiferentes} Contratos</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6'>
                                        <ProgressBar progress={firstProgressCumplidas.porcentajeActivos} secondProgress={secondProgressCumplidas.porcentajeActivos} active={active7 || active15} />
                                        <div className='estadistica-promesas-de-pago-vigentes'>
                                            <span className='col-12'><strong>Promesas cumplidas</strong></span>
                                            <span className='col-12'>${firstProgressCumplidas.sumaTotalDinero}</span>
                                            <span className='col-12'>{firstProgressCumplidas.idsDiferentes} Contratos</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <button onClick={increaseProgress}>Increase Progress</button> */}
                        </div>
                    </div>
                    <div className='col-12 col-lg-3'>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-final-texto'>Monto vencido</span>
                            <span className='estadistica-monto-final-numero'><strong>${totalMontoVencido}</strong></span>
                            <span>{totalContratos} contratos</span>
                        </div>
                        <div className='estadistica-monto-final'>
                            <span className='estadistica-monto-cobrado-texto'>Monto cobrado</span>
                            <span className='estadistica-monto-cobrado-numero'> <strong>${totalMontoCobrado}</strong></span>
                            <span>{totalContratos} contratos</span>
                        </div>
                    </div>
                </div>
            )}
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Historial de visitas</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.HistorialVisitas, SubModule.SeguimientoVisita).Descarga}>
                        <button className='btn' onClick={handleDownloadFile}>Exportar Excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>A continuación encontrarás el historial de todo el seguimiento de visitas realizado.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.HistorialVisitas, SubModule.SeguimientoVisita).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-3 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setTextoBusqueda(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <Select options={opcionesTipoVisita} className='col-12' onChange={(e) => { setTipoVisita(e.label) }} placeholder="Tipo de visita" />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <input type="date" className="form-control mb-4" placeholder="Fecha inicio" onChange={(e) => { setFechaInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <input type="date" className="form-control mb-4" placeholder="Fecha fin" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages setpageRows={setpageRows} />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={currentData}
                        detailable={ValidatePermissionById(4, Section.HistorialVisitas, SubModule.SeguimientoVisita).Historial}
                        handleDetail={handleDetails}
                        paginate={true}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currentPage - 1}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default HistorialVisitas