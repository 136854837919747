import axios from '../../Utils/ApiService';

const Siniestros = {

    /* SEGUIMIENTO SINIESTRO */
    getSeguimientoSiniestro: (data) => {
        return axios.post(`/cartera/getSeguimientoSiniestro`, data);
    },
    /* CREAR SINIESTRO */
    siniestroCrear: (data) => {
        return axios.post(`/cartera/siniestroCrear`, data);
    },
    /* OBTENER SINIESTRO */
    siniestroGet: (data) => {
        return axios.get(`/cartera/siniestroGet?siniestroId=${data}`);
    },
    /* OBTENER SINIESTRO BY VIN */
    siniestroByVinGet: (data) => {
        return axios.get(`/cartera/siniestroByVinGet?vin=${data}`);
    },
    /* ACTUALIZAR SINIESTRO */
    siniestroActualizar: (data) => {
        return axios.put(`/cartera/siniestroActualizar`, data);
    },
    /* OBTENER SINIESTRO INFORMACION SEGURO */
    getSiniestroInformacionSeguro: (data) => {
        return axios.get(`/cartera/siniestroInformacionSeguroGet?generalId=${data}`);
    },

    /* CREA SINIESTRO CLIENTE ENTREGA UNIDAD */
    siniestroClienteEntregaUnidadCrear: (data) => {
        return axios.post(`/cartera/siniestroClienteEntregaUnidadCrear`, data);
    },
    /* OBTENER SINIESTRO CLIENTE ENTREGA UNIDAD */
    siniestroClienteEntregaUnidadGet: (data) => {
        return axios.get(`/cartera/siniestroClienteEntregaUnidadGet?siniestroId=${data}`);
    },
    /* ACTUALIZAR SINIESTRO CLIENTE ENTREGA UNIDAD */
    siniestroClienteEntregaUnidadActualizar: (data) => {
        return axios.put(`/cartera/siniestroClienteEntregaUnidadActualizar`, data);
    },

    /* CREA SINIESTRO CLIENTE DESISTE */
    siniestroClienteDesisteCrear: (data) => {
        return axios.post(`/cartera/siniestroClienteDesisteCrear`, data);
    },
    /* OBTENER SINIESTRO CLIENTE DESISTE */
    siniestroClienteDesisteGet: (data) => {
        return axios.get(`/cartera/siniestroClienteDesisteGet?siniestroId=${data}`);
    },
    /* ACTUALIZAR SINIESTRO CLIENTE DESISTE */
    siniestroClienteDesisteActualizar: (data) => {
        return axios.put(`/cartera/siniestroClienteDesisteActualizar`, data);
    },

    /* CREA SINIESTRO CLIENTE BOLETINAR */
    siniestroClienteBoletinarCrear: (data) => {
        return axios.post(`/cartera/siniestroClienteBoletinarCrear`, data);
    },
    /* OBTENER SINIESTRO CLIENTE BOLETINAR */
    siniestroClienteBoletinarGet: (data) => {
        return axios.get(`/cartera/siniestroClienteBoletinarGet?siniestroId=${data}`);
    },
    /* ACTUALIZAR SINIESTRO CLIENTE BOLETINAR */
    siniestroClienteBoletinarActualizar: (data) => {
        return axios.put(`/cartera/siniestroClienteBoletinarActualizar`, data);
    },

    /* CREA SINIESTRO UNIDAD DETENIDA */
    siniestroUnidadDetenidaCrear: (data) => {
        return axios.post(`/cartera/siniestroUnidadDetenidaCrear`, data);
    },
    /* OBTENER SINIESTRO UNIDAD DETENIDA */
    siniestroUnidadDetenidaGet: (data) => {
        return axios.get(`/cartera/siniestroUnidadDetenidaGet?siniestroId=${data}`);
    },
    /* ACTUALIZAR SINIESTRO UNIDAD DETENIDA */
    siniestroUnidadDetenidaActualizar: (data) => {
        return axios.put(`/cartera/siniestroUnidadDetenidaActualizar`, data);
    },

    /* CREA SINIESTRO PAGO TRAMITES */
    siniestroPagoTramitesCrear: (data) => {
        return axios.post(`/cartera/siniestroPagoTramitesCrear`, data);
    },

    /* OBTENER SINIESTRO PAGO TRAMITES */
    siniestroPagoTramitesGet: (data) => {
        return axios.get(`/cartera/SiniestroPagoTramitesGet?siniestroId=${data}`);
    },

    /* ACTUALIZAR SINIESTRO PAGO TRAMITES */
    siniestroPagoTramitesActualizar: (data) => {
        return axios.put(`/cartera/siniestroPagoTramitesActualizar`, data);
    },

    /* CREA SINIESTRO PAGO TRAMITES ADEUDO */
    siniestroPagoTramitesAdeudoCrear: (data) => {
        return axios.post(`/cartera/siniestroPagoTramitesAdeudoCrear`, data);
    },
    siniestroPagoTramitesAdeudoDelete: (id) => {
        return axios.delete(`/cartera/DeleteSiniestroPagoTramites?PagoTramiteId=${id}`);
    },
    /* OBTENER SINIESTRO PAGO TRAMITES ADEUDO */
    siniestroPagoTramitesAdeudoGet: (data) => {
        return axios.get(`/cartera/SiniestroPagoTramitesAdeudoGet?SiniestroPagoTramitesAdeudoId=${data}`);
    },

    /* ACTUALIZAR SINIESTRO PAGO TRAMITES ADEUDO */
    siniestroPagoTramitesAdeudoActualizar: (data) => {
        return axios.put(`/cartera/siniestroPagoTramitesAdeudoActualizar`, data);
    },

    /* CREA SINIESTRO PAGO TRAMITES PAGO */
    siniestroPagoTramitesPagoCrear: (data) => {
        return axios.post(`/cartera/siniestroPagoTramitesPagoCrear`, data);
    },

    /* OBTENER SINIESTRO PAGO TRAMITES PAGO */
    siniestroPagoTramitesPagoGet: (data) => {
        return axios.get(`/cartera/siniestroPagoTramitesPagoGet?SiniestroPagoTramitesPagoId=${data}`);
    },

    /* ACTUALIZAR SINIESTRO PAGO TRAMITES PAGO */
    siniestroPagoTramitesPagoActualizar: (data) => {
        return axios.put(`/cartera/siniestroPagoTramitesPagoActualizar`, data);
    },

    /* CREA SINIESTRO VALUACION ASEGURADORA CREAR */
    siniestroValuacionAseguradoraCrear: (data) => {
        return axios.post(`/cartera/siniestroValuacionAseguradoraCrear`, data);
    },

    /* OBTENER SINIESTRO VALUACION ASEGURADORA */
    siniestroValuacionAseguradoraGet: (data) => {
        return axios.get(`/cartera/siniestroValuacionAseguradoraGet?siniestroId=${data}`);
    },

    /* ACTUALIZAR SINIESTRO VALUACION ASEGURADORA */
    siniestroValuacionAseguradoraActualizar: (data) => {
        return axios.put(`/cartera/siniestroValuacionAseguradoraActualizar`, data);
    },

    //Siniestro Cierre contrato - Reparacion
    siniestroReparacionGet: (siniestroId) => {
        return axios.get(`/cartera/GetSiniestroReparacionById?siniestroId=${siniestroId}`);
    },
    siniestroReparacionUpdate: (data) => {
        return axios.put(`/cartera/SiniestroReparacionUpd`, data);
    },
    //Siniestro Cierre contrato Perdida total
    siniestroPerdidaTotalGet: (siniestroId) => {
        return axios.get(`/cartera/GetSiniestroPerdidaTotalById?siniestroId=${siniestroId}`);
    },
    siniestroPerdidaTotalUpdate: (data) => {
        return axios.put(`/cartera/SiniestroPerdidaTotalUpd`, data);
    },
}

export default Siniestros