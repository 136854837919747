import React, { useState } from 'react'
import SiniestroDetalle from '../Detalle/SiniestroDetalle'
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard'
import ContratoHeaderCard from '../ContratoHeaderCard'
import InformacionSeguroCard from '../InformacionSeguroCard'
import SegumientoProcesoDatamovil from '../Detalle/SeguimientoProcesoDatamovil'
import SiniestroValuacionCard from './SiniestroValuacionCard'
import useSiniestroValuacion from '../../../../Hooks/Inventario/Siniestro/useSiniestroValuacion'
import { toast, Toaster } from 'react-hot-toast';
import ModalConfirm from '../../../Inventario/SeguimientoDeValuacion/ModalConfirm'

const SiniestroValuacion = () => {
    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useSiniestroValuacion(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SiniestroDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                <InformacionSeguroCard generalId={state?.generalId} />
                <SegumientoProcesoDatamovil />
                <SiniestroValuacionCard
                    handleConcluido={handleChangeStatusBtn}
                />
                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Dictamen"}
                        handleConfirm={handleClickTerminarProceso}
                        item={{adeudo:true,monto:"$200,00"}}                       
                    />
                }
            </div>
        </>
    )
}

export default SiniestroValuacion