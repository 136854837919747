import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import CardVehiculo from '../../Garantias/devoluciones/CardVehiculo';
import useCambioDevolucion from '../../../Hooks/Garantias/useCambioDevolucion';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import ContratoHeaderCard from './ContratoHeaderCard';
import InformacionSeguroCard from './InformacionSeguroCard';
// import RegistroSiniestroCard from './RegistroSiniestroCard';
import ClienteEntregaUnidadCard from './ClienteEntregaUnidadCard';
import BoletinarClienteCard from './BoletinarClienteCard';
import ClienteDesisteCard from './ClienteDesisteCard';
import Llamada from '../../../Services/Cartera/Llamadas';
import Garantias from '../../../Services/Garantias/Garantias';
import ValuacionAseguradoraCard from './ValuacionAseguradoraCard';
import { setInfoVin } from '../../../Redux/Slice/IncidenciasSlice/dataVinSlice';

const SiniestroCard = ({ }) => {
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const dispatch = useDispatch();
    const { id, generalid } = useParams();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });


    let {
        vehiculo
    } = useCambioDevolucion();

    let UrlPath = Url;
    if (Url === location.pathname || Url.trim().length === 0) {
        UrlPath = '/seguro/siniestros';
    }


    useEffect(() => {
        handleGetContract(id);        
    }, [])
    const handleGetContract = async (str) => {
        setLoading(true);
        //Llama a los servicios para la busqueda
        
        await Garantias.getContract(str?.trim())
            .then(async res => {
                if (res.data.statusCode === 204) {
                    const msgNotVin = "El VIN ingresado no se encuentra relacionado a ninguna incidencia.||Revisa el VIN o No. de contrato de tu vehículo e intenta reingresarlo nuevamente para tener acceso a las incidencias registradas.";
                    setModalMessage({ ...modalMessage, isOpen: true, type: 3, title: 'No se encontró el vehículo', message: msgNotVin });
                    return false;
                }                        
                var result = {
                    generalId: res.data.data.generalId,
                    fechaEntrega: res.data.data.fechaEntrega,
                    vin: res.data.data.vin,
                    nombreMarca: res.data.data.nombreMarca,
                    nombreModelo: res.data.data.nombreModelo,
                    nombreVersion: res.data.data.nombreVersion,
                    nombreYear: res.data.data.nombreYear,
                    color: res.data.data.color,
                    kilometraje: res.data.data.kilometraje,
                    procedenciaAuto: res.data.data.procedenciaAuto,
                    numMotor: res.data.data.numMotor,
                    numContrato: res.data.data.numContrato,
                    numSolicitud: res.data.data.numSolicitud,
                    cliente: res.data.data.cliente,
                    fechaUltimoPago: res.data.data.fechaUltimoPago,
                    producto: res.data.data.producto,
                    usoUnidad: res.data.data.usoUnidad,
                    TallerAutorizado: res.data.data.taller,
                    FolioCarnet: res.data.data.folioCarnet,
                    Expediente: res.data.data.expediente,
                    serieDatamovil : res.data.data.serieDatamovil,
                    transmision : res.data.data.transmision,
                    nombre:res.data.data.nombre,
                    fechaUltimoPagAcendes:"",
                    clienteAcendes:"",
                    fechaLiquidacionAcendes:"",
                }        

                await Llamada.getAdeudoCredito({
                    numeroSolicitud: res.data.data.numSolicitud,
                    vin: res.data.data.vin,
                    contrato: res.data.data.numContrato
                }).then(res => {
                    let resp = res.data
                    if (resp.result.status == 'proceso_correcto') {
                        console.log("-->",resp.result)
                        result = {...result,fechaUltimoPagAcendes:resp.result.ultimo_pago,clienteAcendes:resp.result.cliente,fechaLiquidacionAcendes:resp.result.termino_contrato.split("-").reverse().join("-")};                        
                    }
                })
                console.log("result Credito-->",result);
                dispatch(setInfoVin(result))
            
                setLoading(false);
            }).catch(e => {
                if (e.response !== undefined && e.response.data !== '')
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, title: '', message: e.message })
            })
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de incidencias</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por:</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <CardVehiculo data={vehiculo} claves={true} />
                        </div>
                        <div className="col-6 px-md-3">
                            <ContratoHeaderCard/>
                        </div>
                    </div>
                </div>
            </section>
            <InformacionSeguroCard />
            {/* <RegistroSiniestroCard
                state={state}
            /> */}
            {/* <ClienteEntregaUnidadCard />
            <BoletinarClienteCard/>
            <ClienteDesisteCard /> */}
            {/* <PagoTramitesCard />
            <UnidadDetenidaCard />
            <ValuacionAseguradoraCard /> */}
            {/* <ValuacionAseguradoraCard state={state} /> */}
        </>)
}

export default SiniestroCard