
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import Llamada from "../../Services/Cartera/Llamadas";
import { FormatFecha } from "../../Utils/functions";
import dataDocsServices from "../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../Utils/AuthLogin";
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { getDateFormat, handleUnchekedColumns, message, pageRows } from '../../Utils/functions'

const SeguimientoTelefonicoDetalleHook = (state) => {
    const userId = sessionStorage.getItem('userId').trim()
    const auth = new AuthLogin();
    let url = auth.UrlDataDocs();

    const columnsPromesas = [
        // { field: 'folio_promesa', headerName: 'Folio Promesa' },
        // { field: 'contrato_id', headerName: 'Contrato' },
        { field: 'periodo', headerName: 'Periodo' },
        { field: 'dias_vencidos', headerName: 'Días Vencidos' },
        { field: 'fecha_promesa', headerName: 'Fecha Promesa' },
        { field: 'monto_vencido', headerName: 'Monto Vencido' },
        { field: 'monto_promesa', headerName: 'Monto Promesa' },
        { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
        { field: 'monto_recuperado', headerName: 'Monto Cubierto' },
        { field: 'promesa_status', headerName: 'Estatus' },
        { field: 'ultimaActualizacion', headerName: 'Ultima Actualización' },

    ];

    const columnsSeguimientoLog = [
        { field: 'fecha', headerName: 'Periodo' },
        { field: 'cobrador', headerName: 'Cobrador' },
        { field: 'contacto', headerName: 'Contacto' },
        { field: 'nombre', headerName: 'Nombre' },
        { field: 'valor', headerName: 'Valor' },
        { field: 'operacion_id', headerName: 'Operacion' },
        { field: 'operacion_resultado_id_nombre', headerName: 'Resultado' },
        { field: 'aplica_visita_nombre', headerName: 'Aplica visita' },
        { field: 'motivo_visita_nombre', headerName: 'Motivo visita' },
        { field: 'acciones', headerName: 'Ver evidencia' },
        // { field: 'comentario_visita', headerName: 'Comentarios' },

    ];

    const columnsInformacionContacto = [
        { field: 'relacion_solicitante', headerName: 'Relación con solicitante' },
        { field: 'nombre_ref', headerName: 'Nombre' },
        { field: 'apellido_paterno_ref', headerName: 'Apellido' },
        { field: 'domicilio_ref', headerName: 'Domicilio Principal' },
        { field: 'interacciones', headerName: 'Interacciones' },
        // { field: 'operacion_resultado_id_nombre', headerName: 'Última Interacción' }
    ];

    const columnsSubInformacionContacto = [
        { field: 'tipo_contacto', headerName: 'Tipo de Contacto' },
        { field: 'subtipo_contacto', headerName: 'Subtipo' },
        // { field: 'valor', headerName: 'Valor' },
        // { field: 'interacciones', headerName: 'Interacciones' },
    ]

    const navigate = useNavigate();

    const [currentDataPromesas, setCurrentDataPromesas] = useState([{}])
    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([{}])
    const [currentDataSeguimientoLog, setCurrentDataSeguimientoLog] = useState([{}])
    // Datos contrato
    const [userDetail, setUserDetail] = useState([{}])
    const [promesasRotas, setPromesasRotas] = useState(0)
    const [promesasRotasTotal, setPromesasRotasTotal] = useState(3)

    const [isOpenModalReferencias, setisOpenModalReferencias] = useState(false)
    const [isOpenModalAgregarContacto, setisOpenModalAgregarContacto] = useState(false)
    const [isOpenModalPromesaPagoDelete, setisOpenModalPromesaPagoDelete] = useState(false)
    const [isOpenModalPromesaPagoData, setisOpenModalPromesaPagoData] = useState(false)
    const [promesaPagoData, setPromesaPagoData] = useState(false)
    const [isOpenModalDireccionContactos, setIsOpenModalDireccionContactos] = useState(false)
    const [infoContactoData, setInfoContactoData] = useState()
    const [isOpenModalRegistroLlamada, setIsOpenModalRegistroLlamada] = useState(false)

    //Modal
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    //Catalogos
    const [motivosVisita, setMotivosVisita] = useState([{}])
    const [tipoResultado, setTipoResulttado] = useState([{}])
    const [relacionSolicitante, setRelacionSolicitante] = useState([{}])
    const [dataEstado, setDataEstado] = useState([]);
    const [tipoContacto, setTipoContacto] = useState([]);
    const [subtipoContacto, setSubtipoContacto] = useState([]);
    const [motivoPromesa, setMotivoPromesa] = useState([])
    const [motivoIngresoCartera, setMotivoIngresoCartera] = useState([])
    const [tipoOperacionResultado, setTipoOperacionResultado] = useState([])
    const [tipoOperacion, setTipoOperacion] = useState([])
    const [promesasStatus, setPromesasStatus] = useState([])
    const [motivoEliminacion, setMotivoEliminacion] = useState([])

    //Tabla anidada
    const [directorioValue, setDirectorioValue] = useState("Aval")

    const [infoContactos, setInfoContactos] = useState([{}])
    const [infoContactosFiltered, setInfoContactosFiltered] = useState([{}])

    //sUBIR DOCUMENTOS
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [fileName, setFileName] = useState("")
    const [SelectedDocs, setSelectedDocs] = useState([]);

    const handleReturnPage = () => {
        navigate(`/cobranza/seguimiento-telefonico/`)
    }

    const handleModalRef = (value) => {
        setisOpenModalReferencias(true)
    }

    const handleModalAdd = (value) => {
        // alert("Función ejecutada desde el componente hijo")
        setInfoContactoData(value)
        setisOpenModalAgregarContacto(true)
    }

    const handlePromesasPago = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoData(true)
    }

    const handlePromesasPagoDelete = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoDelete(true)
    }

    const handleDireccionesModal = (item) => {
        setInfoContactoData(item)
        setIsOpenModalDireccionContactos(true)
    }

    const handleRegistroLlamada = (item) => {
        setInfoContactoData(item)
        setIsOpenModalRegistroLlamada(true)
    }

    useEffect(() => {
        //Catalogos
        HandleGetEstadoMx();
        HandleGetSubtiposContacto()
        HandleGetTipoContacto()
        HandleGetAllRelacionSolicitante()
        HandleMotivoPromesa()
        HandleMotivoIngresoCartera()
        HandleTipoOperaciones()
        HandleTipoOperacionesResultados()
        HandlePromesasStatus()
        HandleMotivoEliminacion()

        //Parametros 
        handleParametrosSistema()

        //Servicios
        handleGetSeguimientoContrato()
        handleGetPromesasdePago()
        handleContactosSeguimientoLog()

        //Tabla anidada
        HandleGetInformacionContactos()
    }, [])


    const handleSetStateTab = (value) => {
        setDirectorioValue(value)
    }

    useEffect(() => {
        const result = infoContactos?.filter(item =>
            item?.tipo?.toLowerCase().includes(directorioValue.toLowerCase())
        );
        setInfoContactosFiltered(result)
    }, [directorioValue, infoContactos])

    const HandleGetAllRelacionSolicitante = async (id) => {
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relaciones = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        relaciones.push({
                            value: item.id,
                            label: item.nombre
                        })
                    }
                });
                setRelacionSolicitante(relaciones)
            })
    }

    const HandleGetEstadoMx = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let Estados = []
            res.data.data?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setDataEstado(Estados);
        })
    }

    const HandleGetTipoContacto = async () => {
        await CobranzaService.getAllTipoContacto()
            .then(res => {
                let tipos = []
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        tipos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setTipoContacto(tipos);
            })
    }

    const HandleGetSubtiposContacto = async () => {
        await CobranzaService.getAllSubTipoContacto()
            .then(res => {
                let subtipo = []
                res.data?.map(item => {
                    if (item.activo) {
                        subtipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setSubtipoContacto(subtipo);
            })
    }

    const HandleMotivoPromesa = async () => {
        await CobranzaService.getAllMotivosPromesa()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoPromesa(motivo);
            })
    }

    const HandleTipoOperaciones = async () => {
        await CobranzaService.getAllTipoOperacion()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    if (item.activo) {
                        operacion.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setTipoOperacion(operacion);
            })
    }

    const HandleTipoOperacionesResultados = async () => {
        await CobranzaService.getAllOperacionResultado()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    operacion.push({
                        value: item.resultado_id,
                        label: item.resultado
                    });
                });
                setTipoOperacionResultado(operacion);
            })
    }

    const HandleMotivoIngresoCartera = async () => {
        await CobranzaService.getAllMotivoIngresoCartera()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoIngresoCartera(motivo);
            })
    }


    const HandlePromesasStatus = async () => {
        await CobranzaService.getAllEstatusPromesa()
            .then(res => {
                let promesa = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        promesa.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setPromesasStatus(promesa);
            })
    }

    const HandleMotivoEliminacion = async () => {
        await CobranzaService.getAllMotivoEliminacion()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoEliminacion(motivo);
            })
    }

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                let datosFormateados = userContracts.contratos.map((item) => {
                    const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                    const fechaClave = FormatFecha(item.fecha_ultima_clave)
                    return {
                        ...item,
                        fecha_ultimo_pago: fechaPago,
                        fecha_ultima_clave: fechaClave,
                    }
                })

                const contractDetail = datosFormateados.find(contract => contract.contrato_id === state.contrato_id) || [{}]

                const userDetail = userContracts.user_detail
                console.log(contractDetail)

                setCurrentDataSeguimiento(contractDetail)
                setUserDetail(userDetail)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleContactosSeguimientoLog = async () => {
        setLoading(true);


        let AllMotivos = await CobranzaService.getAllMotivoVisita()
            .then(res => {
                let newValueMotivos = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueMotivos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueMotivos
            })



        let AllTipoResultados = await CobranzaService.getAllTipoResultado()
            .then(res => {
                let newValueTipo = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueTipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueTipo
            })


        const data = {
            "value": state.contrato_id
        }
        await CobranzaService.getAllSeguimientoContactosLogs(data)
            .then(res => {
                const update = res.data.responseData.map(item => {
                    const fechaFormat = FormatFecha(item.fecha)
                    const aplicaVisitaFormat = item.aplica_visita ? "Si" : "No"


                    const motivoVisitaFormat = AllMotivos?.find(motivo => motivo?.value?.toLowerCase() === item?.motivo_visita_id?.toLowerCase())
                    const resultadoFormat = AllTipoResultados?.find(resultado => resultado?.value?.toLowerCase() === item?.operacion_resultado_id?.toLowerCase() || null)

                    return {
                        ...item,
                        fecha: fechaFormat,
                        aplica_visita_nombre: aplicaVisitaFormat,
                        motivo_visita_nombre: motivoVisitaFormat.label,
                        operacion_resultado_id_nombre: resultadoFormat.label,
                        message: item.comentario_visita

                    }
                })

                setMotivosVisita(AllMotivos)
                setTipoResulttado(AllTipoResultados)
                setCurrentDataSeguimientoLog(update)
            })

        setLoading(false);

    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                let promesasRotas = 0
                const update = res.data.map(item => {

                    let colorFondo
                    let colorTexto

                    switch (item.promesa_status_nombre) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "Vigente":
                            colorFondo = "#FAE7BC"
                            colorTexto = "#B46B16"
                            break;
                        default:
                            break;
                    }

                    const fechaPromesa = FormatFecha(item.fecha_promesa)

                    if (item.promesa_status_nombre === 'Rota') promesasRotas++

                    return {
                        ...item,
                        promesa_status: item.promesa_status_nombre,
                        colorFondo: colorFondo,
                        colorTexto: colorTexto,
                        fecha_promesa: fechaPromesa
                    }
                })

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === state.contrato_id) || [{}]

                setPromesasRotas(promesasRotas)
                setCurrentDataPromesas(promesasUpdate)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleParametrosSistema = async () => {
        let promesasRotasField =
        {
            FIELD: "ID",
            VALUE: "PROM_ROTAS_NIVEL_1"
        }

        let totalPromesasRotas = await CobranzaService.getAllParametrosByField(promesasRotasField)

        setPromesasRotasTotal(totalPromesasRotas.data.responseData[0].valor)

    }

    const AddInteracciones = async (item) => {
        let data = {
            field: "id_contacto_ascendes",
            value: item.idreferencia
        }
        try {
            const response = await CobranzaService.getRowsIteractions(data)
            return response.data.responseData; // Devuelve los datos de la respuesta
        } catch (e) {
            setLoading(false);
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };


    const GetInformacionContactos = async () => {
        const data = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            vin: state.vin
        }

        let result = await CobranzaService.getInformacionContactos(data)
            .then(res => {
                let datosFormateados = res.data.responseData.datos_referencia.map((item) => {
                    if (item) {
                        let domicilio = `${item.calle_ref} ${item.no_ext_ref} ${item.colonia_ref}`
                        return {
                            ...item,
                            domicilio_ref: domicilio,
                            filter: false
                        }
                    }
                })
                return datosFormateados
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })

        return result
    }

    const HandleGetInformacionContactos = async () => {
        const data = await GetInformacionContactos()

        const updateResults = await Promise.all(
            data.map(async (item) => {
                const updateItem = await AddInteracciones(item)

                item.interacciones = updateItem

                return item
            })
        )
        setInfoContactos(updateResults)
    }


    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {

                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {

                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/cobranza/seguimiento-telefonico/datadocs/fCobranza/eTelefono/${value.contrato}/${value.id}`, {
                state: { ...value, registroId: value.id }
            });
        }
    }

    return {
        handleReturnPage,
        columnsPromesas,
        columnsSeguimientoLog,
        columnsInformacionContacto,
        columnsSubInformacionContacto,

        currentDataPromesas,
        currentDataSeguimiento,
        currentDataSeguimientoLog,
        userDetail,
        promesasRotas,
        promesasRotasTotal,

        loading,

        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,

        handleModalRef,
        handleModalAdd,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleDireccionesModal,
        handleRegistroLlamada,

        //Catalogos
        motivosVisita,
        tipoResultado,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,
        infoContactoData,
        motivoPromesa,
        motivoIngresoCartera,
        tipoOperacion,
        tipoOperacionResultado,
        promesasStatus,
        motivoEliminacion,

        directorioValue,
        setDirectorioValue,
        handleSetStateTab,

        //Actualizar Tabla
        handleGetPromesasdePago,
        HandleGetInformacionContactos,
        handleContactosSeguimientoLog,

        //Subido
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs
    }
}
export default SeguimientoTelefonicoDetalleHook