import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ValuacionRegistroCostoService from '../../../Services/Inventario/Valuacion/ValuacionRegistroCostoService';
import { message } from '../../../Utils/functions';
const useSiniestroValuacion = (toast) => {
    const { id } = useParams();    
    const navigate = useNavigate();
    const { state } = useLocation();          
    let initial = {
        valuacionId: "",
        valuacionRegistroGastosId: "",
        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        registroCostos: []
    }
    const [activebtncontinuar, setactivebtncontinuar] = useState(false);
    const [stepe, setStepe] = useState([])
    const [stepSelected, setstepSelected] = useState();
    const [loading, setloading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [stateNew, setStateNew] = useState({});

    useEffect(() => {
        handleGetAllStep();
    }, [activebtncontinuar])
    const handleGetAllStep = async () => {        
        setloading(true)
        await ValuacionRegistroCostoService.getAllStepe(id,state?.procesoId)        
            .then((res) => {
                let step = res.data.data;                
                step.map(x => {
                    if (x.pasoSubStatus === 2) {
                        setstepSelected(x);
                        return;
                    }
                })
                setStepe(step);                
                setloading(false)
            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            })
    }
    const handleClickTerminar = async () => {
        setModalConfirm(true);
    }
    const handleClickTerminarProceso = async () => {  
        navigate(`/seguro/siniestros/${id}/${stepSelected.paso}`, {
            state: {...state,tipoDictamenId:stateNew?.tipoDictamenId,tipoDictamen:stateNew?.tipoDictamen}
        })
        return      
        setModalConfirm(false)
        setloading(true)
        let data = {
            id: id,
            paso: stepSelected.paso,
            porcentaje: stepSelected.porcentaje,
            pasoSubStatus: 3,
            procesoId: state.procesoId
        }        
        await ValuacionRegistroCostoService.updStep(data)
            .then((res) => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡Correcto!", res.data.data.mensaje));
                    //navegar al siguiente step                    
                    if (stepSelected.paso !== 4)
                        navigate(`/seguro/siniestros/${id}/${stepSelected.paso + 1}`, {
                            state: stateNew
                        })
                } else {
                    toast.error(message("¡Operación fallida!", res.data.data.mensaje));
                }
                setloading(false);
            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            })
    }
    const handleChangeStatusBtn = (item) => {  
        setStateNew(item);   
        if (item.concluido) {
            setactivebtncontinuar(true);            
            //handleGetCostosAdeudo
        }
    }
    return {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state     
    }
}

export default useSiniestroValuacion