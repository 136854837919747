import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDatosRuta } from '../../Redux/Slice/Cobranza/SeguimientoVisita/DetalleRutaSlice';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { FormatFecha, FormatFolioNumber } from '../../Utils/functions';

const useRutasCobranza = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [data, setData] = useState([])

   const columns = [
      { field: 'ruta', headerName: 'Ruta', width: 350},
      { field: 'contratos', headerName: 'Cantidad Contratos', width: 200},
      { field: 'fechavisita', headerName: 'Fecha de la visita', width: 250}
   ];

   const columnsDetail = [
      { field: 'numero', headerName: '#'},
      { field: 'contrato_id', headerName: 'Contrato' },
      { field: 'tipo_persona', headerName: 'Tipo de Persona'},
      { field: 'nombre', headerName: 'Nombre'},
      { field: 'tipo_visita', headerName: 'Tipo de Visita'},
      { field: 'dias_vencido', headerName: 'Días vencidos' },
      { field: 'monto_vencido', headerName: 'Monto vencido'},
      { field: 'fecha_visita', headerName: 'Fecha de visita'},
   ];

   const [page, setPage] = useState(1);
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10);
   const [openModalRuta, setOpenModalRuta] = useState(false);


   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      // handleGetAllCobradores(event.selected + 1);
   };

   const handleCrearRuta = () =>{
      setOpenModalRuta(true);
   }

   useEffect(()=>{
      handleGetRutas();
   }, []);

   const handleGetRutas = async () =>{
      let NewData = [];
      setLoading(true);
      await CobranzaService.GetAllRutasCobranza()
         .then(res =>{
            res.data.responseData.map((item)=>{
               console.log(res)
               let DetailData = [];
               let counter = 0;
               item.items.map((element)=>{
                  counter++;
                  DetailData.push({
                     numero: FormatFolioNumber(item.folio,9),
                     tipo_visita: element.tipo_visita,
                     fecha_visita: FormatFecha(element.fecha), 
                     contrato_id: element.contrato.contrato_id,
                     tipo_persona: element.contrato.tipo_persona,
                     nombre: `${element.contrato.nombre} ${element.contrato.apellido_paterno} ${element.contrato.apellido_materno}`,
                     direccion: `Calle ${element.contrato.calle} ${element.contrato.no_ext}, Col.${element.contrato.colonia}, C/.P. ${element.contrato.cp}`,
                     monto_vencido: element.contrato.monto_adeudo,
                     estatus: element.contrato.estatus,
                     dias_vencido: element.contrato.dias_vencido,
                     zona: element.contrato.zona,
                     zonaid: element.contrato.zonaid
                  })
               })
               // DetailData.push()
               NewData.push({
                  ruta: FormatFolioNumber(item.folio,9),
                  contratos: counter,
                  fechainicio:FormatFecha(item.fecha_inicio),
                  fechafin: FormatFecha(item.fecha_fin),
                  fechavisita: FormatFecha(item.fecha_inicio),
                  userId: item.userid,
                  detail: DetailData
               })
            })
            setLoading(false);
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      setData(NewData);
   }

   const handleDetails = (item) =>{
      dispatch(setDatosRuta({
         ruta: item.ruta,
         nombrecobrador: item.nombrecobrador,
         fechainicio: item.fechainicio,
         fechafin: item.fechafin,
         contratos: item.contratos,
         items: item.detail
         })
      );

      navigate("/cobranza/seguimiento-visita/rutas-cobranza/ruta-detalle", {
         state: {
            page: 'Detalle',
            title: "Detalle de ruta",
            puedeEditar: false,
            tipoUsuario: 0
         },
      });
   }

   return {
      columns,
      columnsDetail,
      data,
      pageCount,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleCrearRuta,
      page,
      setpageRows,
      openModalRuta,
      setOpenModalRuta
   }
}

export default useRutasCobranza