import React from "react"
import SubMenuSeguimientoTelefonico from "./SubMenuSeguimiento"
import SeguimientoCobranzaHook from "../../../Hooks/SeguimientoTelefonico/SeguimientoCobranza"
import RowPages from '../../../Components/RowPages'
import DataTable from "../../../Components/datatable/DataTable"

import { Section, SubModule } from '../../../Auth/Authorization'
import { ValidatePermissionById } from '../../../Auth/ValidatePermission'


const SeguimientoCobranza = () => {
  const {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick
  } = SeguimientoCobranzaHook()

  return (
    <>
      <SubMenuSeguimientoTelefonico />
      <div>

      <div className="col-lg-3 col-mg-6 col-sm-12 mt-4 mx-4 inner-addon right-addon">
        <i className="glyphicon fas fa-search"></i>
        <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setName(e.target.value) }} />
      </div>
      <RowPages
        setpageRows={setpageRows}
        />
        </div>
      <div className='mx-4 table-responsive'>
        <DataTable
          column={columns}
          data={currentData}
          detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
          handleDetail={handleDetails}
          paginate={true}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          currentPage={currentPage -1 }
        />
      </div>
    </>
  )
}

export default SeguimientoCobranza