import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import DataTable from '../../../../Components/datatable/DataTable';
import ConfirmarRutasModal from './ConfirmarRutasModal';
import useRutaDetalle from '../../../../Hooks/SeguimientoVisita/useRutaDetalle';
import CambioRutaModal from './CambioRutaModal';
import RutaConfiguradaModal from './RutaConfiguradaModal';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';

const RutaDetalle = () => {
   const { state } = useLocation(); 
   const navigate = useNavigate();

   let {
      columns,
      columnsContratos,
      dataContratos,
      dataContratosNoAsignados,
      datosruta,
      setStr,
      dataTipoPersonas,
      dataZonas,
      btnEditConfirm,
      setBtnEditConfirm,
      rutaConfigurada,
      setRutaConfigurada,
      handleCheckAsignar,
      handleCheckSinAsignar,
      openModal,
      setOpenModal,
      openModalConfirmacion,
      setOpenModalConfirmacion,
      openModalCambioRuta,
      setOpenModalCambioRuta,
      handleConfirmacion,
      handleDesasignarContratos,
      contratosSeleccionados,
      dataAsignarContratos,
      dataContratosSinAsignar,
      contratosAsignadosAruta,
      handleAceptar,
      loading
   } = useRutaDetalle(state);

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
               <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
               Regresar a listado de rutas {state.page}
         </header>
         <section className='mx-5'>
            <section className='py-4'>
               <span style={{fontSize: "20px", color: "#000", fontWeight: "bold"}}>
                  <strong>Ruta - {datosruta.ruta}</strong>
               </span>
               <p><span style={{fontWeight: "bold"}}>Datos de ruta:</span></p>
               <div className='row'>
                  <span className='col-2' style={{fontWeight: "bold"}}>Cobrador</span>
                  <span className='col-2' style={{fontWeight: "bold"}}>Fecha de ruta</span>
               </div>
               <div className='row'>
                  <span className='col-2' >{datosruta.nombrecobrador}</span>
                  <span className='col-2' >{datosruta.fechainicio} al {datosruta.fechafin}</span>
               </div>
            </section>
            {
               state.puedeEditar &&(
                  <section>
                  <section className=' py-4'>
                     <div className='d-flex row'>
                        <span className='fs-5 col-6'>
                           <span style={{fontSize: "20px", color: "#000", fontWeight: "bold"}}>
                              <strong>Contratos no asignados</strong>
                           </span>
                        </span>
                     </div>
                  </section>
                  <div className="mb-3 row">
                     <div className="col-sm-2 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                     </div>
                     <div className="col-sm-2">
                           <label className='col-12 mx-1'>
                              <Select name='tipo_persona' options={dataTipoPersonas} onChange={(e) => {
                                 console.log(e);
                                 }}
                                 placeholder="Tipo de persona" />
                           </label>
                     </div>
                     <div className="col-sm-2">
                        <Select className='col-12 mx-1' name='zona' options={dataZonas} onChange={(e) => {
                           console.log(e);
                           }}
                           placeholder="Zona" />
                     </div>
                     <section className='d-flex justify-content-end row col-6'>
                           <div className='row'>
                              <div className='d-flex justify-content-end row col-12'>
                                 {
                                    btnEditConfirm === 1 &&(
                                       <button id="AT_btnReasignar" className='btn btn-outline col-3' type='button' onClick={() => { setBtnEditConfirm(2) }}>Editar ruta</button>
                                    )
                                 }
                                 { 
                                    btnEditConfirm === 2 &&(
                                       <>
                                          <div className='col-3'>
                                          <span>
                                             <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={rutaConfigurada} 
                                                value={"rutaconfigurada"} 
                                                onChange={(e) => { 
                                                   setRutaConfigurada(e.target.checked) 
                                             }}/><span style={rutaConfigurada ? {fontWeight: 'bold', color: '#000'} : null} onClick={()=>{
                                                   if(rutaConfigurada){
                                                      setOpenModalConfirmacion(true);
                                                   }
                                                }}> Ruta configurada</span>
                                          </span>
                                          </div>
                                          <button 
                                             id="AT_btnAgregar" 
                                             disabled={dataAsignarContratos.length == 0} 
                                             className='btn col-3' 
                                             type="button" 
                                             onClick={() => { 
                                                setOpenModal(true);
                                          }}>Confirmar</button>
                                       </>
                                    )
                                 }
                              </div>
                           </div>
                           <div className='row'>
                              <span className='col-12 pt-3' style={{textAlign: "end"}}>Contratos asignados a ruta: {contratosAsignadosAruta}</span>
                           </div>
                        </section>
                  </div>
                  {/* <ValidatePermission
                     isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                     > */}
                     {
                        btnEditConfirm === 2 &&(
                           <div className='mx-4 table-responsive'  style={{ height:'25rem', overflowY: "scroll"}}>
                              <DataTable
                                 column={columns}
                                 data={dataContratosNoAsignados}
                                 checkbox={true}
                                 handleCheck={handleCheckAsignar}
                              />
                           </div>
                        )
                     }
                     {
                        btnEditConfirm === 1 &&(
                           <div className='mx-4 table-responsive' style={{ height:'25rem', overflowY: "scroll"}} >
                              <DataTable
                                 column={columns}
                                 data={dataContratosNoAsignados}
                              />
                           </div>
                        )
                     }
                     {/* </ValidatePermission> */}
               </section>
               )
            }

            {
               btnEditConfirm === 2 &&(
               <>
                  <div className='d-flex justify-content-center'>
                     <i className="icon-blue ri-arrow-up-circle-fill" style={{fontSize: "30px"}} ></i>
                  </div>
                  <div className="divider"></div>
                  <div className='d-flex justify-content-center'>
                     <i className="icon-blue ri-arrow-down-circle-fill" style={{fontSize: "30px"}}></i>
                  </div>
               </>)
            }
            <section>
               <section className=' py-4'>
                  <div className='d-flex row'>
                     <span className='fs-5 col-6'>
                        <span style={{fontSize: "20px", color: "#000", fontWeight: "bold"}}>
                           <strong>Contratos de ruta: {datosruta.ruta}</strong>
                        </span>
                     </span>
                  </div>
               </section>
               <div className="mb-3 row">
                  <div className="col-sm-2 inner-addon right-addon">
                     <i className="glyphicon fas fa-search"></i>
                     <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                  </div>
                  <div className="col-sm-2">
                        <label className='col-12 mx-1'>
                           <Select name='estado' options={dataTipoPersonas} onChange={(e) => {
                              console.log(e);
                              }}
                              placeholder="Tipo de persona" />
                        </label>
                  </div>
                  <div className="col-sm-2">
                     <Select className='col-12 mx-1' name='municipio'options={dataZonas} onChange={(e) => {
                        console.log(e);
                        }}
                        placeholder="Zona" />
                  </div>
                  <section className='d-flex justify-content-end row col-6'>
                     <div className='d-flex justify-content-end row col-12'>
                        {
                           btnEditConfirm === 2 &&(
                              <button 
                                 id="AT_btnReasignar" 
                                 disabled={dataContratosSinAsignar.length == 0} 
                                 className='btn btn-outline col-3' 
                                 type='button' 
                                 onClick={() => { 
                                    setOpenModalCambioRuta(true);
                                 }}
                              >Eliminar seleccionados</button>
                           )
                        }
                     </div>
                  </section>
               </div>

               {/* <ValidatePermission
                  isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                  > */}
                  {
                     btnEditConfirm === 2 &&(
                        <div className='mx-4 table-responsive' style={{ height:'25rem', overflowY: "scroll"}} >
                           <DataTable
                              column={columnsContratos}
                              data={dataContratos}
                              checkbox={true}
                              handleCheck={handleCheckSinAsignar}
                           />
                        </div>
                     )
                  }
                  {
                     btnEditConfirm === 1 &&(
                        <div className='mx-4 table-responsive' style={{ height:'25rem', overflowY: "scroll"}} >
                           <DataTable
                              column={columnsContratos}
                              data={dataContratos}
                           />
                        </div>
                     )
                  }
                  {/* </ValidatePermission> */}
                  {
                     <ConfirmarRutasModal
                        isOpen = {openModal}
                        setIsOpen = {setOpenModal} 
                        setConfirmar = {handleConfirmacion}
                        contratos = {contratosSeleccionados}
                        fechaInicio={datosruta.fechainicio}
                        fechaFin={datosruta.fechafin}
                     />
                  }
            </section>
         </section>
         {
            <RutaConfiguradaModal
               isOpen={openModalConfirmacion}
               setIsOpen={setOpenModalConfirmacion}
               nombreCobrador={datosruta.nombrecobrador}
               handleAceptar={handleAceptar}
            />
         }
         {
            <CambioRutaModal 
               isOpen={openModalCambioRuta}
               setIsOpen={setOpenModalCambioRuta}
               setConfirmar={handleDesasignarContratos}
               tipoUsuario={2}
            />
         }
      </>
   )
}

export default RutaDetalle