import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import Spinner from '../../../Components/Loadig';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { setStrBusqueda, setFechaInicio, setFechaFin, setConcluido, setLimpiarDatos } from '../../../Redux/Slice/Cobranza/ParametrosBusquedaSlice';

const BusquedaAvanzadaModal = ({ isOpen, setIsOpen, setAccionBuscar}) => {
   const refbtn = useRef();

   let initial = {
      strBusqueda: null,
      fechaInicio: null,
      fechaFin: null,
      concluido: null
   }
   const dispatch = useDispatch();
   const [initialValue, setInitialValue] = useState(initial);
   const [loading, setLoading] = useState(false);
   const dataFechaInicio = [
      { value: null, label: "Seleccionar fecha"},
      { value: "12/07/2023", label: "12/07/2023"},
      { value: "13/08/2023", label: "13/08/2023"},
   ]
   const dataFechaFin = [
      { value: null, label: "Seleccionar fecha"},
      { value: "12/07/2023", label: "12/07/2023"},
      { value: "13/08/2023", label: "13/08/2023"},
   ]
   // const validate = Yup.object({
   // //   nombrecobrador: Yup.string()
   // //       .required("El campo es requerido."),
   //    nivelcobranza: Yup.string()
   //       .required("El campo es requerido."),
   //       telefono: Yup.string()
   //       .required("El campo es requerido.")
   // });

   const handleContinuar = () => {
      setIsOpen(false);
   }

   const handleCancelar = () => {
      dispatch(setLimpiarDatos());
      setIsOpen(false);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         //validationSchema={validate}
         onSubmit={(values) => {
               handleContinuar(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
               <Modal isOpen={isOpen} color='#fff'>
                  <section className='my-2 mx-4' style={{ width: '25rem', height: '25rem' }}>
                     <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Búsqueda Avanzada</strong></span>
                     <Form>
                        <div className="col-sm-12 inner-addon right-addon mt-4">
                           <i className="glyphicon fas fa-search"></i>
                           <input id='strBusqueda' type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { dispatch(setStrBusqueda(e.target.value)) }} />
                        </div>
                        <label className='col-12 mt-4' style={{height:"50px"}}>
                           Fecha de inicio
                           <Select options={dataFechaInicio} label="fechaInicio" onChange={(e) => { 
                              dispatch(setFechaInicio(e.value));
                              }}
                              placeholder="Seleccionar fecha" />
                        </label>
                        <label className='col-12 mt-4' style={{height:"50px"}}>
                           Fecha de fin
                           <Select options={dataFechaFin} label="fechaFin" onChange={(e) => { 
                              dispatch(setFechaFin(e.value));
                              }}
                              placeholder="Seleccionar fecha" />
                        </label>
                        <label className='col-12 mt-4' style={{height:"50px"}}>
                           <input id="concluido" className='form-check-input chk' type="checkbox" defaultChecked={false} onChange={(e) => { dispatch(setConcluido(e.target.checked))}} /> <span> Concluido</span>
                        </label>
                        <section className='d-flex justify-content-center mt-3'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-6 mw-100' type='reset' onClick={() => { handleCancelar() }}>Cancelar</button>
                           <button id="AT_btnContinuar" className='btn mx-2 col-6 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                        </section>
                     </Form>
                  </section>
               </Modal>
               {
                  loading &&
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
               }
            </>
         )
      }
   </Formik>
   )
}

export default BusquedaAvanzadaModal