import React from 'react'
import imagen from '../Assets/img/dataware-blanco.png';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthLogin from '../Utils/AuthLogin';
import { ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { Module } from '../Auth/Authorization';
import { handleMenu } from '../Redux/Slice/menuSlice';
import { useDispatch } from 'react-redux';
import { Fade, Zoom } from 'react-awesome-reveal';

const auth = new AuthLogin();
const Header = () => {
  const dispatch = useDispatch();
  const [verMenu, setHandleMenu] = useState(false);
  const [userName, setUsername] = useState("");
  const myStyle = {
    cursor: 'pointer'
  };
  useEffect(() => {
    const auth = new AuthLogin();
    const storedValue = auth.getNombreUsuario()
    if (storedValue) {
      setUsername(storedValue);
    }
  }, []);

  useEffect(() => {
    verMenu ? document.body.classList.add('toggle-sidebar') : document.body.classList.remove('toggle-sidebar');
  }, [verMenu])

  const navigate = useNavigate();
  const handleClickLogOut = (e) => {
    auth.logout();
    navigate("/")

  };

  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <a className="d-flex align-items-center">
            <Fade direction='left' duration={500}>
              <img id='imgLogotipo' src={imagen} style={{ width: '3rem' }} alt="" />
            </Fade>
          </a>
          <Fade direction='left' duration={500}>
            <i className={`toggle-sidebar-btn pe-2 ${verMenu ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { setHandleMenu((current) => !current); dispatch(handleMenu(false)); }}></i>
          </Fade>
          <ul className="sidebar-nav nav-menu" id="sidebar-nav">
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.Inventario)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnInventario" className="btn">
                  <NavLink to="/inventario" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Inventario</span>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.Seguros)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnSeguro" className="btn">
                  <NavLink to="/seguro" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Seguro</span>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.Garantia)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnGarantias" className="btn">
                  <NavLink to="/garantias" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Garantías</span>
                  </NavLink >
                </li>
              </Fade>

            </ValidatePermission>
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.Cobranza)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnProcesosCierre" className="btn">
                  <NavLink to="/cobranza" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Cobranza</span>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.Gestoria)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnProcesosCierre" className="btn">
                  <NavLink to="/gestoria" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Gestoría</span>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>
            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.ProcesoDeCierre)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnProcesosCierre" className="btn">
                  <NavLink to="/procesocierre" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <span>Proceso de cierre</span>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>

            <ValidatePermission
              isActive={ValidatePermissionById(1, Module.PanelAdministrativo)}
            >
              <Fade direction='left' duration={500}>
                <li id="AT_btnPanelAdministrativo" className="btn">
                  <NavLink to="/admin" className="btn btn-primary mx-1" style={{ padding: '6px' }}>
                    <div className='dropdown'>
                      <span>Panel Administrativo</span>
                    </div>
                  </NavLink >
                </li>
              </Fade>
            </ValidatePermission>

          </ul>

        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown nav-item d-block d-lg-none">
              <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                <Fade direction='right' duration={500}>
                  <i className="icon-fill bi bi-menu-button-wide-fill"></i>
                </Fade>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" style={{ minWidth: '15rem' }}>
                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.Inventario)}
                >
                  <li id="AT_btnInventario" className="">
                    <NavLink to="/inventario" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Inventario</span>
                    </NavLink >
                  </li>
                </ValidatePermission>
                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.Seguros)}
                >
                  <li id="AT_btnSeguro" className="btn-outline">
                    <NavLink to="/seguro" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Seguro</span>
                    </NavLink >
                  </li>
                </ValidatePermission>
                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.Garantia)}
                >
                  <li id="AT_btnGarantias" className="btn-outline">
                    <NavLink to="/garantias" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Garantías</span>
                    </NavLink >
                  </li>
                </ValidatePermission>
                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.Cobranza)}
                >
                  <li id="AT_btnProcesosCierre" className="btn-outline">
                    <NavLink to="/cobranza" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Cobranza</span>
                    </NavLink >
                  </li>
                </ValidatePermission>

                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.Gestoria)}
                >
                  <li id="AT_btnProcesosCierre" className="btn-outline">
                    <NavLink to="/gestoria" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Gestoría</span>
                    </NavLink >
                  </li>
                </ValidatePermission>
                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.ProcesoDeCierre)}
                >
                  <li id="AT_btnProcesosCierre" className="btn-outline">
                    <NavLink to="/procesocierre" className="btn btn-primary w-100 text-start m-0">
                      <span className='text-dark'>Proceso de cierre</span>
                    </NavLink >
                  </li>
                </ValidatePermission>

                <ValidatePermission
                  isActive={ValidatePermissionById(1, Module.PanelAdministrativo)}
                >
                  <li id="AT_btnPanelAdministrativo" className="btn-outline">
                    <NavLink to="/admin" className="btn btn-primary w-100 text-start m-0">
                      <div className='dropdown'>
                        <span className='text-dark'>Panel Administrativo</span>
                        {/* <ul className='dropdown-content pt-2'>
                    <li>Option1</li>
                    <li>Option2</li>
                    <li>Option3</li>
                  </ul> */}
                      </div>
                    </NavLink >
                  </li>
                </ValidatePermission>

              </ul>
            </li>
            <Fade direction='right' duration={500}>
              <li className="nav-item dropdown pe-3">
                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                  <span className='rounded-circle'>{userName.charAt(0)}</span>
                  <span className="d-none d-md-block dropdown-toggle ps-2 text-white">{userName}</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li> <a className="dropdown-item d-flex align-items-center" style={myStyle} onClick={handleClickLogOut}> <i className="bi bi-box-arrow-right"></i> <span>Cerrar sessión</span> </a></li>
                </ul>
              </li>
            </Fade>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header