import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import ModeloAPI from '../../Services/Catalogos/Modelo';
import Catalogo from '../../Services/Catalogos/Catalogo';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import DataTable from '../datatable/DataTable';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';

const ResguardoDeUnidadModal = ({ isOpen, setIsOpen, item }) => {
    const refbtn = useRef();
    let initial = {
        agenteSeguroId: item?.agenteSeguroId ?? "",
        agenteSeguro: item?.agenteSeguro ?? "",
        status: item?.status ?? true
    }
    const [initialValue, setInitialValue] = useState(initial);
    const columns = [
        { field: '#', headerName: '#', width: '100' },
        { field: 'usuarioEjecutivoNombre', headerName: 'Fecha y hora', width: '120' },
        { field: 'tipoAdeudo', headerName: 'Ejecutivo', width: '100' },
        { field: 'fechaAdeudo', headerName: 'Concepto' },
        { field: 'subtotal', headerName: 'Subtotal', width: '140' },
        { field: 'iva', headerName: 'IVA(%)', width: '100' },
        { field: 'total', headerName: 'Total', width: '100' },
        { field: 'fechaAlta', headerName: 'Fecha de registro' },
    ]
    const validate = Yup.object({
        agenteSeguro: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            // .matches(
            //     "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
            //     "La información ingresada es incorrecta."
            // )
            // .matches(
            //     "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
            //     "solo debe existir un espacio entre cada palabra."
            // )
            .min(3, 'minimo 3 caracteres'),
    });
    const [errorMessage, setErrorMessage] = useState("");
    const handleAddEdit = async (item) => {
        // refbtn.current.disabled = true;
        // if (action === 'add') {
        //     await Catalogo.addAgenteSeguro(item)
        //         .then(res => {
        //             setIsOpen(false);
        //             handleGetAll(1);
        //             setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos guardados correctamente." })
        //         }).catch(e => {
        //             setIsOpen(false);

        //             if (e.response !== undefined)
        //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        //             else
        //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        //         })
        // }
        // if (action === 'edit') {
        //     await Catalogo.UpdAgenteSeguro(item)
        //         .then(res => {
        //             setIsOpen(false);
        //             handleGetAll(1);
        //             setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos modificados correctamente." })
        //         }).catch(e => {
        //             setIsOpen(false);

        //             if (e.response !== undefined)
        //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        //             else
        //                 setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        //         })
        // }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='my-4 mx-4'>
                        <span className='fs-5'><strong>Resguardo de unidad</strong></span>
                        <Form>
                            <div className="row mt-2 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <Tabs>
                                            <Tab title="Detalle de resguardo">
                                                <div className="row mt-2 mb-2">
                                                    <div className="col col-12">
                                                        <div className="row mt-2">
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="fechaId"
                                                                    disabled={true}
                                                                    label="Tipo de ubicación"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="usuarioBoletinaId"
                                                                    disabled={true}
                                                                    label="Nombre de ubicación"
                                                                    name="usuarioBoletina"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="fechaId"
                                                                    disabled={true}
                                                                    label="Fecha y hora de cita"
                                                                    name="fechaAlta"
                                                                    type="date"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <SelectField className="col-12 " disabled={initialValue?.clienteBoletinado} label="Motivo resguardo" items={[]} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <SelectField className="col-12" disabled={initialValue?.clienteBoletinado} label="Forma de arribo" items={[]} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                                }} />

                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    disabled={true}
                                                                    label="Estado"
                                                                    name="usuarioBoletina"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    disabled={true}
                                                                    label="Municipio"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    disabled={true}
                                                                    label="colonia"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                                <TextField
                                                                    disabled={true}
                                                                    label="Número"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-6 d-flex align-items-center mt-3">
                                                                <fieldset onClick={{}} className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-lg-9 col-md-6 col-sm-6">
                                                                <TextAreaField
                                                                    disabled={true}
                                                                    label="Referencia de ubicación"
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentariosEjecutivo"
                                                                    id="comentariosEjecutivoId"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab title="Costos adicionales">
                                                <DataTable
                                                    data={[{ title: "rest", data: "sds" }]}
                                                    column={columns}
                                                // handleDownload={handleDownload}
                                                // handleDocuments={handleViewdocs}
                                                // handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                />
                                            </Tab>
                                            <Tab title="Detalle de la entrega">
                                                <div className="row mt-2 mb-2">
                                                    <div className="col col-12">
                                                        <div className="row mt-2">
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="fechaId"
                                                                    disabled={initialValue?.concluido}
                                                                    label="Fecha de resguardo"
                                                                    name="fechaAlta"
                                                                    type="date"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="usuarioBoletinaId"
                                                                    disabled={initialValue?.concluido}
                                                                    label="Hora de resguardo"
                                                                    name="usuarioBoletina"
                                                                    type="time"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="fechaId"
                                                                    disabled={initialValue?.concluido}
                                                                    label="¿Quién entrega?"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    id="fechaId"
                                                                    disabled={initialValue?.concluido}
                                                                    label="¿Quién recibe?"
                                                                    name="fechaAlta"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("subtotal", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    disabled={initialValue?.concluido}
                                                                    label="Calle y número"
                                                                    name="usuarioBoletina"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <TextField
                                                                    disabled={initialValue?.concluido}
                                                                    label="Código postal"
                                                                    name="usuarioBoletina"
                                                                    type="text"
                                                                    holder="Escribe" />
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <SelectField className="col-12" disabled={initialValue?.concluido} label="Estado" items={[]} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                                }} />

                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <SelectField className="col-12" disabled={initialValue?.concluido} label="Municipio" items={[]} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                                }} />
                                                            </div>

                                                        </div>
                                                        <div className="row mt-2">
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <SelectField className="col-12" disabled={initialValue?.concluido} label="Colonia" items={[]} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-9">
                                                                <TextAreaField
                                                                    label="Comentarios"
                                                                    disabled={initialValue?.concluido}
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentariosEjecutivo"
                                                                    id="comentariosEjecutivoId"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto d-flex">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline me-2'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => { setIsOpen(false) }}
                                                >
                                                    Cancelar
                                                </button>
                                                <button
                                                    disabled={initialValue?.clienteBoletinado ? true : !(isValid)}
                                                    type='submit'
                                                    id="AT_BtnCloseModal"
                                                    className='btn'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="row mt-4 d-flex justify-content-between">
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" />
                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cierre de siniestro</label>
                                        </div>
                                        <div className="col-auto flex-column align-items-end">
                                            <div className="col-auto">
                                                {
                                                    initialValue.fechaInicio !== "" &&
                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                }
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    initialValue.concluido &&
                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ResguardoDeUnidadModal