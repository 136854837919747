
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { useDispatch, useSelector } from 'react-redux'
import { setSeguimientoContrato } from "../../Redux/Slice/navigateSliceSeguimientoTelefonico";
import { id } from "date-fns/locale";

const SeguimientoTelefonicoHook = () => {
    const dispatch = useDispatch()

    const userName = sessionStorage.getItem('usuario')
    const userId = sessionStorage.getItem('userId').trim()

    const [percentage, setPercentage] = useState(0)
    const [rangoFechas, setRangoFechas] = useState(0)

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    const [firstProgressVigente, setFirstProgressVigente] = useState([{
        porcentajeActivos: 0,
        sumaTotalDinero:0,
        idsDiferentes:0
    }])
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState([{
        porcentajeActivos: 0,
        sumaTotalDinero:0,
        idsDiferentes:0
    }])
    const [secondProgressVigentes, setSecondProgressVigentes] = useState([{
        porcentajeActivos:0
    }])
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState([{
        porcentajeActivos:0
    }])

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState()

    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    
    //Dropdown
    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)

    const dropdownRef = useRef(null)


    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {

        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {

        // Función para obtener la fecha límite
        const obtenerFechaLimite = () => {
            const hoy = new Date();
            const fechaLimite = new Date(hoy);
            fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
            return fechaLimite;
        };
    
        const fechaLimite = obtenerFechaLimite();
    
        // Filtrar datos según la fecha
        const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
        const total = datosFiltrados.length;
    
        // Suma total de dinero
        const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
        const sumaTotalDinero = activos.length > 0 ? 
        activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0
    
        // Número de IDs diferentes
        const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size :0
    
        // Número de estado activo y cálculo del porcentaje
        const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;
    
        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2)
        };
    }
    //////////////////////////////

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])


    useEffect(() => {
        handleGetSeguimientoContrato()
        handleGetPromesasdePago()
    }, [])


    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase())

                let contratosFiltrador = userContracts.contratos.filter(item => item.estatus === 'active')
                let length = userContracts.contratos.length
                let percentage = contratosFiltrador.length > 0 ? userContracts.contratos.length / contratosFiltrador.length: 0                

                setTotalContratos(length)
                setPercentage( percentage)
                setRangoFechas(userContracts.rangoFechas)
                
                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
    
    
        await CobranzaService.getAllPromesasDePago()
          .then(res => {
            
            const vigente = analizarDatos(res.data, 0, "Vigente")
            const cumplidas = analizarDatos(res.data, 0, "Cumplidas")

            const suma = res.data.reduce((total, obj) => {
                return {
                    montoVencido: total.montoVencido + obj.monto_vencido,
                    montoCobrado: total.montoCobrado + obj.monto_recuperado
                };
            }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

            setAllPromesas(res.data)
            setTotalMontoCobrado(suma.montoCobrado)
            setTotalMontoVencido(suma.montoVencido)
            setFirstProgressVigente(vigente)
            setFirstProgressCumplidas(cumplidas)

            setLoading(false);
    
          }).catch(e => {
            setLoading(false);
          })
      }

    return {
        userName,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        loading,
        totalContratos,
        rangoFechas,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        totalContratos
    }
}
export default SeguimientoTelefonicoHook