import { Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import Modal from "../../../../Components/Modal/Modal";
import { TextField } from "../../../../Components/TextField";
import Spinner from "../../../../Components/Loadig";

const CostoGruaModal = ({ isOpen, setIsOpen, asignarCosto, asignarIva }) => {
  const refbtn = useRef();
  const [costoTotal, setCostoTotal] = useState(0); // Estado para el total
  const [iva, setIva] = useState(""); // Estado para el IVA, se inicia vacío
  const [aplicaIva, setAplicaIva] = useState(false); // Estado para aplicar o no el IVA

  const [costoSinIva, setCostoSinIva] = useState(0);
  const [totalIva, setTotalIva] = useState(0)

  let initial = {
    folio: "011299863",
    nombrecobrador: "",
    zona: [],
    subtotal: "", // Cambiar a cadena para permitir valores vacíos
  };

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);

  const validate = Yup.object({
    nombrecobrador: Yup.string().required("El campo es requerido."),
    subtotal: Yup.number()
      .required("El campo es requerido.")
      .min(0, "El subtotal no puede ser negativo."), // Validación del subtotal
  });

  const handleAdd = () => {
    asignarCosto(costoSinIva);
    asignarIva(totalIva)
    setIsOpen(false);
  };

  // Función para calcular el costo total
  const calcularCostoTotal = (subtotal, iva, aplicaIva) => {
    setCostoSinIva(subtotal)
    let total = parseFloat(subtotal) || 0; // Asegurarse de que sea 0 si no es un número
    if (aplicaIva && !isNaN(iva)) {
      total += total * (iva / 100); // Aplica el IVA
      setTotalIva(total - subtotal)
    }
    else{
      setTotalIva(0)
    }
    return total;
  };

  // Función para manejar el cambio en el campo subtotal
  const handleSubtotalChange = (event, setFieldValue) => {
    const value = event.target.value;

    // Si el valor es vacío, establece subtotal en vacío
    if (value === "") {
      setFieldValue("subtotal", "");
      setCostoTotal(0);
    } else {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0) {
        setFieldValue("subtotal", numericValue);
        setCostoTotal(calcularCostoTotal(numericValue, iva, aplicaIva)); // Calcular el total con IVA
      }
    }
  };

  // Función para manejar el cambio en el campo de IVA
  const handleIvaChange = (event, setFieldValue, subtotal) => {
    let value = event.target.value;

    // Si el valor es vacío, no establece nada en el estado
    if (value === "") {
      setIva(""); // El estado del IVA queda vacío
      setFieldValue("iva", ""); // Deja el campo vacío
      setCostoTotal(calcularCostoTotal(subtotal, 0, aplicaIva)); // Calcular el total sin IVA
    } else if (parseFloat(value) < 0) {
      // Si el valor es negativo, deja el campo vacío
      setFieldValue("iva", ""); // Deja el campo vacío
      setIva("");
    } else {
      // Convertimos a número para asegurar que no sea NaN
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0) {
        setIva(numericValue);
        setFieldValue("iva", numericValue);
        setCostoTotal(calcularCostoTotal(subtotal, numericValue, aplicaIva)); // Calcular el total con IVA
      }
    }
  };

  // Función para manejar el focus en el campo
  const handleFocus = (event) => {
    if (event.target.value === "0") {
      event.target.value = ""; // Borra el cero
    }
  };

  // Función para manejar el blur (cuando el campo pierde foco)
  const handleBlur = (event) => {
    if (event.target.value === "") {
      event.target.value = ""; // No restaura el cero
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={validate}
      onSubmit={(values) => {
        handleAdd(values);
      }}
    >
      {({ isValid, values, setFieldValue }) => (
        <>
          <Modal isOpen={isOpen} color="#fff">
            <section className="my-2 mx-4" style={{ width: "35rem" }}>
              <Form>
                <span
                  className="fs-4"
                  style={{ fontWeight: "bold", color: "#000" }}
                >
                  <strong>Costo recolección grúa </strong>
                </span>
                <p className="mt-3">
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    A continuación captura el costo de la recolección de grúa.
                  </span>
                </p>
                <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                  <div className="col-sm-6">
                    <p className="mt-3">
                      <span style={{ color: "#000", fontWeight: "bold" }}>
                        Valor
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p className="mt-3">
                      <span style={{ color: "#000", fontWeight: "bold" }}>
                        Total
                      </span>
                    </p>
                  </div>
                </div>
                <div className="mb-3 row px-4">
                  <div className="col-sm-6">
                    <p className="mt-3">
                      <span style={{ color: "#000" }}>Costo grúa</span>
                    </p>
                  </div>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className="me-2" style={{ color: "#000" }}>
                      $
                    </span>
                    <TextField
                      id="subtotal"
                      name="subtotal"
                      type="number"
                      holder="Escribe"
                      value={values.subtotal} // Permite el vacío
                      onChange={(event) =>
                        handleSubtotalChange(event, setFieldValue)
                      } // Actualizamos subtotal
                      onFocus={handleFocus} // Maneja el enfoque
                      onBlur={handleBlur} // Maneja el desenfoque
                    />
                  </div>
                </div>
                <div className="mb-3 row px-4">
                  <div className="col-sm-6">
                    <p className="mt-3">
                      <span style={{ color: "#000" }}>Total</span>
                    </p>
                  </div>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className="me-2" style={{ color: "#000" }}>
                      $
                    </span>
                    <p className="mt-3">
                      <span style={{ color: "#000" }}>{costoTotal}</span>
                    </p>
                  </div>
                </div>
                {aplicaIva && (
                  <div className="mb-3 row px-4">
                    <div className="col-sm-6">
                      <p className="mt-3">
                        <span style={{ color: "#000" }}>IVA</span>
                      </p>
                    </div>
                    <div className="col-sm-6 d-flex align-items-center">
                      <TextField
                        id="iva"
                        name="iva"
                        type="number"
                        holder="Escribe"
                        value={iva} // Se usa el estado de IVA que puede ser vacío
                        onChange={(event) =>
                          handleIvaChange(event, setFieldValue, values.subtotal)
                        } // Actualizamos IVA
                        onFocus={handleFocus} // Maneja el enfoque
                        onBlur={handleBlur} // Maneja el desenfoque
                      />
                      <span className="me-2" style={{ color: "#000" }}>
                        %
                      </span>
                    </div>
                  </div>
                )}

                <div className="mb-3 row px-4">
                  <div className="form-check mx-1 col-sm-6">
                    <span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={aplicaIva}
                        value={"rangodefechas"}
                        onChange={(e) => {
                          setAplicaIva(e.target.checked);
                          setCostoTotal(
                            calcularCostoTotal(
                              values.subtotal,
                              iva,
                              e.target.checked
                            )
                          );
                          // Si aplica IVA se pone el valor a 0, si no se deja vacío
                          if (e.target.checked) {
                            setIva(0);
                          } else {
                            setIva("");
                          }
                        }} // Aplica o no IVA
                      />{" "}
                      Aplicar IVA
                    </span>
                  </div>
                </div>
                <section className="d-flex justify-content-end mt-5">
                  <button
                    id="AT_btnCancelar"
                    className="btn btn-outline col-4 mw-100"
                    type="reset"
                    onClick={() => {
                      setIsOpen(false);
                      setInitialValue(initial); // Restablecer valores al inicial
                      setCostoTotal(0); // Restablecer el costo total
                      setIva(0); // Restablecer el IVA
                      setAplicaIva(false); // Restablecer el estado del IVA
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    id="AT_btnGuardar"
                    className="btn mx-2 col-4 mw-100"
                    type="button"
                    onClick={() => {
                      handleAdd();
                    }}
                    disabled={!values.subtotal} // Desactivar el botón si el subtotal está vacío
                  >
                    Guardar
                  </button>
                </section>
              </Form>
            </section>
          </Modal>
          {loading && (
            <Modal isOpen={loading} color="transparent">
              <Spinner message={""} />
            </Modal>
          )}
        </>
      )}
    </Formik>
  );
};

export default CostoGruaModal;
