import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../Redux/Slice/Cobranza/ReasignarCargaNivelDosSlice';

const useSupervisionCargaNivel2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  //Columnas de vista principal
  const columns = [
    { field: 'nombrecobrador', headerName: 'Cobrador', width: 200 },
    { field: 'cantidadcontratos', headerName: 'Cantidad contratos', width: 200 },
    { field: 'fechayhora', headerName: 'Fecha y hora', width: 170 },
  ]

  const columnsDetail = [
      { field: 'zona', headerName: 'Zona', width: 300 },
      { field: 'contrato', headerName: 'Contrato', width: 150 },
      { field: 'tipopersona', headerName: 'Tipo persona', width: 150 },
      { field: 'nombre', headerName: 'Nombre', width: 300 },
      { field: 'direcciontooltip', headerName: 'Dirección', width: 110 },
      { field: 'diasvencidos', headerName: 'Días vencidos', width: 130 },
      { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 200 },
      { field: 'montovencido', headerName: 'Monto vencido', width: 150 },
      { field: 'visitas', headerName: 'Visitas', width: 120 },
  ];

  const { strBusqueda, fechaInicio, fechaFin, concluido} = useSelector((state) => state.ParametrosBusquedaSlice);
  const [data, setData] = useState([]);
  const [dataCobradores, setDataCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState();
  const [page, setPage] = useState(1);
  const [idCobrador, setIdCobrador] = useState(0);
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [item, setItem] = useState(null);
  
  // useEffect(() => {
  //     handleFilters(1, pageRows, idCobrador, idNivel, str);
  //     setPage(1);
  // }, [str, idCobrador, idNivel,pageRows])

  useEffect(() =>{
    handleGetAllData();
    getAllCobradores();
  }, []);

  const handleGetAllData = async () => {
    let dataContratos = [];
    let dataUsuarios = [];
    await Cobranza.getEjecutivosFullDetail()
      .then(res => {
        console.log(res);
        if(res.data.callSucceded){
          res.data.responseData.map(item => {
            if(item.nivel_cobrador == "2"){
              let count = 1;
              item.contratos.map(cont => {
                dataContratos.push({
                  numero: count,
                  zona: cont.zona,
                  contrato: cont.contrato_id,
                  tipopersona: "0",
                  nombre: `${cont.nombre} ${cont.apellido_paterno} ${cont.apellido_materno}`,
                  direcciontooltip: `${cont.calle} ${cont.no_ext}, ${cont.municipio} ${cont.cp}, ${cont.estado} - ${cont.municipio}`,
                  direccion: `${cont.calle} ${cont.no_ext}, ${cont.municipio} ${cont.cp}, ${cont.estado} - ${cont.municipio}`,
                  diasvencidos: cont.dias_vencido,
                  diassinconsultarclave: cont.dias_sin_consultar_clave,
                  montovencido: "0",
                  visitas: "0"
                });
                count++;
              });
              dataUsuarios.push({
                userid:item.userid,
                nombrecobrador: item.user_detail.names,
                cantidadcontratos: item.numContratos,
                fechayhora: "0",
                detail: dataContratos
              });
            }
          })
        }
        setData(dataUsuarios);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.log(`ERROR -- ${e}`)
      })
  }

  // const handlePageClick = (event) => {
  //     setPage(event.selected + 1);
  //     handleGetallCobradores(event.selected + 1);
  //   };

  const getAllCobradores = async () => {
    let NewData = [{value:null, label:"Seleccionar"}];
    //Se usa el endpoint solo para pruebas, se debe cambiar por el correspondiente
    await Cobranza.getAllUsersNotInCobranza()
        .then(res => {
            if(res.data.callSucceded){
                res.data.responseData.map(item => {
                    NewData.push({value: item.userId, label: item.names})
                })
            }
        })
        .catch(e => {
            console.log(`ERROR -- ${e}`)
        })
        setDataCobradores(NewData);
  }

  const handleDetails = (item) =>{
    dispatch(setUsuario({
      userid: item.userid,
      nombrecobrador: item.nombrecobrador,
      numerocontratos: item.cantidadcontratos,
      contratos: item.detail
    }));

    navigate(`/cobranza/reasignarcargatrabajoniveldos`, {
      state: {
          page: 'Detalle',
          title: "Editar usuario"
      },
      });
  }
  
  return {
    columns,
    columnsDetail,
    data,
    pageCount,
    dataCobradores,
    setIdCobrador,
    setStr,
    loading,
    // handlePageClick,
    // handleEdit,
    // page,
    setpageRows,
    handleDetails
  }
}

export default useSupervisionCargaNivel2