import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../SelectField';
import { toast, Toaster } from 'react-hot-toast';
import ResguardoDeUnidadModal from './ResguardoDeUnidadModal';

const DatamovilModal = ({ modalMessage, setModalMessage, handleOk }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);

    const initial = {}
    const [initialValue, setInitialValue] = useState(initial);

    const [modalResguardoUnidad, setModalResguardoUnidad] = useState(false);
    const [dataProceso, setDataProceso] = useState([]);

    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const validate = Yup.object().shape({
        tipoProceso: Yup.string()
            .required('Campo requerido')
    });
    useEffect(() => {
        getSiniestroTipo();
    }, [modalMessage])
    const getSiniestroTipo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroProcesoDatamovil')
            .then(resp => {
                let NewData = [];
                // NewData.push({
                //     value: "",
                //     label: "Todos"
                // })
                resp.data.map(item => {
                    NewData.push({ value: item.SiniestroProcesoDatamovilId, label: item.Proceso });
                });                          
                setDataProceso(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    };    
    const handleModal = (value) => {
        switch (value.tipoProceso) {
            case 1:
                console.log("tipo proceso", value)
                break;
            case 2:
                console.log("tipo proceso", value)
                break;
            case 3:
                console.log("tipo proceso", value)
                break;
            case 4:
                console.log("tipo proceso", value)
                break;
            case 5:
                console.log("tipo proceso", value)
                break;
            case 6:
                console.log("tipo proceso", value);
                setModalResguardoUnidad(true)
                break;
        }
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleModal(values)
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        {
                            modalResguardoUnidad &&
                            <ResguardoDeUnidadModal
                                isOpen={modalResguardoUnidad}
                                setIsOpen={setModalResguardoUnidad}
                            />
                        }
                        <div className='justify-content-start p-2 align-items-center'>
                            <Form>
                                <div className="row">
                                    <div className="col col-12">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col col-12">
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <SelectField className="col-12" label="Tipo de proceso:" name={`tipoProceso`} items={dataProceso} onChange={(event) => {
                                                    setFieldValue(`tipoProceso`, event.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto d-flex">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline me-2'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleRegresar()}
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type='submit'
                                                id="AT_BtnCloseModal"
                                                disabled={!isValid}
                                                className='btn'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleOk(values)}
                                            >
                                                Continuar
                                            </button>
                                        </div>

                                    </div>
                                </section>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default DatamovilModal;
