import React, { useRef, useState, useEffect } from 'react'; // Importa useEffect
import { Formik } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import { TextAreaField } from '../TextAreaField';
import { message } from '../../Utils/functions';
import CobranzaService from '../../Services/Cobranza/Cobranza';

const AutorizacionBoletinadoModal = ({ isOpen, setIsOpen, data, toast }) => {
  console.log(data)
  const refFormik = useRef();
  const [resultado, setResultado] = useState('');
  const [motivoText, setmotivoText] = useState('');
  const [motivoItems, setmotivoItems] = useState([]);


  const handleRechazar = () => {
    setIsOpen(false);
  };

  const handlerGuardar = async (values) => {
    // Verifica que data no sea undefined
    if (!data) {
      console.error("No se han proporcionado datos para el modal.");
      return;
    }

    const Autorizado = resultado === "1" ? "True" : "False";
    // Cambia el nombre del objeto `data` dentro de esta función para evitar sobrescribir
    let requestData = {
      Id: data.siniestroClienteBoletinarId,
      Comentarios: values.message, // Valor de Formik
      Autorizado: Autorizado, // Valor de Formik
      MotivoRechazoId: values.motivo, // Valor de Formik
      MotivoRechazo: motivoText // Valor de Formik

    };
    const response = await CobranzaService.AutorizarRechazarBoletinado(requestData);
    console.log(requestData);
    if (response.status === 200) {
      console.log('Datos enviados correctamente:');

      const successMessage = response.data?.mensaje || "¡Operación exitosa!";

      toast.success(successMessage);
      setIsOpen(false); // Cierra el modal si todo va bien
    } else {
      console.error('Error al enviar los datos:', response);
    }
  };

  const HandleGetMotivos = async () => {
    setmotivoItems([]);
    try {
      const res = await CobranzaService.GetAllBoletinadoMotivoRechazo();
      console.log(res.data.responseData);
      const motivos = res.data.responseData.map(item => ({
        value: item.id,
        label: item.nombre,
      })) || [];
      // const data = [{ key: 1, value: "Inconsistencia" }, { key: 2, value: "Boletinado injustificado" }];

      setmotivoItems(motivos);
      // motivoItems(data);
    } catch (error) {
      console.error('Error al cargar motivos:', error);
    }
  };

  // useEffect para llamar a HandleGetMotivos cuando el resultado cambie
  useEffect(() => {
    if (resultado === '2') { // Si se selecciona "Rechazar"
      HandleGetMotivos(); // Carga los motivos
    } else {
      setmotivoItems([]); // Limpiamos los motivos si no es "Rechazar"
    }
  }, [resultado]); // Se ejecuta cuando cambia el valor de resultado

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={{
          resultado: '', // Valor inicial de 'resultado'
          motivo: '', // Agrega motivo al initialValues
          message: '' // Agrega message al initialValues
        }}
        onSubmit={(values) => {
          handlerGuardar(values); // Envia los datos al servicio al confirmar
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Modal isOpen={isOpen} color='#fff' width={500}>
            <div>
              <div className='justify-content-start p-2 align-items-center'>
                <div className="row">
                  <div className="col col-12 dias-festivos-modal-underline">
                    <div>
                      <h4><strong>Autorizacion modal</strong></h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-6 mt-4">
                    <SelectField
                      id="resultado"
                      label="Resultado"
                      name="resultado"
                      items={[
                        { label: "Aprobar", value: "1" },
                        { label: "Rechazar", value: "2" }
                      ]}
                      onChange={(option) => {
                        setResultado(option.value); // Actualiza el estado de resultado
                        setFieldValue("resultado", option.value);
                      }}
                    />
                  </div>
                  {resultado === "2" && (
                    <div className="col-lg-6 col-6 mt-4">
                      <SelectField
                        id="motivo"
                        label="Motivo"
                        name="motivo"
                        items={motivoItems.map(c => ({ label: c.label, value: c.value }))} // Items cargados desde el servicio
                        onChange={(option) => {
                          setmotivoText(option.label);
                          setFieldValue("motivo", option.value); // Actualiza el valor del Formik
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-12 mt-4" style={{ display: 'flex', flexFlow: 'column' }}>
                  <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribir" />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnCloseModal"
                      className='btn btn-outline btn-block'
                      onClick={handleRechazar}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                    <button
                      id="AT_BtnConfirmModal"
                      className='btn btn-blue btn-block'
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AutorizacionBoletinadoModal;
