import React from 'react';
import Spinner from '../../../Components/Loadig';
import Modal from "../../../Components/Modal/Modal";
//import ErrorModal from '../../../Components/Modal/ErrorModal';
//import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef, useEffect } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux';
import { SelectField } from '../../../Components/SelectField';
import RegistrarZonaCobranzaModal from './RegistrarZonaCobranzaModal';
import useAreasCobranza from '../../../Hooks/Cobranza/useAreasCobranza';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../Utils/functions';

const AreasCobradores = () => {
    const refFormik = useRef();
    const dispatch = useDispatch();

    const {
        columns,
        data,
        currenPage,
        pageCount,
        loading,
        item,
        estados,
        currentState,
        setCurentState,
        selectedState,
        setselectedState,
        isOpenModalRegistrarZona,
        setIsOpenModalRegistrarZona,
        isOpenModalAsignarCP,
        setIsOpenModalAsignarCP,
        handleEdit,
        handleGetAll,
        dataUpdated,
        setDataUpdated,
        setItem
    } = useAreasCobranza();

    const handleClickSelectedState = (label, value) => {
        setCurentState(label);
        setselectedState(value);
        setItem({
            "item": {
                "estado": label
            }
        });
        handleGetAll(label);
    }

    useEffect(() => {
        if (!isOpenModalRegistrarZona && dataUpdated) {
            handleGetAll(currentState);
            setDataUpdated(false);
        }
        if (!isOpenModalRegistrarZona) {
            setItem(null);
        }
    }, [isOpenModalRegistrarZona, dataUpdated]);

    return (
        <>
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <section className='mx-3 py-4 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className='fs-5'><strong>Zonas de cobranza</strong></span>
                            </div>
                            <span className='mt-2'>
                                Selecciona el estado para acceder a la configuración de las áreas de cobranza.
                            </span>
                        </section>
                        <div className="mx-4 my-2">
                            <div className="col-12 col-lg-2">
                                <SelectField id="estadoSeleccionado" label="Estado" name="estadoSeleccionado" items={estados}
                                    onChange={(event) => {
                                        handleClickSelectedState(event.label, event.value);
                                    }}
                                />
                            </div>
                        </div>
                        <section className='mx-3 py-4' style={{ width: '900px' }}>
                            {
                                (selectedState !== "" && selectedState !== 0) && (
                                    <>
                                        <div className='row' style={{ height: '50px' }}>
                                            <div className='col-7' style={{ alignContent: 'center' }}>
                                                <span className='fs-5'><strong>Zonas de cobranza en: <span style={{ fontWeight: 900, color: "#000", textTransform: 'capitalize' }}>{currentState}</span></strong></span>
                                            </div>
                                            <div className='col-5 mw-100'>
                                                <button className='btn' style={{ width: '100%', height: '100%' }} onClick={() => {
                                                    setIsOpenModalRegistrarZona(true)
                                                    setItem({
                                                        "item": {
                                                            "estado": currentState
                                                        }
                                                    });
                                                }}>Registrar zona</button>
                                            </div>
                                        </div>
                                        {/* <ValidatePermission
                                isActive={ValidatePermissionById(4, Section., SubModule.).Ver}
                            > */}
                                        <div className='mx-8 table-responsive'>
                                            <DataTable
                                                column={columns}
                                                data={data}
                                                pageCount={pageCount}
                                                detailable={true}
                                                handleDetail={handleEdit}
                                                currentPage={currenPage - 1}
                                            //actions={ValidatePermissionById(4, Section.AreasCobradores, SubModule.Cobradores).Actualizar}
                                            />
                                        </div>
                                        {/* </ValidatePermission> */}
                                    </>)
                            }
                            {
                                isOpenModalRegistrarZona && (
                                    <>
                                        <RegistrarZonaCobranzaModal
                                            isOpen={isOpenModalRegistrarZona}
                                            setIsOpen={setIsOpenModalRegistrarZona}
                                            item={item}
                                            setDataUpdated={setDataUpdated}
                                            setReasignarColonias={false}
                                        />
                                    </>)
                            }
                            {
                                isOpenModalAsignarCP && (
                                    <>
                                        <RegistrarZonaCobranzaModal
                                            isOpen={isOpenModalAsignarCP}
                                            setIsOpen={setIsOpenModalAsignarCP}
                                            item={item}
                                            setDataUpdated={setDataUpdated}
                                            setReasignarColonias={true}
                                        />
                                    </>)
                            }
                        </section>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default AreasCobradores;
