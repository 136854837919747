import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../Auth/Authorization';
import SeguimientoTelefonicoDetalleHook from "../../../Hooks/SeguimientoTelefonico/SeguimientoTelefonicoDetalleHook"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard"
import ContratoInfoCard from "../../../Components/ContratoInfoCard"
import CarteraInfoCard from "../../../Components/CarteraInfoCard"
import Accordion from "../../../Components/TitleAcordeon"
import DataTable from "../../../Components/datatable/DataTable"
import TablaAnidada from "../../../Components/TablaAnidada"
import Spinner from "../../../Components/Loadig"
import Modal from "../../../Components/Modal/Modal"
import RegistrarReferenciaModal from "../../../Components/Modal/RegistrarReferencia"
import DatosPromesaPagoModal from "../../../Components/Modal/DatosPromesaPagoModal"
import EliminarPromesaPagoModal from "../../../Components/Modal/EliminarPromesaPagoModal"
import DireccionesContactoModal from "../../../Components/Modal/DireccionesContactoModal";
import AgregarDatosContactoModal from "../../../Components/Modal/AgregarDatosContactoModal";
import RegistroLlamadaModal from "../../../Components/Modal/RegistroLlamadaModal";
import VisorFile from "../../../Components/VisorFile";

const SeguimientoCobranzaDetalle = () => {

    const { state } = useLocation();

    const {
        handleReturnPage,
        columnsPromesas,
        columnsSeguimientoLog,
        columnsInformacionContacto,
        columnsSubInformacionContacto,

        currentDataPromesas,
        currentDataSeguimiento,
        currentDataSeguimientoLog,
        userDetail,
        promesasRotas,
        promesasRotasTotal,

        loading,

        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,

        handleModalRef,
        handleModalAdd,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleDireccionesModal,
        handleRegistroLlamada,

        motivosVisita,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,
        infoContactoData,
        motivoPromesa,
        motivoIngresoCartera,
        tipoOperacion,
        tipoOperacionResultado,
        tipoResultado,
        promesasStatus,
        motivoEliminacion,

        directorioValue, 
        setDirectorioValue,
        handleSetStateTab,

        handleGetPromesasdePago,
        HandleGetInformacionContactos,
        handleContactosSeguimientoLog,

        handleUploadFile,
        handleViewdocs,
        isOpenDocs , 
        setIsOpenDocs,
        fileName,
        SelectedDocs,
    } = SeguimientoTelefonicoDetalleHook(state)

    // const [directorioValue, setDirectorioValue] = useState("")


    // const handleSetStateTab = (value) => {
    //     setDirectorioValue(value)
    // }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {
                isOpenModalReferencias &&
                <RegistrarReferenciaModal
                    setIsOpen={setisOpenModalReferencias}
                    isOpen={isOpenModalReferencias}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                />
            }
            {
                isOpenModalDireccionContactos &&
                <DireccionesContactoModal
                    data={infoContactoData}
                    isOpen={isOpenModalDireccionContactos}
                    setIsOpen={setIsOpenModalDireccionContactos}

                />
            }
            {
                isOpenModalAgregarContacto &&
                <AgregarDatosContactoModal
                    setIsOpen={setisOpenModalAgregarContacto}
                    isOpen={isOpenModalAgregarContacto}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    infoContactoData={infoContactoData}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalPromesaPagoData &&
                <DatosPromesaPagoModal
                    data={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoData}
                    setIsOpen={setisOpenModalPromesaPagoData}

                />
            }
            {
                isOpenModalPromesaPagoDelete &&
                <EliminarPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoDelete}
                    setIsOpen={setisOpenModalPromesaPagoDelete}
                    state={state}
                    toast={toast}
                    motivoEliminacion={motivoEliminacion}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalRegistroLlamada &&
                <RegistroLlamadaModal
                    setIsOpen={setIsOpenModalRegistroLlamada}
                    isOpen={isOpenModalRegistroLlamada}
                    motivoPromesa={motivoPromesa}
                    motivoIngresoCartera={motivoIngresoCartera}
                    motivosVisita={motivosVisita}
                    tipoOperacion={tipoOperacion}
                    tipoOperacionResultado={tipoResultado}
                    infoContactoData={infoContactoData}
                    promesasStatus={promesasStatus}
                    user={userDetail}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                    GetDataPromesa={handleGetPromesasdePago}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
                            {
                    isOpenDocs &&
                    <VisorFile
                        isOpen={isOpenDocs}
                        setIsOIpen={setIsOpenDocs}
                        fileName={fileName}
                        doc={SelectedDocs}
                    />
                }
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <header className='heder-subtitle d-flex justify-content-between align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                    <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2" ></i>
                        Regresar a listado de seguimiento de cobranza
                    </div>
                    {promesasRotas >= promesasRotasTotal && <div className="error"> <i className='bx bxs-error'> </i> Politica Aval, favor de contactar aval</div>}
                </header>
                <div className="col col-11 d-flex">
                    {/* <div className="row mt-1"> */}
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard
                            VIN={currentDataSeguimiento.VIN}
                        />
                    </div>
                    <div className="col col-6 px-4 py-2">
                        <ContratoInfoCard
                            contrato={currentDataSeguimiento} />
                    </div>
                </div>
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard
                        contrato={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas} />
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Promesa de pago">
                            <p className="text-base"><strong>Detalle de promesa</strong></p>
                            <DataTable
                                column={columnsPromesas}
                                data={currentDataPromesas}
                                detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                                handleDetail={handlePromesasPago}
                                actionColumns
                                deleteableColumn
                                handleDelete={handlePromesasPagoDelete}
                            />
                        </Accordion>
                    </div>
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2 wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Directorio de Contactos">
                            <ul className="nav nav-tabs text-sm" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Cliente" && "tab-active"}`} value={'Cliente'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal}>Cliente</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Aval" && "tab-active"}`} value={'Aval'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} >Aval</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Coacredito" && "tab-active"}`} value={'Coacredito'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Coacredito</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Representante Legal" && "tab-active"}`} value={'Representante Legal'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Representante Legal</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Referencia" && "tab-active"}`} value={'Referencia'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Referencias</button>
                                </li>
                                <div className="container mt-2">
                                </div>
                            </ul>
                            <div>
                                <div className="d-flex align-items-center my-2">
                                    <span className="mx-2"><strong>Datos de contacto</strong></span>
                                    {directorioValue === 'Referencia' && (
                                        <button
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={handleModalRef}
                                        >
                                            Agregar Referencias
                                        </button>
                                    )}
                                </div>
                                <TablaAnidada
                                    state={state}
                                    data={infoContactosFiltered}
                                    column={columnsInformacionContacto}
                                    addColumns={2}
                                    buttonText={"Aceptar"}
                                    handleClick={handleModalAdd}
                                    moreDirections={true}
                                    handleMoreDirections={handleDireccionesModal}
                                    handleRegistro={handleRegistroLlamada}
                                    childColumn={columnsSubInformacionContacto}
                                    // childData={currentDataPromesas}
                                    childCommentColumn={true}
                                    childActionColumns={true}
                                    childRegistroColumn={true}
                                >
                                    <DataTable/>
                                </TablaAnidada>
                            </div>
                        </Accordion>
                    </div>
                    <div>
                        <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                            <Accordion title="Seguimiento de contactos">
                                <p className="text-base"><strong>Seguimiento telefónico</strong></p>
                                <DataTable
                                    column={columnsSeguimientoLog}
                                    data={currentDataSeguimientoLog}
                                    commentColumn={true}
                                    handleDocuments={handleViewdocs}
                                    handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                />
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SeguimientoCobranzaDetalle