import React, { useEffect, useRef, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';

const auth = new AuthLogin();
const CardLiberacionDeUnidad = ({ generalId }) => {
    const { refFormik } = useRef();

    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);
    const [boolSelect, setBoolSelect] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);

    let initial = {
    }

    const [initialValue, setInitialValue] = useState(initial);

    useEffect(() => {
    }, []);

    const dummy = {
        dias: 100
    }



    /* CONSULTAS */
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    /* TERMINA CATALOGOS */


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseProcesoDatamovil" role="button" aria-expanded="false" aria-controls="collapseProcesoDatamovil" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Liberacion de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseProcesoDatamovil">
                                <div className="separator"></div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={initialValue}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    <h6>
                                                        <strong>Ubicación de Unidad</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <button
                                                                className='btn'
                                                                disabled={initialValue?.concluido}
                                                                type='submit'
                                                            >
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-4">
                                                    <TextField label="Tipo de ubicacion" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Nombre de ubicacion" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Total adeudo" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField label="Calle y numero" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Codigo postal" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Pais" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Estado" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Municipio" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Colonia"
                                                        name="requiereCambioUbicacion"
                                                        items={[]}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereCambioUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Se requiere Grua?"
                                                        name="requiereCambioUbicacion"
                                                        items={[]}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereCambioUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    Subir documento
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField
                                                        label="Comentarios"
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentariosAprobacion"
                                                        id="comentariosAprobacion"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Oficio de liberacion registrado en sistema</label>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-4">
                                                    <TextField label="Unidad en Corralon?" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Nombre del corralon" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField label="Calle y numero" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Codigo postal" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Pais" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Estado" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Municipio" name="ubicacionActual" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("ubicacionActual", event.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Colonia"
                                                        name="requiereCambioUbicacion"
                                                        items={[]}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereCambioUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad liberada</label>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default CardLiberacionDeUnidad