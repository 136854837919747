import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import { TextField } from "../../../../Components/TextField";
import Llamada from "../../../../Services/Cartera/Llamadas";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import toast from "react-hot-toast";
import { message } from "../../../../Utils/functions";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { useSelector } from "react-redux";

const DireccionesAgregar = () => {
   const navigate = useNavigate();
   // const { state } = useLocation();
   const [fechaInicio, setFechaInicio] = useState("21-09-2023 09:00")
   const [fechaFin, setFechaFin] = useState("21-09-2023 09:00")
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [dataColonia, setDataColonia] = useState([]);
   const [dataEstado, setDataEstado] = useState([]);
   const [loading, setLoading] = useState(false);

   let initial = {
      calle: "",
      noExterior: "",
      noInterior: "",
      codigoPostal: "",
      estado: "",
      colonia: "",
      municipio: "",
      message:""
   }

   const [initialValue, setInitialValue] = useState(initial); 

   const HandleGetEstadoMx = async () => {
      await Llamada.GetEstadoMx().then(res => {
         let Estados = []
         res.data.data?.map(item => {
            Estados.push({
                  value: item.edoMexicoId,
                  label: item.estado
            });
         });
         setDataEstado(Estados);
      })
   }

   const HandleGetMunicpio = async (id) => {
      setDataMunicipio([]);
      await Llamada.GetMunicipio(id).then(res => {
         let Municipios = []
         res.data?.data?.map(item => {
            Municipios.push({
                  value: item.municipioId,
                  label: item.municipio
            });
         });
         setDataMunicipio(Municipios);
      })
   }
   const HandleGetColonia = async (id) => {
      setDataColonia([])
      await Llamada.GetColonia(id).then(res => {
         let Colonias = []
         res.data?.data?.map(item => {
            Colonias.push({
                  value: item.coloniaId,
                  label: item.colonia
            });
         });
         setDataColonia(Colonias);
      })
   }

   useEffect(()=>{
      HandleGetEstadoMx();
   },[])

   const validate = Yup.object({
      calle: Yup.string()
         .required("El campo es requerido."),
      noExterior: Yup.string()
         .required("El campo es requerido."),
      noInterior: Yup.string()
         .required("El campo es requerido."),
      codigoPostal: Yup.string()
         .required("El campo es requerido."),
      estado: Yup.string()
         .required("El campo es requerido."),
      municipio: Yup.string()
         .required("El campo es requerido."),
      colonia: Yup.string()
         .required("El campo es requerido."),
   });
   const handleAgregarDireccion = async (values) =>{
      console.log(values)
      setLoading(true);
      let data = {
         "anyObject": {
            calle: values.calle,
            numero_exterior: values.noExterior,
            numero_interior: values.noInterior,
            codigo_posta: values.codigoPostal,
            colonia_id: values.colonia,
            municipio_id: values.municipio,
            estado_id: values.estado,
            pais_id: "México",
            es_principal: 0,
            comentarios: values.message,
            referencia_ascendes_id: datosConsulta.persona.value
         }
      }

      await CobranzaService.createContactoReferenciaDireccion(data)
         .then(res => {
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            setLoading(false)
            navigate(-1)
         }).catch(e => {
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                              <h2 className="fw-bold ">Agregar direcciones</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                              <div className="row mt-4">
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-11 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
               <Formik
                  enableReinitialize={true}
                  validationSchema={validate}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                     handleAgregarDireccion(values);
               }}>
               {({ isValid, values, setFieldValue, errors }) => (
                  <>
                     <Form>
                        <div className="px-4">
                           <div className="row mt-2 px-4">
                              <div className="col-2 mt-3">
                                 <p><span style={{fontSize: '20px', fontWeight: 'bold'}}>Registro de direcciones</span></p>
                              </div>
                              <div className="col-7 mt-3">
                                 <div className="row justify-content-end">
                                    <div className="col-auto">
                                       <button className='btn col-12' type='submit' disabled={!(isValid)} >Continuar</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="mb-3 row px-4" style={{marginTop:30}}>
                              <div className="col-2">
                                 <TextField label="Calle" min={0} name="calle" value={values.calle} type="text" holder="Escribe"
                                    onChange={(event) => {setFieldValue("calle", event.target.value);}} />
                              </div>
                              <div className="col-2">
                                 <TextField label="Número exterio" min={0} name="noExterior" value={values.noExterior} type="text" holder="Escribe"
                                    onChange={(event) => {setFieldValue("noExterior", event.target.value);}} />
                              </div>
                              <div className="col-2">
                                 <TextField label="Número interios" min={0} name="noInterior" value={values.noInterior} type="text" holder="Escribe"
                                    onChange={(event) => {setFieldValue("noInterior", event.target.value);}} />
                              </div>
                              <div className="col-2">
                                 <TextField label="Código postal" min={0} name="codigoPostal" value={values.codigoPostal} type="text" holder="Escribe"
                                    onChange={(event) => {setFieldValue("codigoPostal", event.target.value);}} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4" style={{marginTop:30}}>
                              <div className="col-2">
                                 <SelectField  label="Estado" name="estado" items={dataEstado} onChange={(e) => {
                                    setFieldValue("estado", e.value != 0 ? e.value : "");
                                    HandleGetMunicpio(e.value);
                                 }} />
                              </div>
                              <div className="col-2">
                                 <SelectField  label="Municipio" name="municipio" items={dataMunicipio} onChange={(e) => {
                                    setFieldValue("municipio", e.value != 0 ? e.value : "");
                                    HandleGetColonia(e.value);
                                 }} />
                              </div>
                              <div className="col-2">
                                 <SelectField  label="Colonia" name="colonia" items={dataColonia} onChange={(e) => {
                                    setFieldValue("colonia", e.value != 0 ? e.value : "");
                                 }} />
                              </div>
                              <div className="row">
                                 <div className="col-lg-6 col-6" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribe" />
                                 </div>
                              </div>
                           </div>
                           <div className="row mb-3 px-4">
                              <div className="col-3 mb-3">
                                 <span className="text-sm">Inicio de proceso: {fechaInicio} </span>
                              </div>
                              <div className="col-3">
                                 <span className="text-sm">Fin de proceso: {fechaFin} </span>
                              </div>
                           </div>
                        </div>
                     </Form>
                  </>)}
               </Formik>
            </div>
         </div>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      </>
   );
}

export default DireccionesAgregar;