import React from 'react'
import SiniestroDetalle from '../Detalle/SiniestroDetalle'
import useSiniestroValuacion from '../../../../Hooks/Inventario/Siniestro/useSiniestroValuacion';
import { toast, Toaster } from 'react-hot-toast';
import ContratoHeaderCard from '../ContratoHeaderCard';
import VehiculoInfoCard from '../../../../Components/VehiculoInfoCard';
import InformacionSeguroCard from '../InformacionSeguroCard';
import SegumientoProcesoDatamovil from '../Detalle/SeguimientoProcesoDatamovil';
import ModalConfirm from '../../../Inventario/SeguimientoDeValuacion/ModalConfirm';
import CierreSiniestroReparacionCard from './CierreSiniestroReparacionCard';
import { Normalize } from '../../../../Utils/functions';
import CierreSiniestroPerdidaTotalCard from './CierreSiniestroPerdidaTotalCard';
import CierreSiniestroAseguradoraCard from './CierreSiniestroAseguradoraCard';

const CierreSiniestro = () => {
    const {
        stepe,
        handleClickTerminar,
        activebtncontinuar,
        loading,
        handleChangeStatusBtn,
        modalConfirm,
        setModalConfirm,
        handleClickTerminarProceso,
        state
    } = useSiniestroValuacion(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SiniestroDetalle
                dataStep={stepe}
                handleClickContinuar={handleClickTerminar}
                activeBtn={activebtncontinuar}
            />
            <div className='d-flex flex-column background-grey' style={{ height: '100vh' }}>
                <div className='row mx-md-4 my-4'>
                    <div className="col-6 px-md-3">
                        <VehiculoInfoCard GeneralId={state?.generalId} verClaves={true} />
                    </div>
                    <div className="col-6 px-md-3">
                        <ContratoHeaderCard />
                    </div>
                </div>
                <InformacionSeguroCard generalId={state?.generalId} />
                <SegumientoProcesoDatamovil />
                {
                    Normalize(state?.tipoDictamen) === "perdidatotal" &&
                    <>
                        <CierreSiniestroPerdidaTotalCard
                            handleConcluido={handleChangeStatusBtn}
                        />
                         <CierreSiniestroAseguradoraCard
                            handleConcluido={handleChangeStatusBtn}
                        />
                    </>
                }
                {
                    Normalize(state?.tipoDictamen) === "reparacion" &&
                    <CierreSiniestroReparacionCard
                        handleConcluido={handleChangeStatusBtn}
                    />
                }

                {
                    modalConfirm &&
                    <ModalConfirm
                        isOpen={modalConfirm}
                        setIsOpen={setModalConfirm}
                        title={"Cierre de siniestro"}
                        handleConfirm={handleClickTerminarProceso}
                        procesoId={2}
                    />
                }
            </div>
        </>
    )
}

export default CierreSiniestro