import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef, useEffect } from 'react';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { Field, Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import BuscadorDatamovilModal from '../../../Components/Modal/BuscadorDatamovilModal';
import CobranzaService from '../../../Services/Cobranza/Cobranza';

const AccesoriosSeguimiento = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    let initial = {
        buscar: "",
        codProceso: "",
        codConcepto: "",
        fechaInicio: "",
        fechaFin: "",
        page: 1,
        rows: 10
    }

    let iConcepto = [{
        value: "Cobrar Seguro",
        label: "Cobrar Seguro",
    },{
        value: "Cobrar Refrendo",
        label: "Cobrar Refrendo",
    },{
        value: "Cobrar Deducible",
        label: "Cobrar Deducible",
    },{
        value: "Cobrar Corralon",
        label: "Cobrar Corralon",
    },{
        value: "Cobrar Multa",
        label: "Cobrar Multa",
    },{
        value: "Cobrar Reparaciones/Mantenimiento",
        label: "Cobrar Reparaciones/Mantenimiento",
    },{
        value: "Cobrar Constancia Registro Vehicular",
        label: "Cobrar Constancia Registro Vehicular",
    },{
        value: "Cobrar Cambio de Propietario",
        label: "Cobrar Cambio de Propietario",
    },
    {
        value : "Cobrar Tenencia",
        label : "Cobrar Tenencia"
    },
    {
        value : "Cobrar Replaqueo",
        label : "Cobrar Replaqueo"
    }
]
    let iProceso = [
        {
            value : "Seguro",
            label : "Seguro"
        },
        {
            value : "Gestoría",
            label : "Gestoría"
        },
        {
            value : "Cobranza",
            label : "Cobranza"
        },
        {
            value : "Proceso de Cierre",
            label : "Proceso de Cierre"
        },
        {
            value : "Garantía",
            label : "Garantía"
        },
    ]

    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [options, setOptions] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const [proceso, setProceso] = useState(iProceso);
    const [concepto, setConcepto] = useState(iConcepto);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });

    const columns = [
        { field: 'vin', headerName: 'VIN' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'cliente', headerName: 'Cliente' },
        { field: 'proceso', headerName: 'Proceso' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'valorFinanciado', headerName: 'Costo de accesorio' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'año', headerName: 'Año' },
        { field: 'fechaRegistro', headerName: 'Fecha' },
    ];

    useEffect(() => {
        seguimientoDatamovil();
    }, []);

    const seguimientoDatamovil = async (values = initialValue, page = 1) => {
        values.page = page;
        if (!refFormik.current.values.concluido) {
            values.concluido = false;
        }
        setLoading(true);

        await CobranzaService.seguimientoAccesorios(values)
            .then(resp => {
                let tmpData = [];
                let tabsName = ['Todo'];
                resp.data.forEach(data => {
                    if (!tabsName.includes(data.proceso) && data.proceso !== '') {
                        tabsName.push(data.proceso);
                    }
                    tmpData.push({
                        vin: data.vin,
                        contrato: data.contrato,
                        cliente: data.cliente,
                        proceso: data.proceso,
                        valorFinanciado: formatMoney(data.valorFinanciado),
                        marca: data.nombreMarca,
                        modelo: data.claveModelo,
                        año: data.nombreYear,
                        fechaRegistro: dateFormat(data.fechaFinanciamiento),
                    })
                });
                setTabs(tabsName);
                resp.data.data = tmpData;
                setData(resp.data);
                setDataToShow(resp.data);

                let filter = [];
                tabsName.forEach(tab => {
                    if (tab.toLowerCase() === 'todo') {
                        filter['Todo'] = resp.data.data;
                    } else {
                        if (!filter[tab]) {
                            filter[tab] = resp.data.data.filter((item) => item.proceso === tab);
                        }
                    }
                })
                setTabInfo(filter);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d + "Z");
        let formattedDate = ("0" + dateBD.getDate()).slice(-2) + "-" + ("0" + dateBD.getMonth()).slice(-2) + "-" + dateBD.getFullYear() + " " + ("0" + dateBD.getHours()).slice(-2) + ":" + ("0" + dateBD.getMinutes()).slice(-2) + " hrs.";
        return formattedDate;
    }

    const handleEdit = (item) => {
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));
        // procesocierre/resguardos/resguardo/:id/:resguardo
        navigate(`/procesocierre/datamovil/${item.vin}/${item.generalId}/${item.datamovilId}`);
    }

    const [pagina, setPagina] = useState(1);


    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        seguimientoDatamovil(initialValue, page);
    };

    const getCounter = (tab) => {
        console.log(tabInfo[tab]);
        return tabInfo[tab]?.length || 0;
    };


    const formatMoney = (value) => {
        if (value === undefined) {
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const getReporteExcel = async (values) => {
        await CobranzaService.reporteAccesorios(values).then(res => {
            var FileSaver = require('file-saver');
            FileSaver.saveAs(res.data, `UnidadesConProcesoPagado-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        })
    }

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BuscadorDatamovilModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>

                    <section>
                        {/* <SubmenuCitasAgendadas /> */}
                        <div className='pb-4 pt-2'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Financiamiento de accesorios</strong>
                                        </div>
                                        <div className="col-auto">
                                            <button className='btn' onClick={(e) => { getReporteExcel(values); }}>
                                                Exportar Excel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 pt-5'>
                                    <div className="row">
                                        <div className="col-12 col-md-2">
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <TextField id="fechaIicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaInicio", event.target.value);
                                            }} />
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                setFieldValue("fechaFin", event.target.value);
                                            }} />
                                        </div>

                                        <div className="col-12 col-md-2">
                                            <SelectField
                                                id="codProceso"
                                                label=""
                                                name="codProceso"
                                                items={proceso}
                                                onChange={(event) => {
                                                    setFieldValue("codProceso", event.value);
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <SelectField
                                                id="codConcepto"
                                                label=""
                                                name="codConcepto"
                                                items={concepto}
                                                onChange={(event) => {
                                                    setFieldValue("codConcepto", event.value);
                                                }}
                                            />
                                        </div>
                                        {/* <div className="col-12 col-md-2 mt-3 mr-1">
                                            <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                                setFieldValue("concluido", event.target.value);
                                            }} />
                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Incluir finalizados</label>
                                        </div> */}
                                        <div className="col-12 col-md-auto">
                                            <button className='btn btn-blue' onClick={(e) => seguimientoDatamovil(values)}>
                                                <i className="glyphicon fas fa-search"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {
                                                tabs.map((tab, idx) => {
                                                    return <li className="nav-item" key={'tab_'+idx} role="presentation">
                                                        <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                    </li>
                                                })
                                            }
                                        </ul> */}
                                        {
                                            tabIndex !== 0 && dataToShow.length > 0 &&
                                            <DataTable
                                                column={columns}
                                                data={dataToShow}
                                                editable={true}
                                                handleEdit={handleEdit}
                                            // pageCount={0}
                                            // currentPage={1 - 1}
                                            />
                                        }
                                        {
                                            tabIndex === 0 &&
                                            <DataTable
                                                column={columns}
                                                data={data?.data}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                            // pageCount={0}
                                            // currentPage={1 - 1}
                                            />
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default AccesoriosSeguimiento;
