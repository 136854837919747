import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha } from '../../Utils/functions'

const PromesaPagoHook = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)

  const columns = [
    { field: 'folio_promesa', headerName: 'Folio Promesa' },
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencidos', headerName: 'Días Vencidos' },
    { field: 'fecha_promesa', headerName: 'Fecha Promesa' },
    { field: 'monto_vencido', headerName: 'Monto Vencido' },
    { field: 'monto_promesa', headerName: 'Monto Promesa' },
    { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
    { field: 'monto_recuperado', headerName: 'Monto Cubierto' },
    { field: 'promesa_status', headerName: 'Estatus' },
    { field: 'ultimaActualizacion', headerName: 'Ultima Actualización' },
  ];
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([{}])

  const [currentData, setCurrentData] = useState(data)
  const [promesasStatusSelect, setPromesasStatusSelect] = useState([])
  const [promesasStatus, setPromesasStatus] = useState("")

  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    let filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (promesasStatus === "" || item.promesa_status_nombre === promesasStatus)
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
    // if (currentPage > newTotalPages) {
    //   setCurrentPage(newTotalPages)
    // } else if (currentPage <= 0) {
    //   setCurrentPage(1)
    // }

  }, [currentPage, name, pageRows, data, promesasStatus])

  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: value
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    handleGetPromesasdePago()
    //Select Status
    HandlePromesasStatus()
  }, [])

  const HandlePromesasStatus = async () => {
    await CobranzaService.getAllEstatusPromesa()
      .then(res => {
        let promesa = []
        res.data?.responseData?.map(item => {
          if (item.activo) {
            promesa.push({
              value: item.id,
              label: item.nombre
            });
          }
        });
        setPromesasStatusSelect(promesa);
      })
  }


  const handleGetPromesasdePago = async () => {
    setLoading(true);
    await CobranzaService.getAllPromesasDePago()
      .then(res => {

        const update = res.data.map(item => {

          let colorFondo
          let colorTexto

          switch (item.promesa_status_nombre) {
            case "Cumplida":
              colorFondo = "#D8F2CD"
              colorTexto = "#47A066"
              break;
            case "Rota":
              colorFondo = "#F5CECE"
              colorTexto = "#CE2222"
              break;
            case "Vigente":
              colorFondo = "#FAE7BC"
              colorTexto = "#B46B16"
              break;
            default:
              break;
          }

          const fechaPromesa = FormatFecha(item.fecha_promesa)


          return {
            ...item,
            promesa_status: item.promesa_status_nombre,
            colorFondo: colorFondo,
            colorTexto: colorTexto,
            fecha_promesa: fechaPromesa
          }
        })



        setData(update)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    promesasStatusSelect,
    setPromesasStatus
  }
}
export default PromesaPagoHook