import React from 'react'
import Select from 'react-select'
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../Components/datatable/DataTable';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import Modal from '../../Components/Modal/Modal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import SubMenuParametrosCartera from '../../Components/SubMenuParametrosCartera';
// import AcendesCus from '../../Hooks/Parametros/Acendes';
// import TenenciaModal from './ParametrosModal';
import { pageRows } from '../../Utils/functions';
// import RowPages from '../../Components/RowPages';
import ParametrosCarteraHooks from '../../Hooks/ParametrosCartera/ParametrosCarteraHooks';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import Accordion from '../../Components/TitleAcordeon';


const ParametrosCartera = () => {
    let {
        columns,
        setStrFiltro,
        dataParametroSelect,
        setParametroSelect,
        dataCategorias,
        dataAllParametrosCartera
    } = ParametrosCarteraHooks();

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);

    const handleEdit = (item) => {
        console.log(item);
        setIsOpenEditModal(true)
    }

    useEffect(() => {

        // Hacer Proceso para  isOpenEditModal

    }, [isOpenEditModal])


    return (
        <>
            <SubMenuParametrosCartera />

            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Parámetros cartera</strong></span>
                </div>

                <span className='mt-2'>A continuación, se presentan los parametros agrupados por proceso</span>
            </section>

            <section className='mt-3 mx-4'>
                <div className='col-lg-12 col-sm-12 d-flex'>
                    <div className="col-4 mx-1  inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStrFiltro(e.target.value) }} />
                    </div>
                    <div className='col-4'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <Select options={dataParametroSelect} className='col-12'
                                // onChange={(e) => { setParametroSelect(e.value) }}
                                placeholder="Párametro" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-3 mx-4'>
                {
                    dataCategorias.map((item) => {

                        // handleGetParametrosByCategoria(item);

                        let filteredData = [];
                        filteredData = dataAllParametrosCartera.filter((data) => data.categoria === item);

                        return (
                            <>
                                <div className='col-lg-12 col-sm-12'>

                                    <Accordion title={item}>
                                        <DataTable
                                            column={columns}
                                            data={filteredData}
                                            editable={true}
                                            handleEdit={handleEdit}
                                        />
                                    </Accordion>
                                </div>

                            </>
                        )

                    })
                }
            </section>



        </>
    )

}


export default ParametrosCartera