import '../App.css';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import Select from 'react-select'

export const SelectField = ({ label, disabled, required, items, handleChange, placeholder, ...props }) => {
    const [data, setData] = useState([])
    const [field, meta] = useField(props);
    useEffect(() => {
        setData([]);
        let newitems = items.filter((item) => item.label !== 'Seleccione');
        newitems.unshift({
            value: "",
            label: "Seleccione"
        })

        setData(newitems);
    }, [items])


    return (
        <div className="mb-2">
            {
            label && <label className='text' htmlFor={field.name}>{label} {required ? <span className='text-danger'>*</span> : ""}</label>
            }
            <Select id='select-control' options={data}
            defaultValue={{
                value: "",
                label: placeholder || "Seleccione" 
            }} 
                className={`${ meta.error && 'is-invalid'}`} {...props}
                value={data ? data.find(option => option.value === field.value) : ""}
                isDisabled={disabled}
            />
            {meta.error ? (
                <span className="error">{meta.error}</span>
            ) : null}
        </div>
    )
}
