import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../../../Components/TextAreaField';
import handlePhonNumberFormatter, { getDateFormat, message, pageRows } from '../../../../../Utils/functions'
import Modal from '../../../../../Components/Modal/Modal';
import Spinner from '../../../../../Components/Loadig';
import ErrorModal from '../../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../../../Components/SelectField';
import { TextField } from '../../../../../Components/TextField';
import Siniestros from '../../../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../../../Services/Catalogos/Catalogo';

const RecoleccionUnidadCard = ({ handlerDatadocs, handlerCostos, handlerCalendario, step }) => {
    const { refFormik } = useRef();
    const { id, generalid, siniestroid } = useParams();
    const navigate = useNavigate();
    const { fechaEntrega,
        nombreYear,
        vin,
        color,
        nombreMarca,
        kilometraje,
        nombreModelo,
        procedenciaAuto,
        nombreVersion,
        numContrato,
        numSolicitud } = useSelector((state) => state.DataVinSlice);
    let initial = {
        tipoUbicacionOrigen: 2,
        TipoUbicacionDestino: 0,
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);
    const [UbicacionOrigen, setUbicacionOrigen] = useState(0);
    const [UbicacionDestino, setUbicacionDestino] = useState(0);

    useEffect(() => {
    }, []);


    /* CONSULTAS */
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const TipoUbicaciones = [{ value: 1, label: 'Interno' }, { value: 2, label: 'Externo' }];
    /* TERMINA CATALOGOS */

    const formatMoney = (value) => {
        if (value === undefined) {
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    const validate = Yup.object().shape({});
    const handleUploadFile = () => {
        // navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${siniestroid}`, {
        //     state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }

        // });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <>
                                        <Form>
                                            <div className="row mt-2 px-4 justify-content-between">
                                                <div className="col-6">
                                                    <h6><strong>Recolección de Unidad </strong></h6>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <fieldset onClick={handleUploadFile} className='btn btn-gray'>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-2" style={{ 'textAlign': 'right' }}>
                                                            <button
                                                                className='btn px-4'
                                                                type="submit"
                                                                disabled={!(isValid)}>
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="reportaNombre"
                                                            label="Folio"
                                                            holder=""
                                                            name="reportaNombre"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("reportaNombre", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoMovil" label="Usuario Programo"
                                                            holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                                setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="unidadDetenida"
                                                            label="Grúa"
                                                            name="unidadDetenida"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadDetenida", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="unidadDetenida"
                                                            label="Empresa"
                                                            name="unidadDetenida"
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadDetenida", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="reportaNombre"
                                                            label="Grúa asignada"
                                                            holder=""
                                                            name="reportaNombre"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("reportaNombre", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <TextField id="telefonoMovil" label="Costo para cliente"
                                                                    holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                                        setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                                    }} />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <button className='btn mt-4'>
                                                                    Registrar costo
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Indicar ubicación de origen</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="tipoUbicacionOrigen"
                                                            label="Tipo de ubicación"
                                                            name="tipoUbicacionOrigen"
                                                            items={TipoUbicaciones}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoUbicacionOrigen", event.value);
                                                            }} />
                                                    </div>
                                                    {
                                                        values.tipoUbicacionOrigen === 1 &&
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="siniestroTipoId"
                                                                label="Ubicación"
                                                                name="siniestroTipoId"
                                                                items={dataTipoSiniestro}
                                                                onChange={(event) => {
                                                                    setFieldValue("siniestroTipoId", event.value);
                                                                }} />
                                                        </div>
                                                    }
                                                    {
                                                        values.tipoUbicacionOrigen === 2 &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="folioAseguradora"
                                                                label="Nombre ubicación"
                                                                holder="Escribe"
                                                                name="folioAseguradora"
                                                                type="text"
                                                                onChange={(event) => {
                                                                    setFieldValue("folioAseguradora", event.target.value);
                                                                }} />
                                                        </div>
                                                    }
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            label="Fecha de recolección"
                                                            name="folioAseguradora"
                                                            type="date"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            label="Hora de recolección"
                                                            name="folioAseguradora"
                                                            type="time"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                {
                                                    values.tipoUbicacionOrigen === 2 &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="siniestroTipoId"
                                                                    label="Estado"
                                                                    name="siniestroTipoId"
                                                                    items={dataTipoSiniestro}
                                                                    onChange={(event) => {
                                                                        setFieldValue("siniestroTipoId", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="cuentaFolioAseguradora"
                                                                    label="Municipio"
                                                                    name="cuentaFolioAseguradora"

                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("cuentaFolioAseguradora", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="cuentaFolioAseguradora"
                                                                    label="Colonia"
                                                                    name="cuentaFolioAseguradora"

                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("cuentaFolioAseguradora", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="folioAseguradora"
                                                                    label="Calle"
                                                                    holder="Escribe"
                                                                    name="folioAseguradora"
                                                                    type="text"
                                                                    onChange={(event) => {
                                                                        setFieldValue("folioAseguradora", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="folioAseguradora"
                                                                    label="Número"
                                                                    holder="Escribe"
                                                                    name="folioAseguradora"
                                                                    type="text"
                                                                    onChange={(event) => {
                                                                        setFieldValue("folioAseguradora", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-6">
                                                                <TextAreaField id="comentarios"
                                                                    label="Comentarios del siniestro"
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentarios"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentarios", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Indicar ubicación destino</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="tipoUbicacionOrigen"
                                                            label="Tipo de ubicación"
                                                            name="tipoUbicacionOrigen"
                                                            items={TipoUbicaciones}
                                                            onChange={(event) => {
                                                                setFieldValue("tipoUbicacionOrigen", event.value);
                                                            }} />
                                                    </div>
                                                    {
                                                        values.tipoUbicacionOrigen === 1 &&
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="siniestroTipoId"
                                                                label="Ubicación"
                                                                name="siniestroTipoId"
                                                                items={dataTipoSiniestro}
                                                                onChange={(event) => {
                                                                    setFieldValue("siniestroTipoId", event.value);
                                                                }} />
                                                        </div>
                                                    }
                                                    {
                                                        values.tipoUbicacionOrigen === 2 &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="folioAseguradora"
                                                                label="Nombre ubicación"
                                                                holder="Escribe"
                                                                name="folioAseguradora"
                                                                type="text"
                                                                onChange={(event) => {
                                                                    setFieldValue("folioAseguradora", event.target.value);
                                                                }} />
                                                        </div>
                                                    }
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            label="Fecha de recolección"
                                                            name="folioAseguradora"
                                                            type="date"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            label="Hora de recolección"
                                                            name="folioAseguradora"
                                                            type="time"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                {
                                                    values.tipoUbicacionOrigen === 2 &&
                                                    <>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="folioAseguradora"
                                                                    label="Calle y número"
                                                                    holder="Escribe"
                                                                    name="folioAseguradora"
                                                                    type="text"
                                                                    onChange={(event) => {
                                                                        setFieldValue("folioAseguradora", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <TextField id="folioAseguradora"
                                                                    label="Código postal"
                                                                    holder="Escribe"
                                                                    name="folioAseguradora"
                                                                    type="text"
                                                                    onChange={(event) => {
                                                                        setFieldValue("folioAseguradora", event.target.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="siniestroTipoId"
                                                                    label="Estado"
                                                                    name="siniestroTipoId"
                                                                    items={dataTipoSiniestro}
                                                                    onChange={(event) => {
                                                                        setFieldValue("siniestroTipoId", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="cuentaFolioAseguradora"
                                                                    label="Municipio"
                                                                    name="cuentaFolioAseguradora"

                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("cuentaFolioAseguradora", event.value);
                                                                    }} />
                                                            </div>
                                                            <div className="col-12 col-lg-3">
                                                                <SelectField id="cuentaFolioAseguradora"
                                                                    label="Colonia"
                                                                    name="cuentaFolioAseguradora"

                                                                    items={opcionesBool}
                                                                    onChange={(event) => {
                                                                        setFieldValue("cuentaFolioAseguradora", event.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-4">
                                                            <div className="col-12 col-lg-6">
                                                                <TextAreaField id="comentarios"
                                                                    label="Comentarios del siniestro"
                                                                    className="form-control col-12"
                                                                    rows="3"
                                                                    name="comentarios"
                                                                    type="text"
                                                                    holder="Escribe"
                                                                    onChange={(event) => {
                                                                        setFieldValue("comentarios", event.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grúa programada</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RecoleccionUnidadCard