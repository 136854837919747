import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   usuario: {
      userid: "",
      nombrecobrador: "",
      usuariosistema: "",
      nivel: "",
      telefono: "",
      status: false,
      estatusdesistema: "",
      data: []
   }
}
export const UsuarioSeleccionadoSlice = createSlice({
   name: 'UsuarioSeleccionadoSlice',
   initialState,
   reducers: {
      setUsuario: (state, action) => {
         return { ...state, usuario: action.payload }
      },
      setLimpiarUsuario: (state, action) => {
         return { ...state,
            usuario: {
               userid: "",
               nombrecobrador: "",
               usuariosistema: "",
               nivel: "",
               telefono: "",
               status: false,
               estatusdesistema: "",
               data: []
            }
         }
      }
   },
})

export const { setUsuario, setLimpiarUsuario } = UsuarioSeleccionadoSlice.actions

export default UsuarioSeleccionadoSlice.reducer