import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import { FormatFecha } from '../../Utils/functions'
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import CobranzaService from '../../Services/Cobranza/Cobranza';

const useCarteraGeneral = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'contrato_id', headerName: 'Contrato', width: 150 },
        { field: 'zona', headerName: 'Zona', width: 150 },
        { field: 'periodo', headerName: 'Periodo', width: 150 },
        { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
        { field: 'monto_adeudo', headerName: 'Monto insoluto', width: 150 },
        { field: 'monto_adeudo', headerName: 'Monto adeudo', width: 150 },
        { field: 'fecha_ultimo_pago', headerName: 'Fecha últ. pago', width: 150 },
        { field: 'fecha_ultima_clave', headerName: 'Fecha ultima clave', width: 150 },
        { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar', width: 150 },
        { field: 'visitas', headerName: 'Visitas', width: 150 },
        { field: 'fecha_ultimo_calculo', headerName: 'Últ. Actualización', width: 150 },
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [zonaSeleccionada, setZonaSeleccionada] = useState("")
    //Filtro
    const [data, setData] = useState([{}]);
    const [pageRows, setpageRows] = useState(10);
    const [currentData, setCurrentData] = useState(data);
    const userId = sessionStorage.getItem('userId').trim();
    const [rangoFechas, setRangoFechas] = useState();
    const [percentage, setPercentage] = useState(0)

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    const [firstProgressVigente, setFirstProgressVigente] = useState(0)
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState(0)
    const [secondProgressVigentes, setSecondProgressVigentes] = useState(0)
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState(0)

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState()
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)
    const dropdownRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")
        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {

        // Función para obtener la fecha límite
        const obtenerFechaLimite = () => {
            const hoy = new Date();
            const fechaLimite = new Date(hoy);
            fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
            return fechaLimite;
        };

        const fechaLimite = obtenerFechaLimite();

        // Filtrar datos según la fecha
        const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
        const total = datosFiltrados.length;

        // Suma total de dinero
        const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
        const sumaTotalDinero = activos.length > 0 ?
            activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        // Número de IDs diferentes
        const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2)
        };
    }

    useEffect(() => {
        habdleGetAll();
        // handleGetZonas();
        handleGetPromesasdePago();
    }, [])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(x => {
            return (textoBusqueda === '' || (x.contrato_id && x.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()))) &&
                (zonaSeleccionada === 'Todos' || (x.zona && x.zona.toLowerCase().includes(zonaSeleccionada.toLowerCase())));
        });

        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
        // if (currentPage > newTotalPages) {
        //     setCurrentPage(newTotalPages)
        // } else if (currentPage <= 0) {
        //     setCurrentPage(1)
        // }

        // Solo actualiza currentPage si es necesario para evitar bucles infinitos
        if (currentPage > newTotalPages && currentPage !== newTotalPages) {
            setCurrentPage(newTotalPages);
        } else if (currentPage <= 0 && currentPage !== 1) {
            setCurrentPage(1);
        }

    }, [currentPage, textoBusqueda, pageRows, zonaSeleccionada])

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])

    const habdleGetAll = async () => {
        setLoading(true);
        await SeguimientoVisitaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                let datosFormateados = userContracts.contratos.map((item) => {
                    const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                    const fechaClave = FormatFecha(item.fecha_ultima_clave)
                    return {
                        ...item,
                        fecha_ultimo_pago: fechaPago,
                        fecha_ultima_clave: fechaClave,
                    }
                });

                let contratosFiltrador = userContracts.contratos.filter(item => item.estatus === 'active')
                let length = userContracts.contratos.length
                let percentage = contratosFiltrador.length > 0 ? userContracts.contratos.length / contratosFiltrador.length : 0

                setTotalContratos(length)
                setPercentage(percentage)

                setRangoFechas(userContracts.rangoFechas)

                const opcionesZonas = [...new Map(datosFormateados.map(x => [`${x.zonaid}-${x.zona}`, { value: x.zonaid, label: x.zona }])).values()];
                setzonas(opcionesZonas);

                setData(datosFormateados);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
        setLoading(false);
    }

    const handleDownloadFile = async () => {
        setLoading(true);
        await InstalacionDatamovilService.downloadVehiculosInventario(zonaSeleccionada)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculoDeInventario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
                habdleGetAll(1);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    // const handleGetZonas = async () => {
    //     await SeguroPolizaService.getAllTipocompra()
    //         .then(res => {
    //             let NewData = [];
    //             NewData.push({
    //                 value: "",
    //                 label: "Todos"
    //             })
    //             // res.data.data?.map(item => {
    //             //     NewData.push({
    //             //         value: item.tipoCompraId,
    //             //         label: item.tipoCompra
    //             //     })
    //             // });

    //             zonastest.map(item => {
    //                 NewData.push({
    //                     value: item.id,
    //                     label: item.descripcion
    //                 })
    //             });

    //             setzonas(NewData);
    //         }).catch(e => {
    //         })
    // }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    const handleDetails = (value) => {
        //Movimiento a Detalles del Job
        navigate(`/cobranza/seguimiento-telefonico/detalles`, {
            state: value
        })
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                const vigente = analizarDatos(res.data, 0, "Vigente")
                const cumplidas = analizarDatos(res.data, 0, "Cumplidas")

                const suma = res.data.reduce((total, obj) => {
                    return {
                        montoVencido: total.montoVencido + obj.monto_vencido,
                        montoCobrado: total.montoCobrado + obj.monto_recuperado
                    };
                }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(res.data)
                setTotalMontoCobrado(suma.montoCobrado)
                setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        setTextoBusqueda,
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        loading,
        zonas,
        setpageRows,
        setZonaSeleccionada,
        handleDownloadFile,
        rangoFechas,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        active7,
        active15,
        totalContratos,
        percentage,
        isVisible,
        toggleActive7,
        toggleActive15,
        isOpen
    }
}

export default useCarteraGeneral