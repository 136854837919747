import React from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DireccionesDetalle = () => {
   const navigate = useNavigate();
   // const { state } = useLocation();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

   const columns = [
      { field: 'usuario', headerName: 'Usuario', width: 210 },
      { field: 'fecharegistro', headerName: 'Fecha de registro', width: 200 },
      { field: 'direccion', headerName: 'Dirección', width: 550 },
      { field: 'visitas', headerName: 'Visitas', width: 100 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 }
   ]

   const data = [
      {
         usuario: "Mauricio García",
         fecharegistro: "12/07/2023",
         direccion: "Calle 10 #330 Col.Jardines de Pedregal, San Nicolas de los Garza, NL.",
         visitas: 5,
         comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
      },
      {
         usuario: "Samanths Ortiz",
         fecharegistro: "12/07/2023",
         direccion: "Calle 10 #330 Col.Jardines de Pedregal, San Nicolas de los Garza, NL.",
         visitas: 5,
         comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
      },
      {
         usuario: "Edgar Castillo",
         fecharegistro: "12/07/2023",
         direccion: "Calle 10 #330 Col.Jardines de Pedregal, San Nicolas de los Garza, NL.",
         visitas: 5,
         comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
      },
   ]

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                                 <h2 className="fw-bold ">Consulta de direcciones</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                                 <div className="row mt-4">
                                    <div className="col col-3">
                                       <div className="row justify-content-start">
                                             <div className="col col-12">
                                                <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                             </div>
                                       </div>
                                    </div>
                                    <div className="col col-3">
                                       <div className="row justify-content-start">
                                             <div className="col col-12">
                                                <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
               <div className="px-4">
                  <p className="pt-2" >Detalle de Direcciones</p>
                  <DataTable
                     column={columns}
                     data={data}
                  />
               </div>
            </div>
         </div>
      </>
   );
}

export default DireccionesDetalle;