import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from './Modal';
import Spinner from '../Loadig';
import { TextAreaField } from '../TextAreaField';
import { message } from '../../Utils/functions';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { SelectField } from '../SelectField';

const EliminarPromesaPagoModal = ({ isOpen, setIsOpen, item, toast, handleGetAll, state, GetData, motivoEliminacion }) => {
    // const refbtn = useRef();
    let initial = {
        sol_id: state.sol_id,
        contrato: item.contrato_id,
        folio_promesa: item.folio_promesa,
        // folio_promesa_dataware: item.folio_promesa_dataware,
        vin: state.vin,
        folio_promesa_acendes: item.folio_promesa_acendes,
        motivo_Cancelacion: '',
        comentario: '',
        monto_promesa: item.monto_promesa,
        garantia: false,
        fecha_compromiso: item.fecha_compromiso
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        comentario: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),
        motivo_Cancelacion: Yup.string().required('Motivo is required')
    })

    //Funcion de eliminar
    const EliminarPromesaPagoAscendes = async (item) => {
        await CobranzaService.eliminaPromesaPagoAscendes(item)
            .then(res => {
                toast.success(message("¡Operación exitosa!", res.data.mensaje));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }

    const EliminarPromesaPago = async (item) => {
        await CobranzaService.eliminaPromesaPago(item)
            .then(res => {
                toast.success(message("¡Operación exitosa!", res.data.mensaje));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }

    const handleDelete = async (data) => {
        setLoading(true);
        try {
            await EliminarPromesaPagoAscendes(data)
            await EliminarPromesaPago(item)
            await GetData()

            setIsOpen(false);
            setLoading(false)
        } catch (error) {
            setIsOpen(false);
            setLoading(false)
            toast.error(message("¡Error!"));
        }

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleDelete(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  mx-4'>
                            <span className='fs-5 mb-2'><strong>¿Estas seguro que deseas eliminar la promesa?</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '28rem' }}>
                            <Form>
                                <span> <strong>Folio: {values.folio_promesa}</strong></span>
                                <div className="col-lg-12 col-12">
                                    <SelectField
                                        id="motivo_Cancelacion"
                                        label="Motivo de Eliminación:"
                                        name="motivo_Cancelacion"
                                        disabled={false}
                                        items={motivoEliminacion}
                                        onChange={(event) => {
                                            setFieldValue("motivo_Cancelacion", event.value)
                                        }} />
                                </div>
                                <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario" type="text" holder="Escribe" />
                                </div>

                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' type="submit">Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default EliminarPromesaPagoModal