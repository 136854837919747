import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   datosruta: {
      ruta: "",
      nombrecobrador: "",
      fechainicio:'',
      fechafin: '',
      contratos:0,
      items:[]
   }
}
export const DetalleRutaSlice = createSlice({
   name: 'DetalleRutaSlice',
   initialState,
   reducers: {
      setDatosRuta: (state, action) => {
         return { ...state, datosruta: action.payload }
      },
      setLimpiarData: (state, action) => {
         return { ...state,
            datosruta: {
               ruta: "",
               nombrecobrador: "",
               fechainicio:'',
               fechafin: '',
               contratos:0,
               items:[]
            }
         }
      }
   },
})

export const { setDatosRuta, setLimpiarData } = DetalleRutaSlice.actions

export default DetalleRutaSlice.reducer