import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import CobranzaService from '../Services/Cobranza/Cobranza';

const TablaAnidada = ({
    title,
    state,
    data,
    column,
    addColumns,
    buttonText,
    handleClick,
    children,
    childColumn,
    childData,
    childCommentColumn,
    moreDirections,
    handleMoreDirections,
    childRegistroColumn,
    childActionColumns,
    handleRegistro,

}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const columnsNumber = columns.length + 1
    const  columnsNumberFinal = columnsNumber + addColumns

    const [filteredData, setFilteredData] = useState([])


    useEffect(() => {
        setColumns(column);
        if (data === null)
            setRows([]);
        else
            setRows(data);

    }, [data])

    const handleToggle = (id) => {
        setExpandedRows(prevExpandedRows => {
            if (prevExpandedRows.includes(id)) {
                return prevExpandedRows.filter(rowId => rowId !== id); // Remove id
            } else {
                return [...prevExpandedRows, id]; // Add id
            }
        });
    };

    ///Actualizar y volver esto un llamado. 
    const HandleFilterData = async (value, index) => {
        data = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            vin: state.vin,
            idpersona_acendes: value.idreferencia,
            tipo_persona: value.tipo,
            curp: value.curp_ref
        }

        await CobranzaService.getDatosdeContactos(data)
            .then(res => {
                let data = res.data.responseData.datos_contacto || []
                
                let datosFormateados = data.map((item) => {
                    if (item) {
                        return {
                            ...item,
                            message: item.comentario,
                            value
                        }
                    }
                })

                setFilteredData((prevArray) => {
                    // Create a copy of the previous array
                    const updatedArray = [...prevArray];
                    
                    // Set the value at the specific index
                    updatedArray[index] = datosFormateados;
                    
                    // Return the updated array
                    return updatedArray;
                  });
            })
    }
    //

    const handlegetPropertyValue = (object, field, handler) => {
        let value = object[field];
        return value
    }

    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th></th>
                    {
                        columns?.map((item, ind) => {
                            // if (!item.hasOwnProperty('isActive') || item?.isActive) {
                            return <th key={ind} scope="col" style={{ width: `${item.width}px` }}>{item.headerName}</th>
                            // }
                        })
                    }
                        {
                            moreDirections &&
                            <th scope="col" style={{ width: `80px` }} >Direcciones Adicionales</th>
                        }
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0 ?
                        rows?.map((rowObj, index) => {
                            //aqui poner el childrenProps
                            if (rowObj.filter === false) {
                                HandleFilterData(rowObj, index)
                                rowObj.filter = true
                            }
                            
                            const childWithProps = React.cloneElement(children, {
                                column: childColumn,
                                data: filteredData[index],
                                commentColumn:childCommentColumn,
                                registroColumn: childRegistroColumn,
                                actionColumns: childActionColumns,
                                handleRegistro: handleRegistro
                            });

                            
                            return <Fragment key={index}>
                                <tr>
                                    <td>
                                        <button
                                            style={{
                                                background: "none",
                                                borderColor: "transparent",
                                                cursor: "pointer",
                                                color: "#1A3D7E",
                                            }}
                                            onClick={() => handleToggle(index)}
                                        >
                                            {expandedRows.includes(index) ? '-' : '+'}
                                        </button>
                                    </td>
                                    {
                                        columns?.map((column, index) => {
                                            return <td key={index}><span className=''
                                                style={
                                                    { borderRadius: '5px', padding: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? '3px 10px 3px 10px' : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none', backgroundColor: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' || column.field === 'dia' ? `${rowObj.colorFondo}` : 'none', color: column.field === 'proceso' || column.field === 'nombreProceso' || column.field === 'parametro' ? `${rowObj.colorTexto}` : 'none' }}>
                                                {handlegetPropertyValue(rowObj, column.field)}</span>
                                            </td>
                                        })
                                    }
                                    { moreDirections &&
                                    <td>
                                        <span onClick={(e) => handleMoreDirections(rowObj)}
                                            style={{    textDecoration: "underline" , cursor: "pointer"}}>
                                            <i className='bx bx-receipt'></i> Ver Direcciones</span>
                                    </td>
                                    }
                                    {buttonText &&
                                        <td>
                                            <button
                                                className="btn"
                                                onClick={(e) => handleClick(rowObj)}
                                            >
                                                {buttonText}
                                            </button>
                                        </td>
                                    }
                                </tr>
                                {
                                    expandedRows.includes(index) && (
                                        <tr>
                                            <td colSpan={columnsNumberFinal}>
                                                {childWithProps}
                                            </td>
                                        </tr>
                                    )
                                }
                            </Fragment>
                        })
                        : <tr><td colSpan={(columns?.length + 1)} className="text-center">No se encontrarón registros</td></tr>
                }
            </tbody >
        </table >
    );
};

export default TablaAnidada;