import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha } from '../../Utils/functions'
const SeguimientoCobranzaHook = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)
  const [data, setData] = useState([{}])

  const userId = sessionStorage.getItem('userId').trim()
  const nivel_cobranza = "1"

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencido', headerName: 'Días Vencidos' },
    { field: 'monto_insoluto', headerName: 'Monto Insoluto' },
    { field: 'monto_adeudo', headerName: 'Monto adeudo' },
    { field: 'fecha_ultimo_pago', headerName: 'Fecha última pago' },
    { field: 'fecha_ultima_clave', headerName: 'Hora última clave' },
    { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar' },
    { field: 'llamadas', headerName: 'Llamadas' },
    { field: 'ultima_actualizacion', headerName: 'Ultima Actualización' },
  ];
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
    // if (currentPage > newTotalPages) {
    //   setCurrentPage(newTotalPages)
    // } else if (currentPage <= 0) {
    //   setCurrentPage(1)
    // }

  }, [currentPage, name, pageRows, data])

  const handleDetails = (value) => {
    console.log(value)
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: value
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()
  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());

        let datosFormateados = userContracts.contratos.map((item) => {
          const fechaPago = FormatFecha(item.fecha_ultimo_pago)
          const fechaClave = FormatFecha(item.fecha_ultima_clave)
          return {
            ...item,
            fecha_ultimo_pago: fechaPago,
            fecha_ultima_clave: fechaClave,
          }
        })
        
        let nivelCobranzaUno = datosFormateados?.filter(item => item.nivel_cobranza === nivel_cobranza)
        setData(nivelCobranzaUno)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick
  }
}
export default SeguimientoCobranzaHook