import axios from '../../Utils/ApiService';

const PagoUnidad = {
    /* SEGUIMIENTO VEHICULOS EXTERNOS PENDIENTES DE PAGO */
    getSeguimientoPagoUnidadExternosPendientePago: (data) => {
        return axios.post(`/cartera/getSeguimientoPagoUnidadExternosPendientePago`, data);
    },
    /* SEGUIMIENTO VEHICULOS INVENTARIO PENDIENTES DE PAGO */
    getSeguimientoPagoUnidadInventarioPendientePago: (data) => {
        return axios.post(`/cartera/getSeguimientoPagoUnidadInventarioPendientePago`, data);
    },
    pagoUnidadRazonSocialGet: (id) => {
        return axios.get(`/cartera/pagoUnidadRazonSocialGet?TipoCompraId=${id}`);
    },
    pagoUnidadProcesoPagoCrear: (data) => {
        return axios.post(`/cartera/pagoUnidadProcesoPagoCrear`, data);
    },
    getSeguimientoUnidadesProcesoPago: (data) => {
        return axios.post(`/cartera/getSeguimientoUnidadesProcesoPago`, data);
    },
    ExportPendientesPagoProceso: async (data) => {
        return await axios.get(`/cartera/ExportarPendientesPagoReporte?Folio=${data.folio}`, { responseType: 'blob' });
    },
    pagoUnidadProcesoPagadoCrear: async (data) => {
        return await axios.post(`/cartera/pagoUnidadProcesoPagadoCrear`, data);
    },
    getSeguimientoUnidadesProcesoPagado: async (data) => {
        return await axios.post(`/cartera/getSeguimientoUnidadesProcesoPagado`, data);
    },
    ExportarUnidadesPagadasReporte: async (data) => {
        return await axios.get(`/cartera/ExportarUnidadesPagadasReporte?page=${data.page}&rows=${data.rows}`, { responseType: 'blob' });
    },

    getAllReportePagoUnidad: () => {
        return axios.get(`/Cartera/GetAllReportePagoUnidad`);
    },
    getAllFormaDePago: () => {
        return axios.get(`/Cartera/GetAllFormaDePago`);
    },
    getAllMetodosDePago: () => {
        return axios.get(`/Cartera/GetAllMetodosDePago`);
    },
    asignarVehiculoPago: async (data) => {
        return await axios.post(`/cartera/AsignarVehiculoPago`, data);
    },
    pagoLeyAntiLavado: async (data) => {
        return await axios.post(`/cartera/PagoLeyAntiLavado`, data);
    },
    desasignaVehiculoPago: async (data) => {
        return await axios.post(`/cartera/DesasignaVehiculoPago`, data);
    },
    getAllSeguimientoPagoUnidad: async (data) => {
        return await axios.post(`/cartera/GetAllSeguimientoPagoUnidad`);
    },
    creaSeguimientoPago: async (data) => {
        return await axios.post(`/cartera/CreaSeguimientoPago`, data);
    },
    actualizaSeguimientoPago: async (data) => {
        return await axios.put(`/cartera/ActualizaSeguimientoPago`, data);
    },
    upsertSeguimientoPagoUnidad: async (data) => {
        return await axios.post(`/Cartera/UpsertRegistroPagoUnidades`, data);
    },
    getAllRegistroPagoUnidades: async () => {
        return await axios.post(`/Cartera/GetAllRegistroPagoUnidades`);
    },    

    exportarSeguimientoPagoUnidad: () => {
        return axios.get(`/Cartera/ExportarSeguimientoPagoUnidad`,{ responseType: 'blob' });
    },
    exportarReportePagoUnidad: () => {
        return axios.get(`/Cartera/ExportarReportePagoUnidad`,{ responseType: 'blob' });
    },
}

export default PagoUnidad