import axios from '../../Utils/ApiService';

const AutorizarDictamenService = {
    getAllAutorizarDictamen: async (page, rows, string) => {
        return await axios.get(`/cartera/GetAllAutorizarDictamen?page=${page}&Rows=${rows}&Buscar=${string}`);
    }, 
    AprobarRechazarDictamen:async(data)=>{
        return axios.put('/cartera/AprobarRechazarDictamen',data);
    },
}

export default AutorizarDictamenService