import { useEffect, useState } from 'react'
import JobsCarteraService from '../../Services/Jobs/JobsCartera';
import { useNavigate } from 'react-router';

const useProgramacionJobsCartera = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dataProceso, setDataProceso] = useState([]);
  const [dataFrecuencia, setDataFrecuencia] = useState([]);

  const columns = [
    { field: 'job', headerName: 'Job', width: 150 },
    { field: 'proceso', headerName: 'Proceso', width: 160 },
    { field: 'frecuenciaFormateada', headerName: 'Frecuencia', width: 230 },
    { field: 'diaProgramado', headerName: 'Dia Programado', width: 230 },
    { field: 'horaProgramado', headerName: 'Horario programado', width: 200 },
    { field: 'fechaUltimaEjecucion', headerName: 'Fecha última ejecución', width: 250 },
    { field: 'horaUltimaEjecucion', headerName: 'Hora última ejecución', width: 200 },
  ];

  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });

  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([])

  const [name, setName] = useState("")
  const [proceso, setProceso] = useState("")
  const [frecuencia, setFrecuencia] = useState("")

  const [pageRows, setpageRows] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    handleGetAllJobs();
    getProcesos()
    getFrecuecia()
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.job && item.job.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (proceso === '' || item.proceso === proceso) &&
        (frecuencia === '' || item.frecuenciaFormateada === frecuencia)
      )
    })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, proceso, frecuencia, pageRows, data])

  useEffect(() => {
    setCurrentPage(0)
  },[pageRows])

  const handleGetAllJobs = async () => {
    setLoading(true)
    let newData = {
      frecuenciaId: null,
      etapaJobId: null,
      page: null,
      rows: null,
      buscar: ''
    }
    await JobsCarteraService.getJobsCartera(newData)
      .then(res => {
        const resFechaFormateada = res.data.data.map(event => {
          const horaProgramado = new Date(event.horaProgramado)
          const horaUltimaEjecucion = new Date(event.horaUltimaEjecucion)
          const fechaUltimaEjecucion = new Date(event.fechaUltimaEjecucion)
          let frecuenciaFormateada

          const opciones = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          };

          if (event.frecuencia === "Días")
            frecuenciaFormateada = "Diaria"
          if (event.frecuencia === "Semanas")
            frecuenciaFormateada = "Semanal"
          if (event.frecuencia === "Mes")
            frecuenciaFormateada = "Mensual"

          const horaProgramadoFormateada = horaProgramado.toLocaleTimeString('en-US', opciones).toLowerCase()
          const horaUltimaEjecucionFormateada = horaUltimaEjecucion.toLocaleTimeString('en-US', opciones).toLowerCase()
          const fechaUltimaEjecucionFormateada = FormatFecha(fechaUltimaEjecucion)

          return {
            ...event,
            frecuenciaFormateada: frecuenciaFormateada,
            horaProgramado: horaProgramadoFormateada,
            horaUltimaEjecucion: horaUltimaEjecucionFormateada,
            fechaUltimaEjecucion: fechaUltimaEjecucionFormateada
          }
        })

        setData(resFechaFormateada)
        setLoading(false)
      }).catch(e => {
        setLoading(false)
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  const FormatFecha = (fecha) => {
    const newDate = new Date(fecha)

    const dia = String(newDate.getDate()).padStart(2, '0')
    const mes = String(newDate.getMonth() + 1).padStart(2, '0')
    const anio = newDate.getFullYear()

    const fechaFormateada = `${dia}-${mes}-${anio}`
    return fechaFormateada
  }


  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/admin/jobs/cartera/${value.idJob}`, {
      state: value
    })
  }

  /* CATALOGOS */
  const getProcesos = async () => {
    await JobsCarteraService.getAllProcesosCartera()
      .then(res => {
        let NewData = [
          { value: '', label: 'Todos' }
        ]
        res.data?.map(item => {
          NewData.push({
            value: item.nombreProcesoJob,
            label: item.nombreProcesoJob
          })
          // }
        })
        setDataProceso(NewData)
      }).catch(e => {
      })
  }

  const getFrecuecia = async () => {
    await JobsCarteraService.getAllFrecuenciaCartera()
      .then(res => {
        let NewData = [
          { value: '', label: 'Todos' }
        ]
        res.data?.map(item => {
          if (item.nombre === "Minutos")
            return null
          if (item.nombre === "Días")
            NewData.push({
              value: "Diaria",
              label: "Diaria"
            })
          if (item.nombre === "Semanas")
            NewData.push({
              value: "Semanal",
              label: "Semanal"
            })
          if (item.nombre === "Mes")
            NewData.push({
              value: "Mensual",
              label: "Mensual"
            })
        })
        // res.data?.map(item => {
        //   NewData.push({
        //     value: item.nombre,
        //     label: item.nombre
        //   })
        // })
        setDataFrecuencia(NewData)
      }).catch(e => {
      })
  }
  /* TERMINA CATALOGOS */

  return {
    //inicializar
    loading,
    dataProceso,
    dataFrecuencia,
    currentData,
    columns,

    //Actualizar
    setName,
    setProceso,
    setFrecuencia,

    //Modal
    modalMessage,
    setModalMessage,

    //Paginacion
    currentPage,
    pageCount,
    setpageRows,
    handlePageClick,
    handleDetails
  }
}
export default useProgramacionJobsCartera