import { useEffect, useState } from 'react'
import CobranzaService, { ResponseApi } from '../../Services/Cobranza/Cobranza';
import { useSelector } from 'react-redux';

const useAreasCobranza = () => {
  const columns = [
    { field: 'zonadecobranza', headerName: 'Zona de cobranza', width: 300 },
    { field: 'coloniasasignadas', headerName: 'Colonias asignadas', width: 250 },
    { field: 'contratos', headerName: 'Contratos', width: 250 }
  ];

  const [estados, setDataEstados] = useState([]);
  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedState, setselectedState] = useState(0);
  const [currentState, setCurentState] = useState(0);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [isOpenModalRegistrarZona, setIsOpenModalRegistrarZona] = useState(false);
  const [isOpenModalAsignarCP, setIsOpenModalAsignarCP] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false); // Bandera para detectar si se registraron datos

  const [dataMunicipio, setDataMunicipio] = useState([]);
  const [idMunicipio, setIdMunicipio] = useState(0);
  const [defaultValueMunicipio, setDefaulValudeModelo] = useState({ value: 0, label: 'Seleccionar' })

  useEffect(() => {
    getAllStates();
    // setLoading(false);
  }, []);

  useEffect(() => {
    handleGetAll(currentState);
  }, [selectedState]);

  const handleGetAll = async (nombreestado) => {
    if (nombreestado != '') {
      setLoading(true);
      await CobranzaService.getGetZonaReport()
        .then(res => {
          let NewData = [];
          res.data.responseData.forEach(element => {
            if (element.estado == nombreestado) {
              NewData.push({
                zonadecobranza: element.descripcion,
                coloniasasignadas: element.zonaItemsCount,
                contratos: element.contratosCount,
                item: element
              })
            }
          });

          setData(NewData);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
        })
    }
  }

  const getAllStates = async () => {
    setLoading(true);
    await CobranzaService.getAllEstados()
      .then(res => {
        let NewData = [];
        res.data.responseData.map(element => {
          NewData.push({
            value: element.cpId,
            label: element.d_estado
          })
        });
        setDataEstados(NewData);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      })
  }

  const handleEdit = (item) => {
    setItem(item)
    setIsOpenModalAsignarCP(true);
  }

  const handleChangeMunicipio = async (item) => {
    setDefaulValudeModelo(item);
    setIdMunicipio(item.value);
  }

  const handleCheck = (cheked, value) => {
    if (cheked) {
      console.log(`CHECKED TRUE ${value}`)
    } else {
      console.log(`CHECKED FALSE ${value}`)
    }
  }

  return {
    columns,
    data,
    currenPage,
    pageCount,
    loading,
    item,
    estados,
    selectedState,
    setselectedState,
    currentState,
    setCurentState,
    isOpenModalRegistrarZona,
    setIsOpenModalRegistrarZona,
    isOpenModalAsignarCP,
    setIsOpenModalAsignarCP,
    handleEdit,
    dataMunicipio,
    defaultValueMunicipio,
    handleChangeMunicipio,
    handleCheck,
    handleGetAll,
    dataUpdated,
    setDataUpdated,
    setItem
  }
}

export default useAreasCobranza